import convert from 'xml-js';
import Uuid from 'uuid-lib';
import ErrorModel from '../models/errorModel';
import flatten from 'lodash/flatten';

class XMLModel {
	static generateJSON(setDataReady) {
		fetch('content/configuration.xml')
			.then(function(res) {
				res
					.text()
					.then(function(data) {
						const json = convert.xml2js(data, { compact: false, ignoreDeclaration: true, nativeTypeAttributes: true, trim: true });
						window.configurationData = XMLModel.formatJSON(json);
						console.log('Configuration Data:', window.configurationData);
						setDataReady(true);
					})
					.catch(function(error) {
						console.log(error);
						ErrorModel.show(
							'Looks like a problem with the configuration XML file.',
							'We are unable to determine the exact cause of the error. Please check to make sure the XML has matching closing tags for each opening tag.'
						);
					});
			})
			.catch(function(error) {
				ErrorModel.show('There was a problem loading the configuration XML file.', 'Please check to make sure the XML file is valid.');
			});
	}

	static generateMediaObject(elements, elementNames, attributes, attributeNames, optional) {
		const object = {};
		elementNames.forEach((elementName) => {
			if (Array.isArray(elements)) {
				let element = elements.find((element) => element.name === elementName);
				if (element) {
					object[elementName] = element.elements[0].text;
				} else {
					if (!optional) {
						ErrorModel.missingTag(elementName);
					}
				}
			} else {
				if (elements) {
					object[elementName] = elements[elementName];
				}
			}
		});
		if (attributes) {
			attributeNames.forEach((attributeName) => {
				if (Array.isArray(attributes)) {
					let attribute = attributes.find((attribute) => attribute.name === attributeName);
					if (attribute) {
						object[attributeName] = attribute.attributes[0].text;
					} else {
						ErrorModel.missingTag(attributeName);
					}
				} else {
					object[attributeName] = attributes[attributeName];
				}
			});
		}
		return object;
	}

	static generateMediaObjects(element, parentDepth, parent) {
		const depth = parentDepth + 1;
		const objects = [];
		if (element.elements) {
			const elements = element.elements.filter(
				(element) =>
					element.name === 'map' ||
					element.name === 'gallery' ||
					element.name === 'video' ||
					element.name === 'panorama' ||
					element.name === 'object' ||
					element.name === 'html'
			);
			elements.forEach((element) => {
				let object;
				if (element.name === 'map') {
					object = XMLModel.generateMediaObject(element.elements, ['title', 'description'], element.attributes, [
						'thumb',
						'topics',
						'hideFromIndex',
						'menuTurns',
						'folder',
						'mapX',
						'mapY',
						'lat',
						'long',
						'pinX',
						'pinY',
						'zoom',
						'switchPoint',
						'maxZoom',
						'showTitle',
						'hideZoomButtons',
						'image',
						'alt',
						'accessToken',
						'mediaType',
						'style',
					]);
				} else if (element.name === 'gallery') {
					object = XMLModel.generateMediaObject(element.elements, ['title', 'description'], element.attributes, [
						'thumb',
						'topics',
						'hideFromIndex',
						'menuTurns',
						'folder',
						'imageCount',
						'pinX',
						'pinY',
						'lat',
						'long',
						'fit',
					]);
				} else if (element.name === 'panorama') {
					object = XMLModel.generateMediaObject(element.elements, ['title', 'description'], element.attributes, [
						'thumb',
						'topics',
						'hideFromIndex',
						'menuTurns',
						'folder',
						'pinX',
						'pinY',
						'lat',
						'long',
					]);
				} else if (element.name === 'video') {
					object = XMLModel.generateMediaObject(element.elements, ['title', 'description'], element.attributes, [
						'thumb',
						'topics',
						'hideFromIndex',
						'menuTurns',
						'file',
						'pinX',
						'pinY',
						'lat',
						'long',
						'fit',
					]);
				} else if (element.name === 'object') {
					object = XMLModel.generateMediaObject(element.elements, ['title', 'description'], element.attributes, [
						'thumb',
						'topics',
						'hideFromIndex',
						'menuTurns',
						'file',
						'folder',
						'offsetX',
						'offsetY',
						'offsetZ',
						'texture',
						'pinX',
						'pinY',
						'lat',
						'long',
					]);
				} else if (element.name === 'html') {
					object = XMLModel.generateMediaObject(element.elements, ['title', 'description'], element.attributes, [
						'thumb',
						'topics',
						'hideFromIndex',
						'menuTurns',
						'url',
						'mediaType',
						'timeout',
						'pinX',
						'pinY',
						'lat',
						'long',
					]);
				} else {
					object = XMLModel.generateMediaObject(element.elements, ['title', 'description'], element.attributes, [
						'thumb',
						'topics',
						'hideFromIndex',
						'menuTurns',
						'pinX',
						'pinY',
						'lat',
						'long',
					]);
				}
				object['id'] = Uuid.raw();
				object['type'] = element.name;
				object['depth'] = depth;
				object['parent'] = parent;
				objects.push(object);
				if (element.name === 'map') {
					const maps = XMLModel.generateMediaObjects(element, depth, object.id);
					objects.push(maps);
				}
				if (element.elements) {
					object.raw = element.elements;
				}
			});
		}
		const flattenedObjects = flatten(objects);
		flattenedObjects.forEach((element) => {
			element['children'] = [];
			flattenedObjects.forEach((inner) => {
				if (element.id === inner.parent) {
					element.children.push(inner.id);
				}
			});
		});
		return flattenedObjects;
	}

	static formatJSON(json) {
		const main = json.elements.find((element) => element.name === 'main');
		const data = {};
		if (main) {
			if (main.elements) {
				const settings = main.elements.find((element) => element.name === 'settings');
				if (settings) {
					data.settings = XMLModel.generateMediaObject(settings.elements, [], settings.attributes, [
						'devMode',
						'timeout',
						'timeoutLong',
						'pin',
						'mapLogo',
						'headingFont',
						'copyFont',
						'hideMediaIndex',
						'hideMainMenu',
						'hideHomeScreen',
					]);
				} else {
					ErrorModel.missingTag('settings');
				}
				const home = main.elements.find((element) => element.name === 'home');
				if (home) {
					data.home = XMLModel.generateMediaObject(
						home.elements,
						['title', 'prompt', 'alt'],
						home.attributes,
						['background', 'backgroundDuration', 'backgroundVideo', 'overlayImage'],
						true
					);
					if (home.elements) {
						const homeBackgrounds = home.elements.find((element) => element.name === 'backgrounds');
						if (homeBackgrounds) {
							if (homeBackgrounds.elements) {
								data.homeBackgrounds = homeBackgrounds.elements;
							}
						}
					}
				} else {
					ErrorModel.missingTag('home');
				}
				const menu = main.elements.find((element) => element.name === 'menu');
				if (menu) {
					data.menu = XMLModel.generateMediaObject(
						menu.elements,
						['title', 'description'],
						menu.attributes,
						['logo', 'overlayImage'],
						true
					);
				} else {
					ErrorModel.missingTag('menu');
				}
				const credits = main.elements.find((element) => element.name === 'credits');
				if (credits) {
					if (credits.elements) {
						data.credits = credits.elements;
						data.creditsLogo = credits.attributes;
					}
				} else {
					ErrorModel.missingTag('credits');
				}
				const help = main.elements.find((element) => element.name === 'help');
				if (help) {
					data.help = XMLModel.generateMediaObject(help.elements, ['alt'], help.attributes, ['file']);
				} else {
					ErrorModel.missingTag('help');
				}
				const toc = main.elements.find((element) => element.name === 'toc');
				if (toc) {
					data.toc = XMLModel.generateMediaObject(toc.elements, ['title', 'description'], toc.attributes, []);
				} else {
					ErrorModel.missingTag('toc');
				}
				data.media = XMLModel.generateMediaObjects(main, 0, null);
				return data;
			} else {
				ErrorModel.missingTags('main');
			}
		} else {
			ErrorModel.missingGalleryTag();
		}
	}
}

export default XMLModel;
