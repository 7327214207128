class Focus {
	static set(id) {
		console.log('focus on', id);
		setTimeout(function() {
			const element = document.getElementById(id);
			console.log('focus', element);
			if (element) {
				element.focus();
			}
		}, 100);
	}
}

export default Focus;
