import React from 'react';
import PropTypes from 'prop-types';
import MediaIcon from './MediaIcon';

class MediaType extends React.PureComponent {
	render() {
		const { mediaType } = this.props;
		if (mediaType === 'map') {
			return (
				<span>
					<MediaIcon mediaType={mediaType} />
					&nbsp;&nbsp;Map
				</span>
			);
		}
		if (mediaType === 'video') {
			return (
				<span>
					<MediaIcon mediaType={mediaType} />
					&nbsp;&nbsp;Video
				</span>
			);
		}
		if (mediaType === 'gallery') {
			return (
				<span>
					<MediaIcon mediaType={mediaType} />
					&nbsp;&nbsp;Gallery
				</span>
			);
		}
		if (mediaType === 'html') {
			return (
				<span>
					<MediaIcon mediaType={mediaType} />
					&nbsp;&nbsp;Interactive
				</span>
			);
		}
		if (mediaType === 'panorama') {
			return (
				<span>
					<MediaIcon mediaType={mediaType} />
					&nbsp;&nbsp;Panorama
				</span>
			);
		}
		if (mediaType === 'object') {
			return (
				<span>
					<MediaIcon mediaType={mediaType} />
					&nbsp;&nbsp;Object
				</span>
			);
		}
		if (mediaType === 'video360') {
			return (
				<span>
					<MediaIcon mediaType={mediaType} />
					&nbsp;&nbsp;Video
				</span>
			);
		}
		return null;
	}
}

MediaType.propTypes = {
	mediaType: PropTypes.string.isRequired,
};

export default MediaType;
