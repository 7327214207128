import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import Slider from 'react-rangeslider';
import VideoHelper from '../../helpers/videoHelper';
import Logger from '../../helpers/logger';
import Focus from '../../helpers/focus';
import DataModel from '../../models/dataModel';

function handleVolumeChange(value, setVolume, playSound) {
	setVolume(Math.round((value / 30) * 100) / 100);
	playSound('intro', value / 30, true);
	VideoHelper.setVolume(Math.round((value / 30) * 100) / 100);
}

class WelcomePopup extends React.PureComponent {
	componentDidMount() {
		const { actions, state } = this.props;
		const { setPopup, playSound } = actions;
		const { activeVolume, webMode } = state;
		const videoBackground = DataModel.getHomeAttribute('backgroundVideo', true);
		if (!videoBackground) {
			playSound('intro', activeVolume);
		}
		if (webMode) {
			setPopup('');
		}
	}

	render() {
		const { state, actions } = this.props;
		const { activeCaptionSetting, activeVolume, timer, webMode } = state;
		const { setPopup, setCaptionSetting, setVolume, playSound } = actions;
		if (webMode) {
			VideoHelper.playVideo();
			Logger.log('start-kiosk');
			return null;
		}
		return (
			<div aria-hidden="true">
				<h1>Configuration Settings</h1>
				<div className="setting">
					<Toggle id="show-captions" checked={activeCaptionSetting} onChange={() => setCaptionSetting(!activeCaptionSetting)} />
					<label className="label-left" htmlFor="show-captions">
						Show Video Captions
					</label>
				</div>
				<div className="setting">
					<label>Home Screen Volume</label>
					<Slider
						min={0}
						max={30}
						value={Math.round(activeVolume * 30)}
						onChange={(value) => {
							handleVolumeChange(value, setVolume, playSound);
							VideoHelper.playVideo();
						}}
					/>
				</div>
				<div className="setting">
					<label>Kiosk will start automatically in {timer.remainingSeconds} seconds.</label>
				</div>
				<div className="setting">
					<button
						className="button"
						onClick={() => {
							setPopup('');
							Focus.set('home');
							VideoHelper.playVideo();
							Logger.log('start-kiosk');
							if (window.ipcRenderer) {
								window.ipcRenderer.send('save-settings', { captions: activeCaptionSetting, volume: activeVolume });
							}
						}}>
						Start
					</button>
				</div>
			</div>
		);
	}
}

WelcomePopup.propTypes = {
	state: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

export default WelcomePopup;
