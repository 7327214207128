import React from 'react';
import PropTypes from 'prop-types';
import Logger from '../../helpers/logger';
import Focus from '../../helpers/focus';
import DataModel from '../../models/dataModel';

class TimerPopup extends React.PureComponent {
	componentDidMount() {
		const { resetTimer } = this.props;
		const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
		events.forEach(function(event) {
			document.addEventListener(
				event,
				() => {
					resetTimer();
				},
				true
			);
		});
	}

	componentDidUpdate() {
		const { timer, webMode, setTimer, setPopup, setView, setCarousel, setConnectionStatus } = this.props;
		const online = window.navigator.onLine;
		if (online) {
			setConnectionStatus('online');
		} else {
			setConnectionStatus('offline');
		}
		if (timer.remainingSeconds < 1 && !webMode) {
			const hideHomeScreen = DataModel.getSettingsAttribute('hideHomeScreen', true);
			const hideMainMenu = DataModel.getSettingsAttribute('hideMainMenu', true);
			if (hideHomeScreen && !hideMainMenu) {
				setView('menu');
			} else if (hideHomeScreen && hideMainMenu) {
				// do nothing
			} else {
				setView('home');
			}
			setPopup('');
			setCarousel(1, 0, '');
			setTimer(timer.totalSeconds);
			Logger.log('end-session', 'timeout');
		}
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
			setTimer(timer.remainingSeconds - 1);
		}, 1000);
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	render() {
		const { activeViewID, timer, webMode } = this.props;
		if (timer.remainingSeconds <= 15 && activeViewID !== 'home' && !webMode) {
			Focus.set('timer-popup');
			return (
				<div id="timer-popup" tabIndex="-1" className="popup">
					<div className="popup-container popup-small popup-rounded">
						<div className="popup-content">
							<div>
								<h1>Anybody there?</h1>
								<p className="screen-reader">Push a button or touch anywhere to continue.</p>
								<h3 className="center">Touch anywhere to continue.</h3>
								<h3 className="center">
									The kiosk will restart in&nbsp;
									{timer.remainingSeconds}&nbsp;seconds.
								</h3>
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	}
}

TimerPopup.propTypes = {
	timer: PropTypes.object.isRequired,
	activeViewID: PropTypes.string.isRequired,
	webMode: PropTypes.bool.isRequired,
	setView: PropTypes.func.isRequired,
	setPopup: PropTypes.func.isRequired,
	setCarousel: PropTypes.func.isRequired,
	setTimer: PropTypes.func.isRequired,
	resetTimer: PropTypes.func.isRequired,
	setConnectionStatus: PropTypes.func.isRequired,
};

export default TimerPopup;
