import React from 'react';
import PropTypes from 'prop-types';
import DataModel from '../../models/dataModel';
import VideoHelper from '../../helpers/videoHelper';

function renderBackgrounds(backgrounds, activeBackgroundID) {
	const totalBackgrounds = backgrounds.length;
	const previousBackgroundID = activeBackgroundID === 1 ? totalBackgrounds : activeBackgroundID - 1;
	const backgroundMarkup = backgrounds.map((background, index) => {
		let className;
		const backgroundID = index + 1;
		const src = `content/${background.file}`;
		if (backgroundID === activeBackgroundID) {
			className = 'enter';
		} else if (backgroundID === previousBackgroundID) {
			className = 'exit';
		} else {
			className = 'inactive';
		}
		if (background.type === 'video') {
			return (
				<div className={className} key={backgroundID}>
					<video className="video" autoPlay loop>
						<source src={src} type="video/mp4" />
					</video>
				</div>
			);
		} else {
			return (
				<div className={className} key={backgroundID}>
					<img className="background ken-burns" src={src} alt="" />
				</div>
			);
		}
	});
	return backgroundMarkup;
}

class Background extends React.PureComponent {
	componentDidMount() {
		const { activeBackgroundID, setBackground } = this.props;
		const backgrounds = DataModel.getBackgrounds();
		let backgroundDuration = DataModel.getHomeAttribute('backgroundDuration', true);
		if (!backgroundDuration) {
			backgroundDuration = 10;
		}
		this.timeout = setTimeout(() => {
			if (activeBackgroundID === backgrounds.length) {
				setBackground(1);
			} else {
				setBackground(activeBackgroundID + 1);
			}
		}, backgroundDuration * 1000);
	}

	componentDidUpdate() {
		const { activeBackgroundID, setBackground } = this.props;
		const backgrounds = DataModel.getBackgrounds();
		let backgroundDuration = DataModel.getHomeAttribute('backgroundDuration', true);
		if (!backgroundDuration) {
			backgroundDuration = 10;
		}
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
			if (activeBackgroundID === backgrounds.length) {
				setBackground(1);
			} else {
				setBackground(activeBackgroundID + 1);
			}
		}, backgroundDuration * 1000);
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	render() {
		const { activeBackgroundID, activeVolume } = this.props;
		const backgrounds = DataModel.getBackgrounds();
		const videoBackground = DataModel.getHomeAttribute('backgroundVideo', true);
		if (videoBackground) {
			const src = `content/${videoBackground}`;
			return (
				<div className="backgrounds">
					<video
						className="video"
						id="videoBackground"
						autoPlay
						loop
						onLoadStart={() => {
							VideoHelper.setVolume(activeVolume);
						}}>
						<source src={src} type="video/mp4" />
					</video>
				</div>
			);
		} else if (backgrounds) {
			return <div className="backgrounds">{renderBackgrounds(backgrounds, activeBackgroundID)}</div>;
		}
		return <div className="backgrounds backgrounds-empty" />;
	}
}

Background.propTypes = {
	activeBackgroundID: PropTypes.number.isRequired,
	setBackground: PropTypes.func.isRequired,
	activeVolume: PropTypes.number.isRequired,
};

export default Background;
