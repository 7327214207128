import React from 'react';
import PropTypes from 'prop-types';
import MenuButton from './MenuButton';
import DataModel from '../../models/dataModel';

function renderButtons(carouselGroup, buttonProps, ariaHidden) {
	const buttons = carouselGroup.map((activeMediaID) => {
		return <MenuButton key={activeMediaID} activeMediaID={activeMediaID} {...buttonProps} hidden={ariaHidden} />;
	});
	return <div>{buttons}</div>;
}

class CarouselGroups extends React.PureComponent {
	render() {
		const { carouselGroups, activeGroupID, previousGroupID, lastDirection, buttonProps } = this.props;
		const maxButtons = DataModel.getLengthOfLargestCarouselGroup(carouselGroups);
		const carouselMarkup = carouselGroups.map((carouselGroup, index) => {
			const groupNumber = index + 1;
			let className = `group group-length-${maxButtons}`;
			let ariaHidden = true;
			if (groupNumber === activeGroupID) {
				className += ` enter-${lastDirection}`;
				ariaHidden = false;
			} else if (groupNumber === previousGroupID) {
				className += ` exit-${lastDirection}`;
			} else {
				className += ' inactive';
			}
			return (
				<div aria-hidden={ariaHidden} className={className} key={index}>
					{renderButtons(carouselGroup, buttonProps, ariaHidden)}
				</div>
			);
		});
		return <div className="groups">{carouselMarkup}</div>;
	}
}

CarouselGroups.propTypes = {
	carouselGroups: PropTypes.array.isRequired,
	activeGroupID: PropTypes.number.isRequired,
	previousGroupID: PropTypes.number.isRequired,
	lastDirection: PropTypes.string.isRequired,
	buttonProps: PropTypes.object.isRequired,
};

export default CarouselGroups;
