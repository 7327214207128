import React from 'react';
import PropTypes from 'prop-types';
import ViewManager from '../managers/ViewManager';
import ErrorScreen from '../errors/ErrorScreen';

function renderScreens(state, actions) {
	const { kioskMultiScreens } = state;
	switch (kioskMultiScreens) {
		case 1:
			return <ViewManager state={state} actions={actions} />;
		default:
			return <ErrorScreen />;
	}
}

class ScreenManager extends React.PureComponent {
	componentWillMount() {
		const { actions } = this.props;
		const { setCaptionSetting, setVolume, setWebMode } = actions;
		if (window.savedSettings) {
			const { captions, volume, webMode } = window.savedSettings;
			if (captions === true) {
				setCaptionSetting(true);
			}
			if (volume >= 0 && volume <= 1) {
				setVolume(volume);
			}
			if (webMode === true) {
				setWebMode(true);
			}
		}
	}

	render() {
		const { state, actions } = this.props;
		return <div className="screen">{renderScreens(state, actions)}</div>;
	}
}

ScreenManager.propTypes = {
	state: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

export default ScreenManager;
