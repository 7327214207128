import React from 'react';
import PropTypes from 'prop-types';

function renderTranscript(transcript) {
	const content = transcript.map((paragraph, index) => {
		return <p key={index}>{paragraph}</p>;
	});
	return content;
}

class TranscriptPopup extends React.PureComponent {
	render() {
		const { transcript } = this.props;
		console.log(transcript);
		return (
			<div id="transcript-popup" tabIndex="-1" className="transcript-content">
				<h1>Transcript</h1>
				<div>{renderTranscript(transcript)}</div>
			</div>
		);
	}
}

TranscriptPopup.propTypes = {
	transcript: PropTypes.array.isRequired,
};

export default TranscriptPopup;
