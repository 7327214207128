import React from 'react';
import PropTypes from 'prop-types';
import MediaIcon from '../common/MediaIcon';
import DataModel from '../../models/dataModel';
import VideoHelper from '../../helpers/videoHelper';
import Logger from '../../helpers/logger';
import Focus from '../../helpers/focus';

function handleClick(id, type, setView, setMedia, fadeSound, playVideo, resetTimer) {
	if (type === 'map') {
		setView('map');
		Focus.set('map');
	} else {
		Focus.set('media');
	}
	setMedia(id);
	fadeSound('intro');
	VideoHelper.fadeVolume(0);
	if (type === 'video') {
		playVideo(() => setMedia(''));
	}
	resetTimer();
	Logger.log('open-media', type, DataModel.getMediaTitle(id));
}

class MenuButton extends React.PureComponent {
	render() {
		const { activeMediaID, hidden, setView, setMedia, fadeSound, playVideo, resetTimer } = this.props;
		const mediaType = DataModel.getMediaType(activeMediaID);
		const mediaTypeCustom = DataModel.getMediaTypeCustom(activeMediaID);
		const tabIndex = hidden ? '-1' : '0';
		return (
			<button
				key={activeMediaID}
				tabIndex={tabIndex}
				type="button"
				className="category-button"
				onClick={() => {
					handleClick(activeMediaID, mediaType, setView, setMedia, fadeSound, playVideo, resetTimer);
				}}>
				<div className="image-box">
					<img src={DataModel.getMediaThumbnail(activeMediaID)} alt="" />
					<MediaIcon mediaType={mediaTypeCustom} />
				</div>
				<div className="media-heading">
					<h2>
						{DataModel.getMediaTitle(activeMediaID)} <span className="screen-reader">{mediaTypeCustom}</span>
					</h2>
				</div>
			</button>
		);
	}
}

MenuButton.propTypes = {
	activeMediaID: PropTypes.string.isRequired,
	hidden: PropTypes.bool.isRequired,
	setView: PropTypes.func.isRequired,
	setMedia: PropTypes.func.isRequired,
	fadeSound: PropTypes.func.isRequired,
	playVideo: PropTypes.func.isRequired,
};

export default MenuButton;
