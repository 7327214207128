export function setDataReady(dataReady) {
	return {
		type: 'SET_DATA_READY',
		dataReady,
	};
}

export function setSettingsReady(settingsReady) {
	return {
		type: 'SET_SETTINGS_READY',
		settingsReady,
	};
}

export function setError(error, title, description) {
	return {
		type: 'SET_ERROR',
		error,
		title,
		description,
	};
}

export function setBackground(backgroundID) {
	return {
		type: 'SET_BACKGROUND',
		backgroundID,
	};
}

export function setView(viewID) {
	return {
		type: 'SET_VIEW',
		viewID,
	};
}

export function setMedia(mediaID) {
	return {
		type: 'SET_MEDIA',
		mediaID,
	};
}

export function setPopup(popupID) {
	return {
		type: 'SET_POPUP',
		popupID,
	};
}

export function setCarousel(activeGroupID, previousGroupID, direction) {
	return {
		type: 'SET_CAROUSEL',
		activeGroupID,
		previousGroupID,
		direction,
	};
}

export function setSelector(activeGroupID, previousGroupID, direction, depth) {
	return {
		type: 'SET_SELECTOR',
		activeGroupID,
		previousGroupID,
		direction,
		depth,
	};
}

export function setGallery(activeImageID, previousImageID, direction, command) {
	return {
		type: 'SET_GALLERY',
		activeImageID,
		previousImageID,
		direction,
		command,
	};
}

export function setFilter(filterType, filterID) {
	return {
		type: 'SET_FILTER',
		filterType,
		filterID,
	};
}

export function setCaptionSetting(activeCaptionSetting) {
	return {
		type: 'TOGGLE_CAPTION_SETTING',
		activeCaptionSetting,
	};
}

export function setWebMode(webMode) {
	return {
		type: 'SET_WEB_MODE',
		webMode,
	};
}

export function setVolume(volume) {
	return {
		type: 'SET_VOLUME',
		volume,
	};
}

export function setTimer(remainingSeconds, totalSeconds, totalSecondsLong) {
	return {
		type: 'SET_TIMER',
		remainingSeconds,
		totalSeconds,
		totalSecondsLong,
	};
}

export function resetTimer() {
	return {
		type: 'RESET_TIMER',
	};
}

export function playSound(sound, volume, keepExistingSounds) {
	volume = volume || volume === 0 ? volume : 1;
	let soundSettings;
	if (keepExistingSounds) {
		soundSettings = { volume: [sound, volume] };
	} else {
		soundSettings = {
			stop: sound,
			play: sound,
			volume: [sound, volume],
		};
	}
	return {
		type: 'PLAY_SOUND',
		meta: {
			sound: soundSettings,
		},
	};
}

export function stopSound(sound) {
	return {
		type: 'STOP_SOUND',
		meta: {
			sound: {
				stop: sound,
			},
		},
	};
}

export function fadeSound(sound) {
	return {
		type: 'FADE_SOUND',
		meta: {
			sound: {
				stop: sound,
				// fade: [sound, 1, 0, 2000],
			},
		},
	};
}

export function playVideo(callback) {
	const timer = setInterval(function() {
		const video = document.getElementById('video');
		if (video) {
			video.load();
			video.play();
			video.onended = () => callback();
			clearInterval(timer);
		}
	}, 5);
	return {
		type: 'PLAY_VIDEO',
	};
}

export function stopVideo() {
	const video = document.getElementById('video');
	if (video) {
		video.pause();
		video.currentTime = 0;
	}
	return {
		type: 'STOP_VIDEO',
	};
}

export function setMapState(lat, long, zoom, animating, loaded) {
	return {
		type: 'SET_MAP_STATE',
		lat,
		long,
		zoom,
		animating,
		loaded,
	};
}

export function stopMapAnimation() {
	return {
		type: 'STOP_MAP_ANIMATION',
	};
}

export function setMapMarkers(mapMarkers) {
	return {
		type: 'SET_MAP_MARKERS',
		mapMarkers,
	};
}

export function setMapMarker(mediaID, isActive) {
	return {
		type: 'SET_MAP_MARKER',
		mediaID,
		isActive,
	};
}

export function resetMapMarkers() {
	return {
		type: 'RESET_MAP_MARKERS',
	};
}

export function setConnectionStatus(connectionStatus) {
	return {
		type: 'SET_CONNECTION_STATUS',
		connectionStatus,
	};
}

export function logAction(date, user, action, kind, title) {
	return {
		type: 'LOG_ACTION',
		date,
		user,
		action,
		kind,
		title,
	};
}

export function setSponsorSlot(sponsorSlot) {
	return {
		type: 'SET_SPONSOR_SLOT',
		sponsorSlot,
	};
}

export function setTranscript(transcript) {
	return {
		type: 'SET_TRANSCRIPT',
		transcript,
	};
}

export function resetState() {
	return {
		type: 'RESET_STATE',
	};
}
