import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import ScreenManager from '../components/managers/ScreenManager';
import ErrorScreen from '../components/errors/ErrorScreen';
import * as Actions from '../actions/actionCreators';
import JSONModel from '../models/jsonModel';
import XMLModel from '../models/xmlModel';
import ImageModel from '../models/imageModel';
import DataModel from '../models/dataModel';

function renderPreloadTags() {
	const preloadImages = ImageModel.getPreloadImages();
	const preloadTags = preloadImages.map((image, index) => {
		return <link rel="preload" href={image} as="image" key={index} />;
	});
	return preloadTags;
}

function renderFontStyles() {
	const headingFontPath = DataModel.getSettingsAttribute('headingFont', true);
	const copyFontPath = DataModel.getSettingsAttribute('copyFont', true);
	if (headingFontPath && copyFontPath) {
		const headingFontFace = `@font-face { font-family: 'Heading'; src: url(content/${headingFontPath});`;
		const copyFontFace = `@font-face { font-family: 'Copy'; src: url(content/${copyFontPath});`;
		return (
			<Helmet>
				<style type="text/css">{headingFontFace}</style>
				<style type="text/css">{copyFontFace}</style>
			</Helmet>
		);
	}
	if (headingFontPath) {
		const headingFontFace = `@font-face { font-family: 'Heading'; src: url(content/${headingFontPath});`;
		return (
			<Helmet>
				<style type="text/css">{headingFontFace}</style>
			</Helmet>
		);
	}
	if (copyFontPath) {
		const copyFontFace = `@font-face { font-family: 'Copy'; src: url(content/${copyFontPath});`;
		return (
			<Helmet>
				<style type="text/css">{copyFontFace}</style>
			</Helmet>
		);
	}
	return null;
}

class App extends React.PureComponent {
	componentWillMount() {
		const { actions } = this.props;
		const { setDataReady, setSettingsReady, setError } = actions;
		JSONModel.getSavedSettings(setSettingsReady);
		XMLModel.generateJSON(setDataReady, setError);
	}

	render() {
		const { state, actions } = this.props;
		const { dataReady, settingsReady, error, errorTitle, errorDescription } = state;
		const { setView, setPopup, setCarousel, setError } = actions;
		if (error) {
			return (
				<div className="application">
					<ErrorScreen
						title={errorTitle}
						description={errorDescription}
						setView={setView}
						setPopup={setPopup}
						setCarousel={setCarousel}
						setError={setError}
					/>
				</div>
			);
		}
		if (dataReady && settingsReady) {
			return (
				<div className="application">
					<Helmet>
						<meta charset="utf-8" />
						<link rel="shortcut icon" href="/favicon.ico" />
						<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
						{renderPreloadTags()}
						<title>Multimedia Gallery</title>
					</Helmet>
					{renderFontStyles()}
					<ScreenManager state={state} actions={actions} />
				</div>
			);
		}
		return (
			<div className="application">
				<div className="spinner-screen">
					<div className="spinner-content">
						<i className="fas fa-spinner fa-spin" />
					</div>
				</div>
			</div>
		);
	}
}

/* eslint-disable */
App.propTypes = {
	state: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};
/* eslint-enable */

const mapStateToProps = (state) => ({ state });
const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(App);
