import React from 'react';
import PropTypes from 'prop-types';
import MediaType from '../common/MediaType';
import DataModel from '../../models/dataModel';
import Logger from '../../helpers/logger';

function renderMediaTypeButtons(activeTypeFilter, setFilter) {
	const allMediaTypes = DataModel.getAllMediaTypes();
	const allButtonClass = activeTypeFilter === 'all' ? 'active' : '';
	const buttons = allMediaTypes.map((mediaType) => {
		const buttonClass = mediaType === activeTypeFilter ? 'active' : '';
		return (
			<button
				className={buttonClass}
				type="button"
				key={mediaType}
				onClick={() => {
					setFilter('type', mediaType);
					Logger.log('set-media-filter', mediaType);
				}}>
				<MediaType mediaType={mediaType} />
			</button>
		);
	});
	return (
		<div>
			<button
				className={allButtonClass}
				type="button"
				onClick={() => {
					setFilter('type', 'all');
					Logger.log('set-media-filter', 'all');
				}}>
				All
			</button>
			{buttons}
		</div>
	);
}

function renderMediaTopicButtons(activeTopicFilter, setFilter) {
	const allMediaTopics = DataModel.getAllMediaTopics();
	const allButtonClass = activeTopicFilter === 'all' ? 'active' : '';
	const buttons = allMediaTopics.map((mediaTopic) => {
		const buttonClass = mediaTopic.id === activeTopicFilter ? 'active' : '';
		return (
			<div className="filter-button-container" key={mediaTopic.id}>
				<button
					className={buttonClass}
					type="button"
					onClick={() => {
						setFilter('topic', mediaTopic.id);
						Logger.log('set-topic-filter', mediaTopic.title);
					}}>
					{mediaTopic.title}
				</button>
			</div>
		);
	});
	return (
		<div className="filter-by-topic-columns">
			<button
				className={allButtonClass}
				type="button"
				onClick={() => {
					setFilter('topic', 'all');
					Logger.log('set-topic-filter', 'all');
				}}>
				All
			</button>
			{buttons}
		</div>
	);
}

class Filters extends React.PureComponent {
	componentWillUnmount() {
		const { setFilter } = this.props;
		setFilter('type', 'all');
		setFilter('topic', 'all');
	}

	render() {
		const { activeTypeFilter, activeTopicFilter, setFilter } = this.props;
		return (
			<div className="filter-area">
				<h1 className="filter-heading">Filter Stories</h1>
				<div className="filter-by-type">
					<h2 className="filter-title">by Media Type</h2>
					<div className="filter-buttons">{renderMediaTypeButtons(activeTypeFilter, setFilter)}</div>
				</div>
				<div className="filter-by-topic">
					<h2 className="filter-title">by Topic</h2>
					<div className="filter-buttons">{renderMediaTopicButtons(activeTopicFilter, setFilter)}</div>
				</div>
			</div>
		);
	}
}

Filters.propTypes = {
	activeTypeFilter: PropTypes.string.isRequired,
	activeTopicFilter: PropTypes.string.isRequired,
	setFilter: PropTypes.func.isRequired,
};

export default Filters;
