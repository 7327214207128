import React from 'react';
import PropTypes from 'prop-types';
import SelectorButton from './SelectorButton';
import DataModel from '../../../models/dataModel';

function renderPopup(mediaID, isActive, tip, style, setMedia, stopMapAnimation, playVideo) {
	if (isActive) {
		const className = `mapboxgl-popup mapboxgl-popup-anchor-${tip} local-popup local-popup-${tip}`;
		return (
			<div className={className} style={style}>
				<div className="mapboxgl-popup-tip" />
				<div className="mapboxgl-popup-content">
					<SelectorButton mediaID={mediaID} setMedia={setMedia} stopMapAnimation={stopMapAnimation} playVideo={playVideo} />
				</div>
			</div>
		);
	}
	return null;
}

class MapMarker extends React.PureComponent {
	handleClickPin() {
		const { mediaID, isActive, setMapMarker, stopMapAnimation, resetMapMarkers } = this.props;
		resetMapMarkers();
		stopMapAnimation();
		setMapMarker(mediaID, !isActive);
	}

	render() {
		const { mediaID, isActive, xPos, yPos, tip, setMedia, stopMapAnimation, playVideo } = this.props;
		const thumbnail = DataModel.getMediaThumbnail(mediaID);
		const style = {
			transform: `translate(${xPos}px, ${yPos}px) translate(-50%, -50%)`,
		};
		if (isNaN(xPos) || isNaN(yPos)) {
			return null;
		}
		return (
			<div aria-hidden="true" className="map-marker">
				<img className="marker mapboxgl-marker" src={thumbnail} alt="" onClick={() => this.handleClickPin()} style={style} />
				{renderPopup(mediaID, isActive, tip, style, setMedia, stopMapAnimation, playVideo)}
			</div>
		);
	}
}

MapMarker.propTypes = {
	mediaID: PropTypes.string.isRequired,
	isActive: PropTypes.bool.isRequired,
	xPos: PropTypes.number.isRequired,
	yPos: PropTypes.number.isRequired,
	tip: PropTypes.string.isRequired,
	setMedia: PropTypes.func.isRequired,
	stopMapAnimation: PropTypes.func.isRequired,
	setMapMarker: PropTypes.func.isRequired,
	resetMapMarkers: PropTypes.func.isRequired,
	playVideo: PropTypes.func.isRequired,
};

export default MapMarker;
