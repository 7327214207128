import React from 'react';
import PropTypes from 'prop-types';
import PopupModel from '../../models/popupModel';
import Logger from '../../helpers/logger';
import Focus from '../../helpers/focus';

class PopupFooter extends React.PureComponent {
	render() {
		const { viewID, mediaID, popupID, setPopup } = this.props;
		const showFooter = PopupModel.getPopupFooterVisibility(popupID);
		if (showFooter) {
			return (
				<div className="footer-bar">
					<ul>
						<li>
							<button
								type="button"
								onClick={() => {
									setPopup('');
									if (viewID === 'menu' && mediaID) {
										Focus.set('media');
									} else {
										Focus.set(viewID);
									}
									Logger.log('close-popup');
								}}>
								<i className="fas fa-times" />
								&nbsp;&nbsp;&nbsp;Close
							</button>
						</li>
						<li>
							<h1 className="page-title-breadcrumb">{PopupModel.getPopupTitle(popupID)}</h1>
						</li>
					</ul>
					<div />
				</div>
			);
		}
		return null;
	}
}

PopupFooter.propTypes = {
	viewID: PropTypes.string.isRequired,
	popupID: PropTypes.string.isRequired,
	setPopup: PropTypes.func.isRequired,
};

export default PopupFooter;
