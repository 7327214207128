import React from 'react';
import PropTypes from 'prop-types';

class QuitPopup extends React.PureComponent {
	render() {
		const { setPopup } = this.props;
		return (
			<div className="credits-popup">
				<h1>Quit Multimedia Gallery?</h1>
				<div className="buttons-inline">
					<button
						className="button"
						onClick={() => {
							setPopup('password');
						}}>
						Yes
					</button>
					<button
						className="button"
						onClick={() => {
							setPopup('');
						}}>
						No
					</button>
				</div>
			</div>
		);
	}
}

QuitPopup.propTypes = {
	setPopup: PropTypes.func.isRequired,
};

export default QuitPopup;
