import React from 'react';
import PropTypes from 'prop-types';
import WelcomePopupContainer from '../../containers/WelcomePopupContainer';
import CreditsPopup from './CreditsPopup';
import QuitPopup from './QuitPopup';
import PasswordPopup from './PasswordPopup';
import TranscriptPopup from './TranscriptPopup';
import InvalidPopup from './InvalidPopup';
import PopupFooter from './PopupFooter';
import PopupModel from '../../models/popupModel';

function renderPopup(popupID, transcript, setPopup) {
	if (popupID === 'welcome') {
		return <WelcomePopupContainer />;
	} else if (popupID === 'credits') {
		return <CreditsPopup setPopup={setPopup} />;
	} else if (popupID === 'quit') {
		return <QuitPopup setPopup={setPopup} />;
	} else if (popupID === 'password') {
		return <PasswordPopup setPopup={setPopup} />;
	} else if (popupID === 'transcript') {
		return <TranscriptPopup transcript={transcript} />;
	} else if (popupID === 'invalid') {
		return <InvalidPopup setPopup={setPopup} />;
	}
}

class Popup extends React.PureComponent {
	render() {
		const { activeViewID, activeMediaID, activePopupID, transcript, setPopup } = this.props;
		if (activePopupID && activePopupID !== 'toc' && activePopupID !== 'help') {
			let className = 'popup-container';
			const popupSize = PopupModel.getPopupSize(activePopupID);
			className += ` popup-${popupSize}`;
			return (
				<div className="popup">
					<div className={className}>
						<div className="popup-content">{renderPopup(activePopupID, transcript, setPopup)}</div>
						<PopupFooter viewID={activeViewID} mediaID={activeMediaID} popupID={activePopupID} setPopup={setPopup} />
					</div>
				</div>
			);
		}
		return null;
	}
}

Popup.propTypes = {
	activeViewID: PropTypes.string.isRequired,
	activeMediaID: PropTypes.string.isRequired,
	activePopupID: PropTypes.string.isRequired,
	transcript: PropTypes.array.isRequired,
	setPopup: PropTypes.func.isRequired,
};

export default Popup;
