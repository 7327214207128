import React from 'react';
import PropTypes from 'prop-types';
import DataModel from '../../../models/dataModel';
import Focus from '../../../helpers/focus';

function toMinutes(time) {
	if (time) {
		let seconds = Math.round(time);
		const minutes = (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ':' : ':0') + seconds;
		return minutes;
	}
	return '0:00';
}

class Video extends React.PureComponent {
	constructor(props) {
		super(props);
		const { captionSetting } = this.props;
		this.state = { playing: true, percent: 0, captions: captionSetting, currentTime: 0, duration: 0, showTranscript: false };
	}

	componentDidMount() {
		const video = document.getElementById('video');
		const { captionSetting } = this.props;
		if (captionSetting) {
			video.textTracks[0].mode = 'showing';
		} else {
			video.textTracks[0].mode = 'hidden';
		}
	}

	handleTimeUpdate(resetTimer) {
		const video = document.getElementById('video');
		const percent = Math.floor((video.currentTime / video.duration) * 100000) / 1000;
		const currentTime = video.currentTime;
		const duration = video.duration;
		this.setState({ percent, currentTime, duration });
		resetTimer();
	}

	handlePauseClick() {
		const video = document.getElementById('video');
		video.pause();
		this.setState({ playing: false });
	}

	handlePlayClick() {
		const video = document.getElementById('video');
		video.play();
		this.setState({ playing: true });
	}

	handleTrackbarClick(e) {
		const video = document.getElementById('video');
		const trackbar = document.getElementById('video-trackbar');
		const rect = trackbar.getBoundingClientRect();
		const width = trackbar.clientWidth;
		const x = e.clientX - rect.left;
		const position = x / width;
		const duration = video.duration;
		if (duration) {
			video.currentTime = duration * position;
		}
	}

	renderPausePlayButton() {
		if (this.state.playing) {
			return (
				<button aria-label="pause video" onClick={() => this.handlePauseClick()}>
					<i className="fas fa-pause" />
				</button>
			);
		} else {
			return (
				<button aria-label="play video" onClick={() => this.handlePlayClick()}>
					<i className="fas fa-play" />
				</button>
			);
		}
	}

	handleCaptionsOn() {
		const video = document.getElementById('video');
		video.textTracks[0].mode = 'showing';
		this.setState({ captions: true });
	}

	handleCaptionsOff() {
		const video = document.getElementById('video');
		video.textTracks[0].mode = 'hidden';
		this.setState({ captions: false });
	}

	renderCaptionToggleButton() {
		if (this.state.captions) {
			return (
				<button aria-label="turn captions off" onClick={() => this.handleCaptionsOff()}>
					<i className="fas fa-closed-captioning green" />
				</button>
			);
		} else {
			return (
				<button aria-label="turn captions on" onClick={() => this.handleCaptionsOn()}>
					<i className="far fa-closed-captioning" />
				</button>
			);
		}
	}

	renderTranscriptButton(transcript, setPopup, setTranscript) {
		if (transcript) {
			return (
				<button
					className="button-transcript button-video-transcript"
					onClick={() => this.handleOpenTranscriptPopup(transcript, setPopup, setTranscript)}>
					Transcript
				</button>
			);
		}
		return null;
	}

	handleOpenTranscriptPopup(transcript, setPopup, setTranscript) {
		this.handlePauseClick();
		setTranscript(transcript);
		setPopup('transcript');
		Focus.set('transcript-popup');
	}

	render() {
		const { mediaID, resetTimer, setPopup, setTranscript } = this.props;
		const fitStyle = DataModel.getMediaFit(mediaID);
		const style = { width: `${this.state.percent}%` };
		const src = mediaID === 'help' ? DataModel.getHelpFile() : DataModel.getMediaSource(mediaID);
		const subtitles = mediaID === 'help' ? DataModel.getHelpSubtitles() : DataModel.getMediaSubtitles(mediaID);
		const transcript = DataModel.getMediaTranscript(mediaID);
		return (
			<div>
				<div className="media-area media-area-video">
					<video className="video" id="video" style={fitStyle} onTimeUpdate={() => this.handleTimeUpdate(resetTimer)}>
						<source src={src} type="video/mp4" />
						<track label="English" kind="subtitles" srcLang="en" src={subtitles} default />
						<track label="English" kind="descriptions" srcLang="en" src={subtitles} default />
					</video>
				</div>
				<div className="control-bar control-bar-video">
					<div className="video-controls">
						{this.renderPausePlayButton()}
						<div className="video-time" aria-live="off">
							{toMinutes(this.state.currentTime)} / {toMinutes(this.state.duration)}
						</div>
						<div className="video-progress" id="video-trackbar" onClick={(e) => this.handleTrackbarClick(e)}>
							<div className="video-progress-track" style={style} />
						</div>
						{this.renderCaptionToggleButton()}
						{this.renderTranscriptButton(transcript, setPopup, setTranscript)}
					</div>
				</div>
			</div>
		);
	}
}

Video.propTypes = {
	mediaID: PropTypes.string.isRequired,
	captionSetting: PropTypes.bool.isRequired,
	resetTimer: PropTypes.func.isRequired,
	setPopup: PropTypes.func.isRequired,
	setTranscript: PropTypes.func.isRequired,
};

export default Video;
