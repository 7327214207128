import React from 'react';
import PropTypes from 'prop-types';
import DataModel from '../../../models/dataModel';
import VideoHelper from '../../../helpers/videoHelper';
import Logger from '../../../helpers/logger';
import Focus from '../../../helpers/focus';

function handleCloseMedia(parent, depth, activeVolume, setMedia, playSound, resetTimer) {
	setMedia(parent);
	if (depth === 1) {
		const videoBackground = DataModel.getHomeAttribute('backgroundVideo', true);
		if (!videoBackground) {
			playSound('intro', activeVolume);
		}
		VideoHelper.fadeVolume(activeVolume);
		Logger.log('open-screen', 'main-menu', 'Main Menu');
	} else {
		Logger.log('open-media', 'map', DataModel.getMediaTitle(parent));
	}
	resetTimer();
}

function renderIframe(mediaID, connectionStatus, activeVolume, setMedia, playSound, resetTimer) {
	const interactiveType = DataModel.getInteractiveType(mediaID);
	const mediaURL = DataModel.getMediaURL(mediaID);
	const url = interactiveType === 'local' ? `content/${mediaURL}` : mediaURL;
	if (interactiveType === 'remote' && connectionStatus === 'offline') {
		const parent = DataModel.getMediaParent(mediaID) ? DataModel.getMediaParent(mediaID) : '';
		const depth = DataModel.getMediaDepth(mediaID);
		return (
			<div className="no-connection-screen">
				<div className="error-content">
					<h2>
						<i className="fas fa-exclamation-triangle" />
						&nbsp;&nbsp;Sorry. This interactive is not available.
					</h2>
					<p>Unable to connect to the network. Please try again later.</p>
					<button type="button" onClick={() => handleCloseMedia(parent, depth, activeVolume, setMedia, playSound, resetTimer)}>
						Continue
					</button>
				</div>
			</div>
		);
	}
	if (window.ipcRenderer && interactiveType === 'local') {
		return <iframe className="iframe" title="website" src={url} />;
	} else {
		return <iframe className="iframe" title="website" src={url} />;
	}
}

function renderTranscriptButton(transcript, setPopup, setTranscript) {
	if (transcript) {
		return (
			<button
				className="button-transcript button-html-transcript"
				onClick={() => handleOpenTranscriptPopup(transcript, setPopup, setTranscript)}>
				Transcript
			</button>
		);
	}
	return null;
}

function handleOpenTranscriptPopup(transcript, setPopup, setTranscript) {
	setTranscript(transcript);
	setPopup('transcript');
	Focus.set('transcript-popup');
}

class HTML extends React.PureComponent {
	render() {
		const { mediaID, connectionStatus, activeVolume, setMedia, playSound, resetTimer, setPopup, setTranscript } = this.props;
		const transcript = DataModel.getMediaTranscript(mediaID);
		return (
			<div>
				<div className="media-area" id="media-area">
					{renderIframe(mediaID, connectionStatus, activeVolume, setMedia, playSound, resetTimer)}
				</div>
				<div className="button-outside">{renderTranscriptButton(transcript, setPopup, setTranscript)}</div>
			</div>
		);
	}
}

HTML.propTypes = {
	mediaID: PropTypes.string.isRequired,
	activeVolume: PropTypes.number.isRequired,
	connectionStatus: PropTypes.string.isRequired,
	setMedia: PropTypes.func.isRequired,
	playSound: PropTypes.func.isRequired,
	resetTimer: PropTypes.func.isRequired,
	setPopup: PropTypes.func.isRequired,
	setTranscript: PropTypes.func.isRequired,
};

export default HTML;
