import React from 'react';
import PropTypes from 'prop-types';

class PasswordPopup extends React.Component {
	render() {
		const { setPopup } = this.props;
		return (
			<div className="credits-popup">
				<h1>Invalid PIN</h1>
				<div className="buttons-inline">
					<button
						className="button"
						onClick={() => {
							setPopup('');
						}}>
						Continue
					</button>
				</div>
			</div>
		);
	}
}

PasswordPopup.propTypes = {
	setPopup: PropTypes.func.isRequired,
};

export default PasswordPopup;
