import DataModel from './dataModel';
import uniq from 'lodash/uniq';

class ImageModel {
	static getAllThumbnails() {
		const allMedia = DataModel.getAllMedia();
		const mediaWithThumbnails = allMedia.filter((element) => element.thumb);
		return mediaWithThumbnails.map((element) => `content/${element.thumb}`);
	}

	static getPreloadImages() {
		const allThumbnails = ImageModel.getAllThumbnails();
		const uniquePreloadImages = uniq(allThumbnails);
		return uniquePreloadImages;
	}
}

export default ImageModel;
