import React from 'react';
import PropTypes from 'prop-types';
import DataModel from '../../../models/dataModel';

function setImageStyle(imageElement, x, y, scale) {
	if (imageElement) {
		imageElement.style.transform = `scale(${scale}) translate3D(${x}px, ${y}px, 0)`;
	}
}

class GalleryImage extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = { scale: 1, x: 0, y: 0 };
		this.animating = false;
	}

	componentDidMount() {
		const { imageID } = this.props;
		this.imageElement = document.getElementById(imageID);
		const imageWidth = this.imageElement.offsetWidth;
		const imageHeight = this.imageElement.offsetHeight;
		this.imageDimensions = { width: imageWidth, height: imageHeight };
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		const { mediaID, path, imageID, command, active, altText } = this.props;
		const fitStyle = DataModel.getMediaFit(mediaID);
		const ariaHidden = active ? false : true;
		this.command = command;
		this.active = active;
		if (this.imageElement) {
			setImageStyle(this.imageElement, this.state.x, this.state.y, this.state.scale);
		}
		return <img aria-hidden={ariaHidden} src={path} alt={altText} id={imageID} style={fitStyle} tabIndex="-1" />;
	}
}

GalleryImage.propTypes = {
	mediaID: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	imageID: PropTypes.string.isRequired,
	command: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	altText: PropTypes.string,
	setCommand: PropTypes.func.isRequired,
};

export default GalleryImage;
