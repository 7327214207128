class JSONModel {
	static getSavedSettings(setSettingsReady) {
		fetch('content/settings.json')
			.then(function(response) {
				if (!response.ok) {
					return null;
				}
				return response.json();
			})
			.then(function(result) {
				if (result.stat === 'fail') {
					window.savedSettings = null;
				} else {
					window.savedSettings = result;
				}
				setSettingsReady(true);
			})
			.catch(function(err) {
				window.savedSettings = null;
				setSettingsReady(true);
			});
	}
}

export default JSONModel;
