import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WelcomePopup from '../components/popup/WelcomePopup';
import * as Actions from '../actions/actionCreators';

const WelcomePopupContainer = ({ state, actions }) => <WelcomePopup state={state} actions={actions} />;

/* eslint-disable */
WelcomePopupContainer.propTypes = {
	state: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};
/* eslint-enable */

const mapStateToProps = (state) => ({ state });
const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WelcomePopupContainer);
