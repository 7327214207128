import React from 'react';
import PropTypes from 'prop-types';
import MapMenu from './MapMenu';
import MapLocal from './MapLocal';
import MapOnline from './MapOnline';
import DataModel from '../../../models/dataModel';

function renderMapLogo(mediaID) {
	const logo = DataModel.getSettingsAttribute('mapLogo', true);
	if (logo) {
		const logoPath = `content/${logo}`;
		return (
			<div className="map-logo">
				<img className="logo" src={logoPath} alt="" />
			</div>
		);
	}
	return null;
}

function renderMapTitle(mediaID) {
	const showTitle = DataModel.getMediaAttribute(mediaID, 'showTitle', true);
	const title = DataModel.getMediaTitle(mediaID);
	if (showTitle) {
		return (
			<div className="map-title">
				<h1 className="title">{title}</h1>
			</div>
		);
	}
}

function renderActiveMap(
	selector,
	activeMapID,
	mapState,
	mapMarkers,
	webMode,
	setMedia,
	playVideo,
	setSelector,
	setMapState,
	stopMapAnimation,
	setMapMarkers,
	setMapMarker,
	resetMapMarkers
) {
	if (webMode) {
		return <MapOnline activeMapID={activeMapID} setMedia={setMedia} playVideo={playVideo} setMapState={setMapState} />;
	} else {
		return (
			<MapLocal
				activeMapID={activeMapID}
				mapState={mapState}
				mapMarkers={mapMarkers}
				setMedia={setMedia}
				playVideo={playVideo}
				setMapState={setMapState}
				stopMapAnimation={stopMapAnimation}
				setMapMarkers={setMapMarkers}
				setMapMarker={setMapMarker}
				resetMapMarkers={resetMapMarkers}
			/>
		);
	}
}

function renderInactiveMap(webMode, setMedia, playVideo, setMapState) {
	if (webMode) {
		return (
			<div aria-hidden="true" className="map-view" style={{ opacity: 0, zIndex: -20 }}>
				<MapOnline activeMapID="default" setMedia={setMedia} playVideo={playVideo} setMapState={setMapState} />
			</div>
		);
	}
	return null;
}

class MapView extends React.PureComponent {
	render() {
		const { state, actions } = this.props;
		const { activeViewID, activeMediaID, activePopupID, mapState, mapMarkers, selector, webMode } = state;
		const { setMedia, setSelector, playVideo, setMapState, stopMapAnimation, setMapMarkers, setMapMarker, resetMapMarkers } = actions;
		if (activeViewID === 'map') {
			const mediaType = DataModel.getMediaType(activeMediaID);
			let activeMapID;
			if (mediaType === 'map') {
				activeMapID = activeMediaID;
			} else {
				activeMapID = DataModel.getMediaParent(activeMediaID);
			}
			const ariaHidden = activePopupID !== '' || mediaType !== 'map' ? true : false;
			return (
				<div id="map" tabIndex="-1" className="map-view" aria-hidden={ariaHidden}>
					{renderActiveMap(
						selector,
						activeMapID,
						mapState,
						mapMarkers,
						webMode,
						setMedia,
						playVideo,
						setSelector,
						setMapState,
						stopMapAnimation,
						setMapMarkers,
						setMapMarker,
						resetMapMarkers
					)}
					<MapMenu
						activeMapID={activeMapID}
						selector={selector}
						setMedia={setMedia}
						setSelector={setSelector}
						stopMapAnimation={stopMapAnimation}
						playVideo={playVideo}
					/>
					{renderMapLogo(activeMediaID)}
					{renderMapTitle(activeMediaID)}
				</div>
			);
		}
		return <div aria-hidden="true">{renderInactiveMap(webMode, setMedia, playVideo, setMapState)}</div>;
	}
}

MapView.propTypes = {
	state: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

export default MapView;
