import store from '../index';
import { setError } from '../actions/actionCreators';

class ErrorModel {
	static show(title, description) {
		store.dispatch(setError(true, title, description));
	}

	static missingGalleryTag() {
		store.dispatch(setError(true, 'Missing <gallery> tag.', 'Unable to find a <gallery> tag at the root of the configuration XML file.'));
	}

	static missingTag(tag) {
		store.dispatch(setError(true, `Missing <${tag}> tag.`, `The <${tag}> tag is required.`));
	}

	static missingTags(tag) {
		store.dispatch(setError(true, `The <${tag}> tag is empty.`, `Unable to find any tags within <${tag}>.`));
	}

	static missingAttribute(title, attribute) {
		store.dispatch(
			setError(true, `Missing "${attribute}" attribute or tag.`, `The ${title} element is missing the "${attribute}" attribute or tag.`)
		);
	}

	static missingAttributes(title) {
		store.dispatch(setError(true, 'Missing attributes.', `The element "${title}" has no attributes.`));
	}

	static missingType(title) {
		store.dispatch(setError(true, 'Missing type attribute.', `The element "${title}" has no type attribute.`));
	}

	static unrecognizableType(title) {
		store.dispatch(
			setError(
				true,
				'Unrecognizable mediaType.',
				`The element "${title}" has an unrecognizable mediaType. Please use: video, gallery, panorama, object, or video360.`
			)
		);
	}

	static missingTitle() {
		store.dispatch(setError(true, 'Missing <title> tag.', 'Every category and media element needs a <title> tag.'));
	}

	static missingDescription() {
		store.dispatch(setError(true, 'Missing <description> tag.', 'Every category and media element needs a <description> tag.'));
	}

	static missingThumbnail(title) {
		store.dispatch(setError(true, 'Missing thumb attribute.', `The element "${title}" has no thumb attribute.`));
	}

	static missingSrc(title) {
		store.dispatch(setError(true, 'Missing file attribute.', `The element "${title}" has no file attribute.`));
	}

	static missingURL(title) {
		store.dispatch(setError(true, 'Missing URL attribute.', `The HTML element "${title}" has no URL attribute.`));
	}
}

export default ErrorModel;
