import React from 'react';
import PropTypes from 'prop-types';
import MapMarkers from './MapMarkers';
import MapTileBig from './MapTileBig';
import DataModel from '../../../models/dataModel';
import ErrorModel from '../../../models/errorModel';
import MapModel from '../../../models/mapModel';

function renderLoadingAnimation(loaded) {
	let className = 'spinner-screen';
	if (loaded) {
		className += ' spinner-screen-hidden';
	}
	return (
		<div className={className}>
			<i className="fas fa-spinner fa-spin" />
		</div>
	);
}

function renderCrosshairs() {
	const devMode = DataModel.getSettingsAttribute('devMode');
	if (devMode) {
		return (
			<div>
				<div className="map-crosshairs map-crosshairs-horizontal" />
				<div className="map-crosshairs map-crosshairs-vertical" />
			</div>
		);
	}
	return null;
}

function getMapTileData(folder, data) {
	const xSize = parseInt(data.xLongitudeSize);
	const ySize = parseInt(data.yLatitudeSize);
	let mapTiles = [];
	for (let x = 0; x < xSize; x++) {
		for (let y = 0; y < ySize; y++) {
			mapTiles.push({
				id: `${x}_${y}`,
				imagePath: `content/${folder}${x}_${y}.jpg`,
				topPos: 1024 * y,
				leftPos: 1024 * x,
			});
		}
	}
	return mapTiles;
}

function renderMapTiles(activeMapID, folder, configData, visibleMapDimensions, mapState, minZoom, maxZoom) {
	if (activeMapID !== 'default' && folder && configData) {
		const mapTiles = getMapTileData(folder, configData);
		const map = mapTiles.map((mapTile) => {
			return (
				<img
					aria-hidden="true"
					className="maptile"
					alt=""
					key={mapTile.id}
					src={mapTile.imagePath}
					style={{ top: mapTile.topPos, left: mapTile.leftPos }}
				/>
			);
		});
		const totalMapDimensions = MapModel.getTotalDimensions(configData);
		const styles = MapModel.getMapTileStyles(visibleMapDimensions, totalMapDimensions, mapState, minZoom, maxZoom);
		return (
			<div aria-hidden="true" className="maptiles" style={styles}>
				{map}
			</div>
		);
	}
}

class MapLocal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeMapID: 'default',
			folder: '',
			configData: null,
			visibleMapDimensions: null,
			bigMapDimensions: { width: 0, height: 0 },
			dragging: false,
			pinching: false,
			switchPoint: 3,
			minZoom: 1,
			maxZoom: 10,
		};
	}

	handleClickMap() {
		const { resetMapMarkers, mapState, setMapState } = this.props;
		resetMapMarkers();
		const { lat, long, zoom, loaded } = mapState;
		setMapState(lat, long, zoom, false, loaded);
	}

	handleZoomMap(zoomType, speed) {
		const { mapState, setMapState } = this.props;
		const { minZoom, maxZoom } = this.state;
		const { lat, long, zoom, loaded } = mapState;
		let newLat = lat;
		let newLong = long;
		let zoomFactor = MapModel.mapRange(zoom, 1, 10, 0.018, 0.18);
		if (speed) {
			const speedFactor = MapModel.mapRange(speed, 0, 200, 1, 80);
			zoomFactor = zoomFactor * speedFactor;
		}
		let newZoom;
		if (zoomType === 'in') {
			newZoom = zoom + zoomFactor;
			if (newZoom > maxZoom) {
				newZoom = maxZoom;
			}
		} else if (zoomType === 'out') {
			newZoom = zoom - zoomFactor;
			if (newZoom <= minZoom) {
				newZoom = minZoom;
			}
			const { visibleMapDimensions, bigMapDimensions } = this.state;
			const boundaries = MapModel.getBoundaries(newZoom, bigMapDimensions, visibleMapDimensions);
			if (lat > boundaries.maxLat) {
				newLat = boundaries.maxLat;
			} else if (lat < boundaries.minLat) {
				newLat = boundaries.minLat;
			}
			if (long > boundaries.maxLong) {
				newLong = boundaries.maxLong;
			} else if (long < boundaries.minLong) {
				newLong = boundaries.minLong;
			}
		}
		setMapState(newLat, newLong, newZoom, false, loaded);
	}

	handleAnimate(zoomType) {
		const { activeMapID, mapState, setMapState } = this.props;
		const { animating } = mapState;
		const maxZoom = DataModel.getMapMaxZoom(activeMapID);
		if (animating) {
			const initialMapState = DataModel.getLocalMapData(activeMapID);
			const initialZoom = initialMapState.zoom;
			const { lat, long, zoom, loaded } = mapState;
			const zoomFactor = MapModel.mapRange(zoom, 1, 10, 0.00004, 0.0008);
			const endZoom = MapModel.mapRange(zoom, 1, 10, 0.03, 0.8);
			let newZoom;
			newZoom = zoom + zoomFactor;
			if (newZoom > initialZoom + endZoom) {
				newZoom = initialZoom + endZoom;
			}
			if (newZoom > maxZoom) {
				newZoom = maxZoom;
			}
			setMapState(lat, long, newZoom, true, loaded);
		}
	}

	handlePanMap(panType) {
		const { mapState, setMapState } = this.props;
		const { visibleMapDimensions, bigMapDimensions } = this.state;
		const { lat, long, zoom, loaded } = mapState;
		const boundaries = MapModel.getBoundaries(zoom, bigMapDimensions, visibleMapDimensions);
		const panFactor = MapModel.mapRange(zoom, 1, 10, 15, 1);
		let newLat = lat;
		let newLong = long;
		if (panType === 'left') {
			newLat = lat - panFactor;
		} else if (panType === 'right') {
			newLat = lat + panFactor;
		} else if (panType === 'up') {
			newLong = long - panFactor;
		} else if (panType === 'down') {
			newLong = long + panFactor;
		}
		if (newLat > boundaries.maxLat) {
			newLat = boundaries.maxLat;
		} else if (newLat < boundaries.minLat) {
			newLat = boundaries.minLat;
		}
		if (newLong > boundaries.maxLong) {
			newLong = boundaries.maxLong;
		} else if (newLong < boundaries.minLong) {
			newLong = boundaries.minLong;
		}
		setMapState(newLat, newLong, zoom, false, loaded);
	}

	handleDragStart() {
		this.setState({ dragging: true });
		this.handleClickMap();
	}

	handleDragStop() {
		this.setState({ dragging: false });
	}

	handleDragMove() {
		const { dragging, visibleMapDimensions, bigMapDimensions } = this.state;
		if (dragging) {
			const { mapState, setMapState } = this.props;
			const { zoom, loaded } = mapState;
			const boundaries = MapModel.getBoundaries(zoom, bigMapDimensions, visibleMapDimensions);
			const scale = MapModel.getScale(zoom, bigMapDimensions, visibleMapDimensions);
			let newLat = this.dragStart.lat + (this.dragStart.x - this.dragMove.x) / scale;
			let newLong = this.dragStart.long + (this.dragStart.y - this.dragMove.y) / scale;
			if (newLat > boundaries.maxLat) {
				newLat = boundaries.maxLat;
			} else if (newLat < boundaries.minLat) {
				newLat = boundaries.minLat;
			}
			if (newLong > boundaries.maxLong) {
				newLong = boundaries.maxLong;
			} else if (newLong < boundaries.minLong) {
				newLong = boundaries.minLong;
			}
			setMapState(newLat, newLong, zoom, false, loaded);
		}
	}

	handlePinchStart() {
		const { visibleMapDimensions, bigMapDimensions } = this.state;
		const { mapState } = this.props;
		const { lat, long, zoom } = mapState;
		const scale = MapModel.getScale(zoom, bigMapDimensions, visibleMapDimensions);
		this.setState({ pinching: true });
		this.handleClickMap();
		const startPoint = MapModel.getCenterPoint(this.pinchStart);
		this.goalPosition = {
			x: MapModel.getPosition(startPoint.x, visibleMapDimensions.width, scale) + lat,
			y: MapModel.getPosition(startPoint.y, visibleMapDimensions.height, scale) + long,
		};
	}

	handlePinchStop() {
		this.setState({ pinching: false });
	}

	handlePinchMove() {
		const { pinching } = this.state;
		if (pinching) {
			const startDistance = MapModel.getDistance(this.pinchStart);
			const currentDistance = MapModel.getDistance(this.pinchMove);
			const speed = Math.abs(currentDistance - startDistance);
			if (currentDistance > startDistance) {
				const { mapState, setMapState } = this.props;
				const { visibleMapDimensions, bigMapDimensions, maxZoom } = this.state;
				const { zoom, loaded } = mapState;
				let zoomFactor = MapModel.mapRange(zoom, 1, 10, 0.018, 0.18);
				const speedFactor = MapModel.mapRange(speed, 0, 200, 1, 80);
				zoomFactor = zoomFactor * speedFactor;
				let newZoom = zoom + zoomFactor;
				if (newZoom > maxZoom) {
					newZoom = maxZoom;
				}
				const scale = MapModel.getScale(zoom, bigMapDimensions, visibleMapDimensions);
				const startPoint = MapModel.getCenterPoint(this.pinchStart);
				let newLat = (this.goalPosition.x * scale - (startPoint.x - visibleMapDimensions.width / 2)) / scale;
				let newLong = (this.goalPosition.y * scale - (startPoint.y - visibleMapDimensions.height / 2)) / scale;
				const boundaries = MapModel.getBoundaries(zoom, bigMapDimensions, visibleMapDimensions);
				if (newLat > boundaries.maxLat) {
					newLat = boundaries.maxLat;
				} else if (newLat < boundaries.minLat) {
					newLat = boundaries.minLat;
				}
				if (newLong > boundaries.maxLong) {
					newLong = boundaries.maxLong;
				} else if (newLong < boundaries.minLong) {
					newLong = boundaries.minLong;
				}
				setMapState(newLat, newLong, newZoom, false, loaded);
			}
			if (currentDistance < startDistance) {
				this.handleZoomMap('out', speed);
			}
			this.pinchStart = this.pinchMove;
		}
	}

	getBigMapImageDimensions(folder) {
		const self = this;
		const image = new Image();
		image.src = `content/${folder}FullMapImage.jpg`;
		image.addEventListener('load', loaded);
		function loaded() {
			const bigMapDimensions = { width: image.width, height: image.height };
			self.setState({ bigMapDimensions });
		}
	}

	resetMap() {
		const { activeMapID, setMapState, setMapMarkers } = this.props;
		const initialMapState = DataModel.getLocalMapData(activeMapID);
		const { lat, long, zoom, switchPoint, maxZoom } = initialMapState;
		const folder = DataModel.getMediaAttribute(activeMapID, 'folder', true);
		const loaded = folder === this.state.folder ? true : false;
		const mapDataURL = DataModel.getMapJSON(activeMapID);
		this.setState({ activeMapID, switchPoint, minZoom: zoom, maxZoom });
		const children = DataModel.getMediaChildren(activeMapID);
		const mapMarkers = children.map((mediaID) => {
			return { mediaID: mediaID, isActive: false };
		});
		setMapMarkers(mapMarkers);
		setMapState(lat, long, zoom, true, loaded);
		fetch(mapDataURL)
			.then((response) => response.json())
			.then((configData) => {
				this.setState({ folder, configData });
			})
			.catch(function(error) {
				ErrorModel.show(
					`There was a problem with the map configuration for ${DataModel.getMediaTitle(activeMapID)}.`,
					'The folder is not set correctly or there is not a valid MapImageLayout.json file in the folder.'
				);
			});
	}

	componentWillMount() {
		const { activeMapID, mapState } = this.props;
		const { animating } = mapState;
		this.setState({ activeMapID });
		this.command = '';
		this.xPos = 0;
		this.yPos = 0;
		this.xStart = 0;
		this.yStart = 0;
		this.interval = setInterval(() => {
			if (this.command === 'up') {
				this.handlePanMap('up');
			}
			if (this.command === 'down') {
				this.handlePanMap('down');
			}
			if (this.command === 'left') {
				this.handlePanMap('left');
			}
			if (this.command === 'right') {
				this.handlePanMap('right');
			}
			if (this.command === 'in') {
				this.handleZoomMap('in');
			}
			if (this.command === 'out') {
				this.handleZoomMap('out');
			}
			if (animating) {
				this.handleAnimate();
			}
		}, 15);
		this.componentDidUpdate();
	}

	componentDidMount() {
		const mapArea = document.getElementById('map-area');
		const mapAreaWidth = mapArea.offsetWidth;
		const mapAreaHeight = mapArea.offsetHeight;
		this.setState({ visibleMapDimensions: { width: mapAreaWidth, height: mapAreaHeight } });
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	componentDidUpdate() {
		const { activeMapID } = this.props;
		const { folder } = this.state;
		const folderBasedOnID = DataModel.getMediaAttribute(activeMapID, 'folder', true);
		if (activeMapID !== 'default' && activeMapID !== this.state.activeMapID) {
			this.resetMap();
		}
		if (folder !== folderBasedOnID) {
			this.getBigMapImageDimensions(folderBasedOnID);
		}
	}

	render() {
		const {
			activeMapID,
			mapState,
			mapMarkers,
			setMedia,
			playVideo,
			setMapState,
			stopMapAnimation,
			setMapMarker,
			resetMapMarkers,
		} = this.props;
		const { folder, configData, visibleMapDimensions, bigMapDimensions, switchPoint } = this.state;
		const { lat, long, zoom, loaded } = mapState;
		const altText = DataModel.getMediaAltText(activeMapID);
		let alt = altText[0];
		if (!alt) {
			alt = `${DataModel.getMediaTitle(activeMapID)} map`;
		} else {
			alt = `${altText}`;
		}
		const hideZoomButtons = DataModel.getMediaAttribute(activeMapID, 'hideZoomButtons', true);
		const controlStyles = hideZoomButtons ? { display: 'none' } : {};
		let buttonStyles = {
			up: controlStyles,
			down: controlStyles,
			left: controlStyles,
			right: controlStyles,
			out: controlStyles,
			in: controlStyles,
		};
		let adjustedMapState;
		if (folder) {
			const totalMapDimensions = MapModel.getTotalDimensions(configData);
			const adjustedLat = MapModel.mapRange(lat, 0, bigMapDimensions.width, 0, totalMapDimensions.width);
			const adjustedLong = MapModel.mapRange(long, 0, bigMapDimensions.height, 0, totalMapDimensions.height);
			adjustedMapState = { lat: adjustedLat, long: adjustedLong, zoom };
		} else {
			adjustedMapState = mapState;
		}
		return (
			<div className="map-area" id="map-area">
				<p className="screen-reader">{alt}</p>
				<div
					aria-hidden="true"
					className="local-map"
					onClick={() => this.handleClickMap()}
					onMouseDown={(event) => {
						event.preventDefault();
						event.stopPropagation();
						this.dragStart = { x: event.pageX, y: event.pageY, lat, long };
						this.handleDragStart();
					}}
					onTouchStart={(event) => {
						event.persist();
						event.preventDefault();
						event.stopPropagation();
						const touches = event.nativeEvent.touches.length;
						if (touches === 1) {
							this.dragStart = { x: event.nativeEvent.touches[0].pageX, y: event.nativeEvent.touches[0].pageY, lat, long };
							this.handleDragStart();
						} else if (touches > 1) {
							this.pinchStart = {
								x1: event.nativeEvent.touches[0].pageX,
								y1: event.nativeEvent.touches[0].pageY,
								x2: event.nativeEvent.touches[1].pageX,
								y2: event.nativeEvent.touches[1].pageY,
								zoom,
								lat,
								long,
							};
							this.handlePinchStart();
						}
					}}
					onMouseMove={(event) => {
						event.preventDefault();
						event.stopPropagation();
						this.dragMove = { x: event.pageX, y: event.pageY };
						this.handleDragMove();
					}}
					onTouchMove={(event) => {
						event.persist();
						event.preventDefault();
						event.stopPropagation();
						const touches = event.nativeEvent.touches.length;
						if (touches === 1) {
							this.dragMove = { x: event.nativeEvent.touches[0].pageX, y: event.nativeEvent.touches[0].pageY };
							this.handleDragMove();
						} else if (touches > 1) {
							this.pinchMove = {
								x1: event.nativeEvent.touches[0].pageX,
								y1: event.nativeEvent.touches[0].pageY,
								x2: event.nativeEvent.touches[1].pageX,
								y2: event.nativeEvent.touches[1].pageY,
							};
							this.handlePinchMove();
						}
					}}
					onMouseUp={() => {
						this.handleDragStop();
					}}
					onTouchEnd={() => {
						this.handleDragStop();
						this.handlePinchStop();
					}}>
					{renderLoadingAnimation(loaded)}
					<MapTileBig
						mapID={activeMapID}
						folder={folder}
						configData={configData}
						visibleMapDimensions={visibleMapDimensions}
						bigMapDimensions={bigMapDimensions}
						mapState={mapState}
						minZoom={1}
						maxZoom={switchPoint}
						setMapState={setMapState}
					/>
					{renderMapTiles(activeMapID, folder, configData, visibleMapDimensions, adjustedMapState, switchPoint, 10)}
				</div>
				<MapMarkers
					visibleMapDimensions={visibleMapDimensions}
					bigMapDimensions={bigMapDimensions}
					mapState={mapState}
					mapMarkers={mapMarkers}
					setMedia={setMedia}
					stopMapAnimation={stopMapAnimation}
					setMapMarker={setMapMarker}
					resetMapMarkers={resetMapMarkers}
					playVideo={playVideo}
				/>
				{renderCrosshairs()}
				<div aria-hidden="true" className="control-bar control-bar-map">
					<div className="buttons buttons-clear">
						<button
							tabIndex="-1"
							id="pan-left"
							aria-label="pan left"
							style={buttonStyles.left}
							onTouchStart={() => (this.command = 'left')}
							onMouseDown={() => (this.command = 'left')}
							onKeyDown={(e) => {
								if (e.keyCode === 13 || e.keyCode === 32) {
									this.command = 'left';
								}
							}}
							onMouseUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}
							onKeyUp={() => (this.command = '')}>
							<i className="fas fa-arrow-circle-left" />
						</button>
						<button
							tabIndex="-1"
							id="pan-down"
							aria-label="pan down"
							style={buttonStyles.down}
							onTouchStart={() => (this.command = 'down')}
							onMouseDown={() => (this.command = 'down')}
							onKeyDown={(e) => {
								if (e.keyCode === 13 || e.keyCode === 32) {
									this.command = 'down';
								}
							}}
							onMouseUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}
							onKeyUp={() => (this.command = '')}>
							<i className="fas fa-arrow-circle-down" />
						</button>
						<button
							tabIndex="-1"
							id="pan-up"
							aria-label="pan up"
							style={buttonStyles.up}
							onTouchStart={() => (this.command = 'up')}
							onMouseDown={() => (this.command = 'up')}
							onKeyDown={(e) => {
								if (e.keyCode === 13 || e.keyCode === 32) {
									this.command = 'up';
								}
							}}
							onMouseUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}
							onKeyUp={() => (this.command = '')}>
							<i className="fas fa-arrow-circle-up" />
						</button>
						<button
							tabIndex="-1"
							id="pan-right"
							aria-label="pan right"
							style={buttonStyles.right}
							onTouchStart={() => (this.command = 'right')}
							onMouseDown={() => (this.command = 'right')}
							onKeyDown={(e) => {
								if (e.keyCode === 13 || e.keyCode === 32) {
									this.command = 'right';
								}
							}}
							onMouseUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}
							onKeyUp={() => (this.command = '')}>
							<i className="fas fa-arrow-circle-right" />
						</button>
					</div>
					<div className="buttons buttons-clear">
						<button
							tabIndex="-1"
							id="zoom-out"
							aria-label="zoom out"
							style={buttonStyles.out}
							onTouchStart={() => (this.command = 'out')}
							onMouseDown={() => (this.command = 'out')}
							onKeyDown={(e) => {
								if (e.keyCode === 13 || e.keyCode === 32) {
									this.command = 'out';
								}
							}}
							onMouseUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}
							onKeyUp={() => (this.command = '')}>
							<i className="fas fa-minus-circle" />
						</button>
						<button
							tabIndex="-1"
							id="zoom-in"
							aria-label="zoom in"
							style={buttonStyles.in}
							onTouchStart={() => (this.command = 'in')}
							onMouseDown={() => (this.command = 'in')}
							onKeyDown={(e) => {
								if (e.keyCode === 13 || e.keyCode === 32) {
									this.command = 'in';
								}
							}}
							onMouseUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}
							onKeyUp={() => (this.command = '')}>
							<i className="fas fa-plus-circle" />
						</button>
					</div>
				</div>
			</div>
		);
	}
}

MapLocal.propTypes = {
	activeMapID: PropTypes.string,
	mapState: PropTypes.object.isRequired,
	mapMarkers: PropTypes.array.isRequired,
	setMedia: PropTypes.func.isRequired,
	playVideo: PropTypes.func.isRequired,
	setMapState: PropTypes.func.isRequired,
	stopMapAnimation: PropTypes.func.isRequired,
	setMapMarkers: PropTypes.func.isRequired,
	setMapMarker: PropTypes.func.isRequired,
	resetMapMarkers: PropTypes.func.isRequired,
};

export default MapLocal;
