import store from '../index';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import format from 'date-fns/format';

class Logger {
	static log(action, kind, title) {
		const date = new Date();
		const duration = Logger.getDuration(action, date);
		const logData = {
			date: format(date, 'MM/DD/YYYY'),
			time: format(date, 'h:mm:ss a'),
			action,
			kind: Logger.formatMediaType(kind),
			title: Logger.formatMediaTitle(title),
			duration,
		};
		if (Logger.getLastLogItemType() === 'timeout' && logData.kind === 'timeout') {
			// Don't log consecutive timeout events
		} else {
			if (window.ipcRenderer) {
				window.ipcRenderer.send('write-to-log', logData);
			}
			store.dispatch({
				type: 'LOG_ACTION',
				date,
				user: 'user',
				action,
				kind,
				title,
				duration,
			});
		}
	}

	static getDuration(action, date) {
		const state = store.getState();
		let duration = '';
		if (state.sessionStartDate && action === 'end-session') {
			duration = differenceInSeconds(date, state.sessionStartDate);
		}
		return Logger.formatSeconds(duration);
	}

	static getLastLogItem() {
		const state = store.getState();
		const { log } = state;
		const lastLogItem = log[log.length - 1];
		if (lastLogItem) {
			return lastLogItem;
		}
		return null;
	}

	static getLastLogItemType() {
		const lastLogItem = Logger.getLastLogItem();
		if (lastLogItem) {
			return lastLogItem.type;
		}
		return null;
	}

	static formatMediaTitle(title) {
		if (!title) {
			return '';
		}
		return title;
	}

	static formatMediaType(kind) {
		if (kind === 'html') {
			return 'interactive';
		}
		if (!kind) {
			return '';
		}
		return kind;
	}

	static formatSeconds(seconds) {
		if (seconds || seconds === 0) {
			var hrs = ~~(seconds / 3600);
			var mins = ~~((seconds % 3600) / 60);
			var secs = ~~seconds % 60;
			var ret = '';
			if (hrs > 0) {
				ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
			}
			ret += '' + mins + ':' + (secs < 10 ? '0' : '');
			ret += '' + secs;
			return ret;
		}
		return '';
	}
}

export default Logger;
