import popups from '../data/popups';

class PopupModel {
	static getPopupData(popupID) {
		return popups.find((popup) => popup.id === popupID);
	}

	static getPopupTitle(popupID) {
		const popup = PopupModel.getPopupData(popupID);
		return popup.title;
	}

	static getPopupSize(popupID) {
		const popup = PopupModel.getPopupData(popupID);
		return popup.size;
	}

	static getPopupFooterVisibility(popupID) {
		const popup = PopupModel.getPopupData(popupID);
		return popup.footer;
	}
}

export default PopupModel;
