import React from 'react';
import PropTypes from 'prop-types';
import HomeView from '../home/HomeView';
import MenuView from '../menu/MenuView';
import MapView from '../media/maps/MapView';
import FooterBar from '../footer/FooterBar';
import Popup from '../popup/Popup';
import MediaView from '../media/MediaView';
import TableOfContents from '../toc/TableOfContents';
import HelpPopup from '../popup/HelpPopup';
import DevTools from '../dev/DevTools';
import TimerPopup from '../popup/TimerPopup';
import Background from '../home/Background';
import DataModel from '../../models/dataModel';
import Logger from '../../helpers/logger';
import Focus from '../../helpers/focus';

function handleScroll() {
	const view = document.getElementById('view');
	view.scrollTop = 0;
}

class ViewManager extends React.PureComponent {
	componentWillMount() {
		const { actions } = this.props;
		const { setTimer } = actions;
		const timeout = DataModel.getSettingsAttribute('timeout');
		const timeoutLong = DataModel.getSettingsAttribute('timeoutLong');
		setTimer(timeout, timeout, timeoutLong);
	}

	componentDidMount() {
		const { actions } = this.props;
		const { setPopup, stopSound, playVideo, stopVideo } = actions;
		const helpFileType = DataModel.getHelpFileType();
		if (window.ipcRenderer) {
			window.ipcRenderer.on('show-help-popup', (event) => {
				setPopup('help');
				stopSound('intro');
				stopVideo();
				if (helpFileType === 'mp4') {
					playVideo();
				}
				Focus.set('help');
				Logger.log('open-popup', 'help', 'Help');
			});
		}
	}

	render() {
		const { state, actions } = this.props;
		const { activeBackgroundID, activeViewID, activeMediaID, activePopupID, timer, activeVolume, webMode, transcript } = state;
		const { setBackground, setView, setPopup, setCarousel, setTimer, resetTimer, setConnectionStatus } = actions;
		return (
			<div className="view" id="view" onScroll={() => handleScroll()}>
				<Background activeBackgroundID={activeBackgroundID} activeVolume={activeVolume} setBackground={setBackground} />
				<MenuView state={state} actions={actions} />
				<HomeView state={state} actions={actions} />
				<MapView state={state} actions={actions} />
				<FooterBar state={state} actions={actions} />
				<MediaView state={state} actions={actions} />
				<TableOfContents state={state} actions={actions} />
				<HelpPopup state={state} actions={actions} />
				<Popup
					activeViewID={activeViewID}
					activeMediaID={activeMediaID}
					activePopupID={activePopupID}
					transcript={transcript}
					setPopup={setPopup}
				/>
				<TimerPopup
					activeViewID={activeViewID}
					timer={timer}
					webMode={webMode}
					setView={setView}
					setPopup={setPopup}
					setCarousel={setCarousel}
					setTimer={setTimer}
					resetTimer={resetTimer}
					setConnectionStatus={setConnectionStatus}
				/>
				<DevTools state={state} actions={actions} />
			</div>
		);
	}
}

ViewManager.propTypes = {
	state: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

export default ViewManager;
