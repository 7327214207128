import React from 'react';
import PropTypes from 'prop-types';
import Video from '../media/videos/Video';
import PopupModel from '../../models/popupModel';
import DataModel from '../../models/dataModel';
import VideoHelper from '../../helpers/videoHelper';
import Logger from '../../helpers/logger';
import Focus from '../../helpers/focus';

function renderMedia(helpFile, helpAltText, activeCaptionSetting, resetTimer) {
	const fileType = DataModel.getFileType(helpFile);
	if (fileType === 'mp4') {
		return <Video mediaID="help" captionSetting={activeCaptionSetting} resetTimer={resetTimer} />;
	} else {
		return <img src={helpFile} alt={helpAltText} />;
	}
}

class HelpPopup extends React.PureComponent {
	render() {
		const { state, actions } = this.props;
		const { activeViewID, activePopupID, activeCaptionSetting, activeVolume } = state;
		const { stopVideo, resetTimer, setPopup, playSound } = actions;
		const helpFile = DataModel.getHelpFile();
		const helpAltText = DataModel.getHelpAltText();
		if (activePopupID === 'help') {
			return (
				<div id="help" tabIndex="-1" className="media-popup">
					<div className="popup-content popup-content-help">
						{renderMedia(helpFile, helpAltText, activeCaptionSetting, resetTimer)}
						<div className="footer-bar">
							<ul>
								<li>
									<button
										type="button"
										onClick={() => {
											setPopup('');
											stopVideo();
											Focus.set(activeViewID);
											Logger.log('close-popup');
											if (activeViewID === 'home' || activeViewID === 'menu') {
												const videoBackground = DataModel.getHomeAttribute('backgroundVideo', true);
												if (!videoBackground) {
													playSound('intro', activeVolume);
												}
												VideoHelper.fadeVolume(activeVolume);
											}
										}}>
										<i className="fas fa-times" />
										&nbsp;&nbsp;&nbsp;Close
									</button>
								</li>
								<li>
									<h1 className="page-title-breadcrumb">{PopupModel.getPopupTitle(activePopupID)}</h1>
								</li>
							</ul>
							<div />
						</div>
					</div>
				</div>
			);
		}
		return null;
	}
}

HelpPopup.propTypes = {
	state: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

export default HelpPopup;
