import _ from 'lodash';
import initialState from '../data/initialState';
import DataModel from '../models/dataModel';

function setDataReady(state, action) {
	return { ...state, dataReady: action.dataReady };
}

function setSettingsReady(state, action) {
	return { ...state, settingsReady: action.settingsReady };
}

function setError(state, action) {
	return { ...state, error: action.error, errorTitle: action.title, errorDescription: action.description };
}

function setBackground(state, action) {
	return { ...state, activeBackgroundID: action.backgroundID };
}

function setView(state, action) {
	const { viewID } = action;
	const newState = _.cloneDeep(state);
	newState.selector.previousGroupID = 0;
	newState.selector.activeGroupIDs = [1, 1, 1, 1, 1];
	if (viewID === 'menu' || viewID === 'home') {
		newState.activeMediaID = '';
	}
	newState.activeViewID = viewID;
	return newState;
}

function setMedia(state, action) {
	const newState = _.cloneDeep(state);
	newState.selector.previousGroupID = 0;
	newState.selector.lastArrowClickDirection = '';
	newState.gallery.previousImageID = 0;
	newState.gallery.activeImageID = 1;
	newState.activeMediaID = action.mediaID;
	return newState;
}

function setPopup(state, action) {
	return { ...state, activePopupID: action.popupID };
}

function setCarousel(state, action) {
	const newState = _.cloneDeep(state);
	newState.carousel = {
		activeGroupID: action.activeGroupID,
		previousGroupID: action.previousGroupID,
		lastArrowClickDirection: action.direction,
	};
	return newState;
}

function setSelector(state, action) {
	const newState = _.cloneDeep(state);
	newState.selector.activeGroupIDs[action.depth - 1] = action.activeGroupID;
	newState.selector.previousGroupID = action.previousGroupID;
	newState.selector.lastArrowClickDirection = action.direction;
	return newState;
}

function setGallery(state, action) {
	const newState = _.cloneDeep(state);
	if (action.activeImageID) {
		newState.gallery = {
			activeImageID: action.activeImageID,
			previousImageID: action.previousImageID,
			lastArrowClickDirection: action.direction,
			command: action.command,
		};
	} else {
		newState.gallery.command = action.command;
	}
	return newState;
}

function setFilter(state, action) {
	if (action.filterType === 'type') {
		return { ...state, activeTypeFilter: action.filterID };
	}
	if (action.filterType === 'topic') {
		return { ...state, activeTopicFilter: action.filterID };
	}
	return state;
}

function setCaptionSetting(state, action) {
	return { ...state, activeCaptionSetting: action.activeCaptionSetting };
}

function setWebMode(state, action) {
	return { ...state, webMode: action.webMode };
}

function setVolume(state, action) {
	return { ...state, activeVolume: action.volume };
}

function setTimer(state, action) {
	const newState = _.cloneDeep(state);
	newState.timer.remainingSeconds = action.remainingSeconds;
	if (action.totalSeconds) {
		newState.timer.totalSeconds = action.totalSeconds;
	}
	if (action.totalSecondsLong) {
		newState.timer.totalSecondsLong = action.totalSecondsLong;
	}
	return newState;
}

function resetTimer(state, action) {
	const newState = _.cloneDeep(state);
	const mediaType = DataModel.getMediaType(state.activeMediaID);
	const time = DataModel.getMediaTimeout(state.activeMediaID);
	if (time) {
		newState.timer.remainingSeconds = time;
	} else {
		if (mediaType === 'html' || mediaType === 'panorama') {
			newState.timer.remainingSeconds = state.timer.totalSecondsLong;
		} else {
			newState.timer.remainingSeconds = state.timer.totalSeconds;
		}
	}
	return newState;
}

function setMapState(state, action) {
	return {
		...state,
		mapState: { lat: action.lat, long: action.long, zoom: action.zoom, animating: action.animating, loaded: action.loaded },
	};
}

function stopMapAnimation(state, action) {
	const { lat, long, zoom, loaded } = state.mapState;
	return { ...state, mapState: { lat, long, zoom, animating: false, loaded } };
}

function setMapMarkers(state, action) {
	return { ...state, mapMarkers: action.mapMarkers };
}

function resetMapMarkers(state, action) {
	const { mapMarkers } = state;
	const newMapMarkers = mapMarkers.map((mapMarker) => {
		return { mediaID: mapMarker.mediaID, isActive: false };
	});
	return { ...state, mapMarkers: newMapMarkers };
}

function setMapMarker(state, action) {
	const { mediaID } = action;
	const { mapMarkers } = state;
	const index = mapMarkers.findIndex((marker) => marker.mediaID === mediaID);
	const newMapMarkers = mapMarkers;
	newMapMarkers[index].isActive = action.isActive;
	return { ...state, mapMarkers: newMapMarkers };
}

function setConnectionStatus(state, action) {
	return { ...state, connectionStatus: action.connectionStatus };
}

function logAction(state, action) {
	const newState = _.cloneDeep(state);
	if (action.action === 'start-session') {
		const date = new Date();
		newState.sessionStartDate = date;
	}
	newState.log.push({
		date: action.date,
		user: action.user,
		action: action.action,
		type: action.kind,
		title: action.title,
		duration: action.duration,
	});
	return newState;
}

function setSponsorSlot(state, action) {
	return { ...state, activeSponsorSlot: action.sponsorSlot };
}

function setTranscript(state, action) {
	return { ...state, transcript: action.transcript };
}

function reducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_DATA_READY':
			return setDataReady(state, action);
		case 'SET_SETTINGS_READY':
			return setSettingsReady(state, action);
		case 'SET_ERROR':
			return setError(state, action);
		case 'SET_BACKGROUND':
			return setBackground(state, action);
		case 'SET_VIEW':
			return setView(state, action);
		case 'SET_MEDIA':
			return setMedia(state, action);
		case 'SET_POPUP':
			return setPopup(state, action);
		case 'SET_CAROUSEL':
			return setCarousel(state, action);
		case 'SET_SELECTOR':
			return setSelector(state, action);
		case 'SET_GALLERY':
			return setGallery(state, action);
		case 'SET_FILTER':
			return setFilter(state, action);
		case 'TOGGLE_CAPTION_SETTING':
			return setCaptionSetting(state, action);
		case 'SET_WEB_MODE':
			return setWebMode(state, action);
		case 'SET_VOLUME':
			return setVolume(state, action);
		case 'SET_TIMER':
			return setTimer(state, action);
		case 'RESET_TIMER':
			return resetTimer(state, action);
		case 'SET_MAP_STATE':
			return setMapState(state, action);
		case 'STOP_MAP_ANIMATION':
			return stopMapAnimation(state, action);
		case 'SET_MAP_MARKERS':
			return setMapMarkers(state, action);
		case 'SET_MAP_MARKER':
			return setMapMarker(state, action);
		case 'RESET_MAP_MARKERS':
			return resetMapMarkers(state, action);
		case 'SET_CONNECTION_STATUS':
			return setConnectionStatus(state, action);
		case 'LOG_ACTION':
			return logAction(state, action);
		case 'SET_SPONSOR_SLOT':
			return setSponsorSlot(state, action);
		case 'SET_TRANSCRIPT':
			return setTranscript(state, action);
		case 'RESET_STATE':
			return initialState;
		default:
			return state;
	}
}

export default reducer;
