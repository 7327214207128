import React from 'react';
import PropTypes from 'prop-types';
import SelectorGroup from './SelectorGroup';

class Selector extends React.PureComponent {
	render() {
		const {
			activeMapID,
			selectorGroups,
			activeGroupID,
			previousGroupID,
			lastDirection,
			setMedia,
			stopMapAnimation,
			playVideo,
		} = this.props;
		const id = `map-menu-buttons-${activeMapID}`;
		const carouselMarkup = selectorGroups.map((group, index) => {
			const groupIndex = index + 1;
			let className = 'selector';
			if (groupIndex === selectorGroups[0]) {
				className = 'selector';
			} else if (groupIndex === activeGroupID) {
				className += ` enter-${lastDirection}`;
			} else if (groupIndex === previousGroupID) {
				className += ` exit-${lastDirection}`;
			} else {
				className += ' inactive';
			}
			const active = groupIndex === activeGroupID ? true : false;
			const selectorGroup = selectorGroups[activeGroupID - 1];
			if (selectorGroup) {
				return (
					<div key={groupIndex}>
						<SelectorGroup
							selectorGroup={selectorGroup}
							css={className}
							active={active}
							setMedia={setMedia}
							stopMapAnimation={stopMapAnimation}
							playVideo={playVideo}
						/>
					</div>
				);
			}
			return null;
		});
		return (
			<div className="groups" id={id} tabIndex="-1">
				{carouselMarkup}
			</div>
		);
	}
}

Selector.propTypes = {
	activeMapID: PropTypes.string.isRequired,
	selectorGroups: PropTypes.array.isRequired,
	activeGroupID: PropTypes.number.isRequired,
	previousGroupID: PropTypes.number.isRequired,
	lastDirection: PropTypes.string.isRequired,
	setMedia: PropTypes.func.isRequired,
	stopMapAnimation: PropTypes.func.isRequired,
	playVideo: PropTypes.func.isRequired,
};

export default Selector;
