import React from 'react';
import PropTypes from 'prop-types';
import Swipeable from 'react-swipeable';
import Selector from './Selector';
import DataModel from '../../../models/dataModel';
import Focus from '../../../helpers/focus';

function renderDots(selectorGroups, activeGroupID) {
	if (selectorGroups) {
		if (selectorGroups.length > 1) {
			if (activeGroupID === '') {
				activeGroupID = 1;
			}
			const dots = selectorGroups.map((child, index) => {
				const groupIndex = index + 1;
				let className = 'carousel-dot';
				if (groupIndex === activeGroupID) {
					className += ' active';
				}
				return <div className={className} key={child} />;
			});
			return <div className="carousel-dots">{dots}</div>;
		} else {
			return null;
		}
	} else {
		return null;
	}
}

function handleArrowClick(activeMapID, selectorGroups, activeGroupID, direction, setSelector, mapDepth) {
	const firstGroup = 1;
	const lastGroup = selectorGroups.length;
	if (direction === 'left' && activeGroupID === firstGroup) {
	} else if (direction === 'right' && activeGroupID === lastGroup) {
	} else if (direction === 'left') {
		setSelector(activeGroupID - 1, activeGroupID, direction, mapDepth);
	} else if (direction === 'right') {
		setSelector(activeGroupID + 1, activeGroupID, direction, mapDepth);
	}
	Focus.set(`map-menu-buttons-${activeMapID}`);
}

class MapMenu extends React.PureComponent {
	render() {
		const { activeMapID, selector, setMedia, setSelector, stopMapAnimation, playVideo } = this.props;
		const { previousGroupID, lastArrowClickDirection } = selector;
		const mapDepth = DataModel.getMediaDepth(activeMapID);
		const { activeGroupIDs } = selector;
		let activeGroupID = activeGroupIDs[mapDepth - 1];
		const selectorGroups = DataModel.getSelectorGroups(activeMapID);
		let menuStyle = 'map-menu';
		let groupLength = 0;
		if (selectorGroups) {
			groupLength = selectorGroups.length;
		}
		if (groupLength === 1) {
			menuStyle += ' single';
		}
		const firstGroup = 0;
		const lastGroup = groupLength;
		if (activeGroupID === firstGroup) {
			activeGroupID = 1;
		}
		let leftArrowClass = 'arrow-button arrow-button-left';
		let rightArrowClass = 'arrow-button arrow-button-right';
		if (activeGroupID === 1) {
			leftArrowClass += ' arrow-inactive';
		}
		if (activeGroupID === lastGroup) {
			rightArrowClass += ' arrow-inactive';
		}
		const leftArrowAriaHidden = activeGroupID === 1 ? true : false;
		const rightArrowAriaHidden = activeGroupID === lastGroup ? true : false;
		const leftArrowTabIndex = activeGroupID === 1 ? '-1' : '0';
		const rightArrowTabIndex = activeGroupID === lastGroup ? '-1' : '0';
		const leftArrowAriaLabel = activeGroupID === 1 ? '' : 'previous set';
		const rightArrowAriaLabel = activeGroupID === lastGroup ? '' : 'next set';
		return (
			<div className={menuStyle}>
				<div className="map-menu-carousel">
					<button
						aria-label={leftArrowAriaLabel}
						aria-hidden={leftArrowAriaHidden}
						tabIndex={leftArrowTabIndex}
						type="button"
						className={leftArrowClass}
						onClick={() => handleArrowClick(activeMapID, selectorGroups, activeGroupID, 'left', setSelector, mapDepth)}>
						<i className="fas fa-chevron-left" />
					</button>
					<Swipeable
						onSwipedLeft={() => handleArrowClick(activeMapID, selectorGroups, activeGroupID, 'right', setSelector, mapDepth)}
						onSwipedRight={() => handleArrowClick(activeMapID, selectorGroups, activeGroupID, 'left', setSelector, mapDepth)}>
						<Selector
							activeMapID={activeMapID}
							selectorGroups={selectorGroups}
							activeGroupID={activeGroupID}
							previousGroupID={previousGroupID}
							lastDirection={lastArrowClickDirection}
							setMedia={setMedia}
							stopMapAnimation={stopMapAnimation}
							playVideo={playVideo}
						/>
					</Swipeable>
					<button
						aria-label={rightArrowAriaLabel}
						aria-hidden={rightArrowAriaHidden}
						tabIndex={rightArrowTabIndex}
						type="button"
						className={rightArrowClass}
						onClick={() => handleArrowClick(activeMapID, selectorGroups, activeGroupID, 'right', setSelector, mapDepth)}>
						<i className="fas fa-chevron-right" />
					</button>
				</div>
				{renderDots(selectorGroups, activeGroupID)}
			</div>
		);
	}
}

MapMenu.propTypes = {
	activeMapID: PropTypes.string.isRequired,
	setMedia: PropTypes.func.isRequired,
	setSelector: PropTypes.func.isRequired,
	stopMapAnimation: PropTypes.func.isRequired,
	playVideo: PropTypes.func.isRequired,
};

export default MapMenu;
