class MapModel {
	static getMapPosition(mapState, totalMapDimensions, visibleMapDimensions) {
		const { lat, long } = mapState;
		const left = (totalMapDimensions.width / 2 - visibleMapDimensions.width / 2) * -1 - lat;
		const top = (totalMapDimensions.height / 2 - visibleMapDimensions.height / 2) * -1 - long;
		return { left, top };
	}

	static getTotalDimensions(data) {
		const xSize = parseInt(data.xLongitudeSize);
		const ySize = parseInt(data.yLatitudeSize);
		return { width: xSize * 1024, height: ySize * 1024 };
	}

	static getMinScale(total, visible) {
		const minWidthScale = visible.width / total.width;
		const minHeightScale = visible.height / total.height;
		if (minWidthScale >= minHeightScale) {
			return minWidthScale;
		}
		return minHeightScale;
	}

	static getScale(zoom, totalMapDimensions, visibleMapDimensions) {
		const minScale = MapModel.getMinScale(totalMapDimensions, visibleMapDimensions);
		const maxScale = minScale * 30;
		const scale = MapModel.mapRange(zoom, 1, 10, minScale, maxScale);
		return scale;
	}

	static getPosition(start, total, scale) {
		return (start - total / 2) / scale;
	}

	static getBoundaries(zoom, totalMapDimensions, visibleMapDimensions) {
		const scale = MapModel.getScale(zoom, totalMapDimensions, visibleMapDimensions);
		const latBoundary = (totalMapDimensions.width * scale - visibleMapDimensions.width) / scale / 2;
		const longBoundary = (totalMapDimensions.height * scale - visibleMapDimensions.height) / scale / 2;
		const boundaries = { maxLat: latBoundary, minLat: latBoundary * -1, maxLong: longBoundary, minLong: longBoundary * -1 };
		return boundaries;
	}

	static mapRange(value, a, b, x, y) {
		return ((value - a) * (y - x)) / (b - a) + x;
	}

	static getDistance(points) {
		const a = points.x1 - points.x2;
		const b = points.y1 - points.y2;
		return Math.sqrt(a * a + b * b);
	}

	static getCenterPoint(points) {
		const x = (points.x1 + points.x2) / 2;
		const y = (points.y1 + points.y2) / 2;
		return { x, y };
	}

	static getMapTileStyles(visibleMapDimensions, totalMapDimensions, mapState, minZoom, maxZoom) {
		const { lat, long, zoom } = mapState;
		let display = 'none';
		if (zoom >= minZoom && zoom <= maxZoom) {
			display = 'block';
		}
		const mapPosition = MapModel.getMapPosition(mapState, totalMapDimensions, visibleMapDimensions);
		const scale = MapModel.getScale(zoom, totalMapDimensions, visibleMapDimensions);
		const transformOrigin = `${totalMapDimensions.width / 2 + lat}px ${totalMapDimensions.height / 2 + long}px 0`;
		const styles = {
			display: display,
			width: totalMapDimensions.width,
			height: totalMapDimensions.height,
			transform: `translate3D(${mapPosition.left}px, ${mapPosition.top}px, 0) scale(${scale})`,
			transformOrigin,
		};
		return styles;
	}
}

export default MapModel;
