import React from 'react';
import PropTypes from 'prop-types';
import DataModel from '../../models/dataModel';
import format from 'date-fns/format';

function renderActionLog(log) {
	const logRows = log.map((action, index) => {
		return (
			<tr key={index}>
				<td>{format(action.date, 'MMMM D, YYYY')}</td>
				<td>{format(action.date, 'h:mm:ss a')}</td>
				<td>{action.action}</td>
				<td>{action.type}</td>
				<td>{action.title}</td>
				<td>{action.duration}</td>
			</tr>
		);
	});
	return (
		<div className="scrollable" id="scrollable">
			<table>
				<thead>
					<tr>
						<th className="th-date">Date</th>
						<th className="th-time">Time</th>
						<th className="th-action">Action</th>
						<th className="th-type">Type</th>
						<th className="th-title">Title</th>
						<th className="th-title">Duration</th>
					</tr>
				</thead>
				<tbody>{logRows}</tbody>
			</table>
		</div>
	);
}

function renderConnectionStatus(connectionStatus) {
	const online = connectionStatus === 'online' ? true : false;
	if (online) {
		return (
			<label>
				<i className="fas fa-wifi" />
				&nbsp;&nbsp;Internet Connected
			</label>
		);
	} else {
		return (
			<label>
				<i className="fas fa-ban" />
				&nbsp;&nbsp;No Internet Connection
			</label>
		);
	}
}

function renderToggleKioskModeButton() {
	if (window.ipcRenderer) {
		return (
			<div>
				<button
					className="simple"
					onClick={() => {
						window.ipcRenderer.send('toggle-kiosk-mode');
					}}>
					Toggle Kiosk Mode
				</button>
			</div>
		);
	}
	return null;
}

function renderTimeoutCounter(remainingSeconds, webMode) {
	if (!webMode) {
		return (
			<div>
				<h2>
					Timeout Counter
					<br />
					{remainingSeconds} seconds
				</h2>
				<br />
			</div>
		);
	}
	return null;
}

function round(value) {
	return Math.round(value * 1000) / 1000;
}

class DevTools extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = { actionLog: false, devTools: false, crosshairs: false };
	}

	renderCrosshairsButton(crosshairs, toggleCrosshairs) {
		const buttonText = crosshairs ? 'Hide Crosshairs' : 'Show Crosshairs';
		return (
			<button className="crosshairs" onClick={() => this.setState({ crosshairs: !this.state.crosshairs })}>
				{buttonText}
			</button>
		);
	}

	renderMapCoordinates(viewID, mapState, crosshairs, toggleCrosshairs, webMode) {
		if (viewID === 'map') {
			const { lat, long, zoom } = mapState;
			let mapCoordinates;
			if (webMode) {
				mapCoordinates = `lat="${round(lat)}" long="${round(long)}" zoom="${round(zoom)}"`;
			} else {
				mapCoordinates = `mapX="${round(lat)}" mapY="${round(long)}" zoom="${round(zoom)}"\r\rpinX="${round(lat)}" pinY="${round(long)}"`;
			}
			return (
				<div>
					<h2>Map Coordinates</h2>
					<textarea value={mapCoordinates} readOnly />
				</div>
			);
		}
		return <p>Open a Map to view Map coordinates.</p>;
	}

	render() {
		const { state } = this.props;
		const { activeViewID, mapState, log, timer, connectionStatus, webMode } = state;
		const devMode = DataModel.getSettingsAttribute('devMode');
		if (devMode) {
			const scrollable = document.getElementById('scrollable');
			if (scrollable) {
				scrollable.scrollTop = scrollable.scrollHeight + 1000;
			}
			const actionClass = `action-log action-log-${this.state.actionLog}`;
			const actionIconClass = this.state.actionLog ? 'fas fa-minus-square' : 'fas fa-plus-square';
			const devClass = `dev-tools dev-tools-${this.state.devTools}`;
			const devIconClass = this.state.devTools ? 'fas fa-minus-square' : 'fas fa-plus-square';
			return (
				<div>
					<div className={actionClass}>
						<button className="toggle" onClick={() => this.setState({ actionLog: !this.state.actionLog })}>
							<h1>Log Viewer</h1>
							<i className={actionIconClass} />
						</button>
						{renderActionLog(log)}
					</div>
					<div className={devClass}>
						<button className="toggle" onClick={() => this.setState({ devTools: !this.state.devTools })}>
							<h1>Dev Tools</h1>
							<i className={devIconClass} />
						</button>
						{this.renderMapCoordinates(activeViewID, mapState, this.state.crosshairs, this.toggleCrosshairs, webMode)}
						{renderTimeoutCounter(timer.remainingSeconds, webMode)}
						{renderConnectionStatus(connectionStatus)}
						{renderToggleKioskModeButton()}
						<p>
							MultimediaGallery
							<br />
							Version 2.0
						</p>
					</div>
				</div>
			);
		}
		return null;
	}
}

DevTools.propTypes = {
	state: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

export default DevTools;
