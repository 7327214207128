import React from 'react';
import PropTypes from 'prop-types';
import DataModel from '../../models/dataModel';
import VideoHelper from '../../helpers/videoHelper';
import Logger from '../../helpers/logger';
import Focus from '../../helpers/focus';

function handleCloseMedia(parent, depth, activeVolume, setMedia, playSound, resetTimer) {
	const hideMainMenu = DataModel.getSettingsAttribute('hideMainMenu', true);
	if (depth === 1) {
		const videoBackground = DataModel.getHomeAttribute('backgroundVideo', true);
		if (!videoBackground) {
			playSound('intro', activeVolume);
		}
		VideoHelper.fadeVolume(activeVolume);
		if (hideMainMenu) {
			Logger.log('end-session', 'close-button');
			Focus.set('home');
		} else {
			Logger.log('open-screen', 'main-menu', 'Main Menu');
			Focus.set('menu');
		}
	} else {
		Logger.log('open-media', 'map', DataModel.getMediaTitle(parent));
		Focus.set('map');
	}
	setMedia(parent);
	Focus.set('media');
	resetTimer();
}

class MediaViewFooter extends React.PureComponent {
	render() {
		const { activeMediaID, activeVolume, setMedia, playSound, resetTimer } = this.props;
		const parent = DataModel.getMediaParent(activeMediaID) ? DataModel.getMediaParent(activeMediaID) : '';
		const depth = DataModel.getMediaDepth(activeMediaID);
		return (
			<div className="footer-bar">
				<ul role="navigation">
					<li>
						<button type="button" onClick={() => handleCloseMedia(parent, depth, activeVolume, setMedia, playSound, resetTimer)}>
							<i className="fas fa-times" />
							&nbsp;&nbsp;&nbsp;Close
						</button>
					</li>
					<li>
						<h1 className="page-title-breadcrumb">{DataModel.getMediaTitle(activeMediaID)}</h1>
					</li>
				</ul>
				<div />
			</div>
		);
	}
}

MediaViewFooter.propTypes = {
	activeMediaID: PropTypes.string.isRequired,
	activeVolume: PropTypes.number.isRequired,
	setMedia: PropTypes.func.isRequired,
	playSound: PropTypes.func.isRequired,
	resetTimer: PropTypes.func.isRequired,
};

export default MediaViewFooter;
