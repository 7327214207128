import React from 'react';
import PropTypes from 'prop-types';
import Filters from './Filters';
import TableOfContentsButton from './TableOfContentsButton';
import DataModel from '../../models/dataModel';
import Logger from '../../helpers/logger';
import Focus from '../../helpers/focus';

class TableOfContents extends React.PureComponent {
	render() {
		const { state, actions } = this.props;
		const { activeViewID, activeMediaID, activePopupID, activeTypeFilter, activeTopicFilter } = state;
		const { setView, setMedia, setSelector, setPopup, setFilter, stopSound, playVideo, resetTimer } = actions;
		if (activePopupID === 'toc') {
			const buttonActions = { setView, setMedia, setSelector, setPopup, stopSound, playVideo, resetTimer };
			const mediaIDs = DataModel.getMediaChildrenExcludeHideFromIndex();
			const title = DataModel.getTocAttribute('title');
			const description = DataModel.getTocAttribute('description');
			const tableOfContents = mediaIDs.map((mediaID) => {
				const active = mediaID === activeMediaID;
				const tableOfContentsDepth2 = DataModel.getMediaChildrenExcludeHideFromIndex(mediaID).map((mediaID) => {
					const active = mediaID === activeMediaID;
					const tableOfContentsDepth3 = DataModel.getMediaChildrenExcludeHideFromIndex(mediaID).map((mediaID) => {
						const active = mediaID === activeMediaID;
						const tableOfContentsDepth4 = DataModel.getMediaChildrenExcludeHideFromIndex(mediaID).map((mediaID) => {
							const active = mediaID === activeMediaID;
							return (
								<div key={mediaID}>
									<TableOfContentsButton
										mediaID={mediaID}
										active={active}
										activeTypeFilter={activeTypeFilter}
										activeTopicFilter={activeTopicFilter}
										buttonActions={buttonActions}
									/>
								</div>
							);
						});
						return (
							<div key={mediaID}>
								<TableOfContentsButton
									mediaID={mediaID}
									active={active}
									activeTypeFilter={activeTypeFilter}
									activeTopicFilter={activeTopicFilter}
									buttonActions={buttonActions}
								/>
								{tableOfContentsDepth4}
							</div>
						);
					});
					return (
						<div key={mediaID}>
							<TableOfContentsButton
								mediaID={mediaID}
								active={active}
								activeTypeFilter={activeTypeFilter}
								activeTopicFilter={activeTopicFilter}
								buttonActions={buttonActions}
							/>
							{tableOfContentsDepth3}
						</div>
					);
				});
				return (
					<div key={mediaID}>
						<TableOfContentsButton
							mediaID={mediaID}
							active={active}
							activeTypeFilter={activeTypeFilter}
							activeTopicFilter={activeTopicFilter}
							buttonActions={buttonActions}
						/>
						{tableOfContentsDepth2}
					</div>
				);
			});
			return (
				<div id="toc" tabIndex="-1" className="popup">
					<div className="popup-container">
						<Filters activeTypeFilter={activeTypeFilter} activeTopicFilter={activeTopicFilter} setFilter={setFilter} />
						<div className="table-of-contents">
							<h1 className="toc-title">{title}</h1>
							<p className="toc-description">{description}</p>
							{tableOfContents}
						</div>
						<div className="footer-bar">
							<ul>
								<li>
									<button
										type="button"
										onClick={() => {
											setPopup('');
											Focus.set(activeViewID);
											Logger.log('close-popup');
										}}>
										<i className="fas fa-times" />
										&nbsp;&nbsp;&nbsp;Close
									</button>
								</li>
								<li>
									<h1 className="page-title-breadcrumb">Media Index</h1>
								</li>
							</ul>
							<div />
						</div>
					</div>
				</div>
			);
		}
		return null;
	}
}

TableOfContents.propTypes = {
	state: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

export default TableOfContents;
