import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import ErrorModel from './errorModel';

class DataModel {
	static getAllHomeData() {
		if (window.configurationData.home) {
			return window.configurationData.home;
		} else {
			ErrorModel.show('Unable to find home content in XML.', 'The <home> tag is required.');
		}
	}

	static getHomeAttribute(attribute, optional) {
		const home = DataModel.getAllHomeData();
		if (home[attribute]) {
			return home[attribute];
		} else {
			if (optional) {
				return false;
			} else {
				ErrorModel.missingAttribute('home', attribute);
			}
		}
	}

	static getBackgrounds() {
		const homeBackgrounds = window.configurationData.homeBackgrounds;
		if (homeBackgrounds) {
			const backgroundsArray = homeBackgrounds.map((backgroundObject) => {
				return { type: backgroundObject.name, file: backgroundObject.elements[0].text };
			});
			return backgroundsArray;
		} else {
			const backgroundsString = DataModel.getHomeAttribute('background', true);
			if (backgroundsString) {
				const backgrounds = backgroundsString
					.split(' ')
					.join('')
					.split(',');
				const backgroundsArray = backgrounds.map((backgroundFile) => {
					return { type: 'image', file: backgroundFile };
				});
				return backgroundsArray;
			} else {
				return false;
			}
		}
	}

	static getAllMenuData() {
		if (window.configurationData.menu) {
			return window.configurationData.menu;
		} else {
			ErrorModel.show('Unable to find menu content in XML.', 'The <menu> tag is required.');
		}
	}

	static getMenuAttribute(attribute, optional) {
		const menu = DataModel.getAllMenuData();
		if (menu[attribute]) {
			return menu[attribute];
		} else {
			if (optional) {
				return false;
			} else {
				ErrorModel.missingAttribute('menu', attribute);
			}
		}
	}

	static getCarouselGroups(activeSponsorSlot) {
		const rootDepthMediaInSponsorOrder = DataModel.getRootDepthMediaInSponsorOrder(activeSponsorSlot);
		const children = rootDepthMediaInSponsorOrder.map((media) => media.id);
		const numChildren = children.length;
		if (numChildren <= 5) {
			return [children];
		} else if (numChildren === 6) {
			return [children.slice(0, 3), children.slice(3)];
		} else if (numChildren <= 8) {
			return [children.slice(0, 4), children.slice(4)];
		} else if (numChildren <= 10) {
			return [children.slice(0, 5), children.slice(5)];
		} else if (numChildren <= 12) {
			return [children.slice(0, 4), children.slice(4, 8), children.slice(8)];
		} else if (numChildren <= 15) {
			return [children.slice(0, 5), children.slice(5, 10), children.slice(10)];
		} else {
			ErrorModel.show('Too many categories.', 'This program supports up to 15 categories.');
		}
	}

	static getLengthOfLargestCarouselGroup(groups) {
		const largestGroup = groups.reduce((p, c, i, a) => (a[p].length > c.length ? p : i), 0);
		return groups[largestGroup].length;
	}

	static getAllSettings() {
		if (window.configurationData.settings) {
			return window.configurationData.settings;
		} else {
			ErrorModel.show('Unable to find settings.', 'Not able to find any settings.');
		}
	}

	static getSettingsAttribute(attribute, optional) {
		const settings = DataModel.getAllSettings();
		let error = false;
		if (settings) {
			if (typeof settings[attribute] !== 'undefined') {
				return settings[attribute];
			} else {
				if (!optional) {
					error = true;
				}
			}
		} else {
			if (!optional) {
				error = true;
			}
		}
		if (error) {
			ErrorModel.missingAttribute('settings', attribute);
		} else {
			return null;
		}
	}

	static getAllTocData() {
		if (window.configurationData.toc) {
			return window.configurationData.toc;
		} else {
			ErrorModel.show('Unable to find toc content in XML.', 'The <toc> tag is required.');
		}
	}

	static getTocAttribute(attribute, optional) {
		const toc = DataModel.getAllTocData();
		if (toc[attribute]) {
			return toc[attribute];
		} else {
			if (optional) {
				return false;
			} else {
				ErrorModel.missingAttribute('toc', attribute);
			}
		}
	}

	static getAllMedia() {
		if (window.configurationData.media) {
			return window.configurationData.media;
		} else {
			ErrorModel.show('Unable to find media.', 'Not able to find any media.');
		}
	}

	static getAllMediaTypes() {
		const media = DataModel.getAllMedia();
		const types = media.map((media) => {
			return DataModel.getMediaTypeCustom(media.id);
		});
		return uniq(types);
	}

	static generateIDFromString(topic) {
		return topic.replace(/\W+/g, '-');
	}

	static getAllMediaTopics() {
		const media = DataModel.getAllMedia();
		const topics = media.map((media) => {
			return media.topics;
		});
		const allTopics = topics.filter((topic) => topic);
		const splitTopics = allTopics.map((string) => string.split(','));
		const flatTopics = flatten(splitTopics);
		const trimTopics = flatTopics.map((string) => string.trim());
		const uniqTopics = uniq(trimTopics);
		const sortTopics = uniqTopics.sort();
		const topicsData = sortTopics.map((topic) => {
			return {
				id: DataModel.generateIDFromString(topic),
				title: topic,
			};
		});
		return topicsData;
	}

	static doesMediaContainTopic(mediaID, topic) {
		const media = DataModel.getMedia(mediaID);
		const topics = media.topics;
		if (topics) {
			const splitTopics = topics.split(',');
			const trimTopics = splitTopics.map((string) => string.trim());
			const idTopics = trimTopics.map((string) => DataModel.generateIDFromString(string));
			return idTopics.includes(topic);
		}
		return false;
	}

	static getMedia(id) {
		const allMedia = DataModel.getAllMedia();
		return allMedia.find((media) => media.id === id);
	}

	static getFirstMedia() {
		const media = DataModel.getMediaChildren();
		return media[0];
	}

	static getMediaChildren(id) {
		if (id) {
			return DataModel.getMedia(id).children;
		} else {
			const rootDepthMedia = DataModel.getRootDepthMedia();
			return rootDepthMedia.map((media) => media.id);
		}
	}

	static getRootDepthMedia() {
		const allMedia = DataModel.getAllMedia();
		const rootDepthMedia = allMedia.filter((media) => media.depth === 1);
		return rootDepthMedia;
	}

	static getTotalSponsorSlots() {
		const rootDepthMedia = DataModel.getRootDepthMedia();
		const totalSponsors = rootDepthMedia.reduce(function(accumulator, media) {
			if (media.menuTurns) {
				return accumulator + media.menuTurns;
			}
			return accumulator;
		}, 0);
		return totalSponsors;
	}

	static getMediaWithSponsorSlots() {
		const rootDepthMedia = DataModel.getRootDepthMedia();
		const sponsorMedia = rootDepthMedia.filter((media) => media.menuTurns > 0);
		return sponsorMedia;
	}

	static getSponsorArray() {
		const sponsorMedia = DataModel.getMediaWithSponsorSlots();
		let index = 0;
		const sponsorArray = sponsorMedia.map(function(media) {
			index = index + media.menuTurns;
			media.sponsorIndex = index;
			return media;
		});
		return sponsorArray;
	}

	static getRootDepthMediaInSponsorOrder(currentSponsorSlot) {
		const unsortedMedia = DataModel.getRootDepthMedia();
		const sponsorArray = DataModel.getSponsorArray();
		const totalSponsorSlots = DataModel.getTotalSponsorSlots();
		const sortedMedia = [];
		let index = currentSponsorSlot;
		const theCurrentIndex = (media) => media.sponsorIndex === index;
		unsortedMedia.forEach(function(media) {
			let keepSearching = true;
			if (media.menuTurns > 0) {
				while (keepSearching) {
					const sponsorMedia = sponsorArray.find(theCurrentIndex);
					if (sponsorMedia) {
						sortedMedia.push(sponsorMedia);
						keepSearching = false;
					} else {
						keepSearching = true;
					}
					index++;
					if (index > totalSponsorSlots) {
						index = 1;
					}
				}
			} else {
				sortedMedia.push(media);
			}
		});
		return sortedMedia;
	}

	static getNextSponsorSlot(activeSponsorSlot) {
		const totalSponsorSlots = DataModel.getTotalSponsorSlots();
		if (activeSponsorSlot === totalSponsorSlots) {
			return 1;
		} else {
			return activeSponsorSlot + 1;
		}
	}

	static getMediaChildrenExcludeHideFromIndex(id) {
		const mediaChildren = DataModel.getMediaChildren(id);
		const mediaChildrenExcludeHideFromIndex = mediaChildren.filter((id) => DataModel.getMedia(id).hideFromIndex !== true);
		return mediaChildrenExcludeHideFromIndex;
	}

	static getSelectorGroups(mediaID) {
		const children = DataModel.getMediaChildren(mediaID);
		const numChildren = children.length;
		let selectorGroups = [];
		if (numChildren <= 3) {
			selectorGroups = [children];
		} else if (numChildren === 4) {
			selectorGroups = [children.slice(0, 2), children.slice(2)];
		} else if (numChildren <= 6) {
			selectorGroups = [children.slice(0, 3), children.slice(3)];
		} else if (numChildren <= 9) {
			selectorGroups = [children.slice(0, 3), children.slice(3, 6), children.slice(6)];
		} else if (numChildren <= 12) {
			selectorGroups = [children.slice(0, 3), children.slice(3, 6), children.slice(6, 9), children.slice(9)];
		} else if (numChildren <= 15) {
			selectorGroups = [children.slice(0, 3), children.slice(3, 6), children.slice(6, 9), children.slice(9, 12), children.slice(12)];
		} else if (numChildren <= 18) {
			selectorGroups = [
				children.slice(0, 3),
				children.slice(3, 6),
				children.slice(6, 9),
				children.slice(9, 12),
				children.slice(12, 15),
				children.slice(15),
			];
		} else {
			selectorGroups = [
				children.slice(0, 3),
				children.slice(3, 6),
				children.slice(6, 9),
				children.slice(9, 12),
				children.slice(12, 15),
				children.slice(15, 18),
			];
		}
		return selectorGroups;
	}

	static getMediaParent(mediaID) {
		return DataModel.getMedia(mediaID).parent;
	}

	static getMediaDepth(mediaID) {
		return DataModel.getMedia(mediaID).depth;
	}

	static getMediaType(mediaID) {
		const media = DataModel.getMedia(mediaID);
		if (media) {
			if (media.type) {
				return media.type;
			} else {
				const title = DataModel.getMediaTitle(mediaID);
				ErrorModel.missingType(title);
			}
		}
		return null;
	}

	static getMediaTypeCustom(mediaID) {
		const media = DataModel.getMedia(mediaID);
		if (media) {
			if (media.type) {
				return DataModel.getCustomType(mediaID);
			} else {
				const title = DataModel.getMediaTitle(mediaID);
				ErrorModel.missingType(title);
			}
		}
		return null;
	}

	static getCustomType(mediaID) {
		const media = DataModel.getMedia(mediaID);
		if (media.type === 'html' || media.type === 'map') {
			const customType = DataModel.getMediaAttribute(mediaID, 'mediaType', true);
			if (customType) {
				if (
					customType === 'video' ||
					customType === 'panorama' ||
					customType === 'gallery' ||
					customType === 'object' ||
					customType === 'video360' ||
					customType === 'html'
				) {
					return customType;
				} else {
					const title = DataModel.getMediaTitle(mediaID);
					ErrorModel.unrecognizableType(title);
				}
			} else {
				return media.type;
			}
		} else {
			return media.type;
		}
	}

	static getMediaTitle(mediaID) {
		const media = DataModel.getMedia(mediaID);
		if (media) {
			if (media.title) {
				return media.title.replace('[br]', '\n');
			} else {
				ErrorModel.missingTitle();
			}
		}
		return '';
	}

	static getMediaDescription(mediaID) {
		const media = DataModel.getMedia(mediaID);
		if (media.description) {
			return media.description;
		} else {
			ErrorModel.missingDescription();
		}
	}

	static getMediaThumbnail(mediaID) {
		const media = DataModel.getMedia(mediaID);
		if (media.thumb) {
			return `content/${media.thumb}`;
		} else {
			const title = DataModel.getMediaTitle(mediaID);
			ErrorModel.missingThumbnail(title);
		}
	}

	static getMediaSource(mediaID) {
		const media = DataModel.getMedia(mediaID);
		if (media.file) {
			return `content/${media.file}`;
		} else {
			const title = DataModel.getMediaTitle(mediaID);
			ErrorModel.missingSrc(title);
		}
	}

	static getMediaSubtitles(mediaID) {
		const media = DataModel.getMedia(mediaID);
		if (media.file) {
			let subtitle = `content/${media.file}`;
			subtitle = subtitle.slice(0, -4);
			subtitle = subtitle + '.vtt';
			return subtitle;
		} else {
			const title = DataModel.getMediaTitle(mediaID);
			ErrorModel.missingSrc(title);
		}
	}

	static getMediaURL(mediaID) {
		const media = DataModel.getMedia(mediaID);
		if (media.url) {
			return media.url;
		} else {
			const title = DataModel.getMediaTitle(mediaID);
			ErrorModel.missingURL(title);
		}
	}

	static getMediaPath(mediaID) {
		const media = DataModel.getMedia(mediaID);
		if (media) {
			return media.folder;
		} else {
			const title = DataModel.getMediaTitle(mediaID);
			ErrorModel.missingAttribute(title, 'folder');
		}
	}

	static getMediaAltText(mediaID) {
		const media = DataModel.getMedia(mediaID);
		if (media) {
			const rawData = media.raw;
			const filteredByElement = rawData.filter((data) => data.name === 'alt');
			const mediaElements = filteredByElement.map((data) => data.elements[0].text);
			return mediaElements;
		}
		return '';
	}

	static getMediaTranscript(mediaID) {
		const media = DataModel.getMedia(mediaID);
		if (media) {
			const rawData = media.raw;
			const filteredByElement = rawData.filter((data) => data.name === 'transcript');
			const pMedia = filteredByElement[0];
			if (pMedia) {
				const pElements = pMedia.elements;
				if (pElements) {
					const pTags = pElements.filter((data) => data.name === 'p');
					const mediaElements = pTags.map((data) => data.elements[0].text);
					return mediaElements;
				}
				return null;
			}
			return null;
		}
		return null;
	}

	static getMediaAttribute(mediaID, attribute, optional) {
		const media = DataModel.getMedia(mediaID);
		let error = false;
		if (media) {
			if (media[attribute] !== undefined) {
				return media[attribute];
			} else {
				if (!optional) {
					error = true;
				}
			}
		} else {
			if (!optional) {
				error = true;
			}
		}
		if (error) {
			const title = DataModel.getMediaTitle(mediaID);
			ErrorModel.missingAttribute(title, attribute);
		} else {
			return null;
		}
	}

	static getMediaFit(mediaID) {
		const fit = DataModel.getMediaAttribute(mediaID, 'fit', true);
		if (fit === 'fill') {
			return { objectFit: 'cover' };
		} else if (fit === 'stretch') {
			return { objectFit: 'fill' };
		} else
			return {
				objectFit: 'contain',
			};
	}

	static getObjectOffset(mediaID, attribute) {
		const offset = DataModel.getMediaAttribute(mediaID, attribute, true);
		if (offset) {
			return offset;
		}
		return 0;
	}

	static getMapJSON(mediaID) {
		const folder = DataModel.getMediaPath(mediaID);
		return `content/${folder}MapImageLayout.json`;
	}

	static getMapMaxZoom(mediaID) {
		const maxZoom = DataModel.getMediaAttribute(mediaID, 'maxZoom', true);
		if (maxZoom >= 1 && maxZoom <= 10) {
			return maxZoom;
		} else {
			return 10;
		}
	}

	static getInteractiveType(mediaID) {
		const url = DataModel.getMediaURL(mediaID);
		const pattern = /^((http|https|ftp):\/\/)/;
		if (pattern.test(url)) {
			return 'remote';
		} else {
			return 'local';
		}
	}

	static getMediaTimeout(mediaID) {
		const timeout = DataModel.getMediaAttribute(mediaID, 'timeout', true);
		if (timeout) {
			return timeout;
		}
		return null;
	}

	static getPageTitle(activeViewID, id) {
		if (activeViewID === 'menu' || activeViewID === 'home') {
			return 'Main Menu';
		}
		return DataModel.getMediaTitle(id);
	}

	static getParentPageTitle(activeViewID, id) {
		if (activeViewID === 'menu' || activeViewID === 'home') {
			return 'Main Menu';
		}
		if (id === '') {
			return 'Main Menu';
		}
		const parentID = DataModel.getMediaParent(id);
		return DataModel.getMediaTitle(parentID);
	}

	static getMapData(mapID) {
		if (mapID === 'default') {
			return {
				lat: 20,
				long: -150,
				zoom: 10,
				style: 'mapbox://styles/mapbox/satellite-streets-v9',
				accessToken: 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA',
			};
		} else {
			let zoom = DataModel.getMediaAttribute(mapID, 'zoom', true);
			if (!zoom) {
				zoom = 10;
			}
			let accessToken = DataModel.getMediaAttribute(mapID, 'accessToken', true);
			if (!accessToken) {
				accessToken = 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA';
			}
			let style = DataModel.getMediaAttribute(mapID, 'style', true);
			if (!style) {
				style = 'mapbox://styles/mapbox/satellite-streets-v9';
			}
			return {
				lat: DataModel.getMediaAttribute(mapID, 'lat'),
				long: DataModel.getMediaAttribute(mapID, 'long'),
				zoom,
				style,
				accessToken,
			};
		}
	}

	static getMapImage(mediaID) {
		const imagePath = DataModel.getMediaAttribute(mediaID, 'image', true);
		if (imagePath) {
			return `content/${imagePath}`;
		}
		return false;
	}

	static getLocalMapData(mapID) {
		if (mapID === 'default') {
			return {
				lat: 0,
				long: 0,
				zoom: 1,
				switchPoint: 3,
				maxZoom: 10,
			};
		} else {
			let x = DataModel.getMediaAttribute(mapID, 'mapX', true);
			if (!x) {
				x = 0;
			}
			let y = DataModel.getMediaAttribute(mapID, 'mapY', true);
			if (!y) {
				y = 0;
			}
			let switchPoint = DataModel.getMediaAttribute(mapID, 'switchPoint', true);
			if (!switchPoint) {
				switchPoint = 3;
			}
			if (switchPoint < 1) {
				switchPoint = 1;
			}
			if (switchPoint > 10) {
				switchPoint = 10;
			}
			let zoom = DataModel.getMediaAttribute(mapID, 'zoom', true);
			if (!zoom) {
				zoom = 1;
			}
			if (zoom < 1) {
				zoom = 1;
			}
			if (zoom > 10) {
				zoom = 10;
			}
			const maxZoom = DataModel.getMapMaxZoom(mapID);
			return {
				lat: x,
				long: y,
				zoom,
				switchPoint,
				maxZoom,
			};
		}
	}

	static getCredits() {
		if (window.configurationData.credits) {
			return window.configurationData.credits;
		} else {
			ErrorModel.show('Unable to find credits content in XML.', 'The <credits> tag is required.');
		}
	}

	static getCreditsLogo() {
		const creditsLogo = window.configurationData.creditsLogo;
		if (creditsLogo) {
			const logo = creditsLogo.logo;
			if (logo) {
				return `content/${logo}`;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	static getHelpFile() {
		if (window.configurationData.help) {
			const help = window.configurationData.help;
			if (help.file) {
				return `content/${help.file}`;
			} else {
				ErrorModel.show('Unable to find help video file.', 'The file attribute is required.');
			}
		} else {
			ErrorModel.show('Unable to find help content in XML.', 'The <help> tag is required.');
		}
	}

	static getFileType(file) {
		const fileType = file.split('.').pop();
		return fileType;
	}

	static getHelpFileType() {
		const helpFile = DataModel.getHelpFile();
		const helpFileType = DataModel.getFileType(helpFile);
		return helpFileType;
	}

	static getHelpSubtitles() {
		let subtitle = DataModel.getHelpFile();
		subtitle = subtitle.slice(0, -4);
		subtitle = subtitle + '.vtt';
		return subtitle;
	}

	static getHelpAltText() {
		if (window.configurationData.help) {
			const help = window.configurationData.help;
			if (help.alt) {
				return help.alt;
			}
			return '';
		}
		return '';
	}
}

export default DataModel;
