import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import SelectorButton from './SelectorButton';
import DataModel from '../../../models/dataModel';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA';
let mapMarkers = [];
let mapPopups = [];

function renderCrosshairs() {
	const devMode = DataModel.getSettingsAttribute('devMode');
	if (devMode) {
		return (
			<div>
				<div className="map-crosshairs map-crosshairs-horizontal" />
				<div className="map-crosshairs map-crosshairs-vertical" />
			</div>
		);
	}
	return null;
}

function removeMapMarkers() {
	mapMarkers.forEach((mapMarker) => mapMarker.remove());
	mapPopups.forEach((mapPopup) => mapPopup.remove());
}

function addMapMarkers(map, activeID, setMedia, playVideo) {
	const children = DataModel.getMediaChildren(activeID);
	children.forEach((mediaID, index) => {
		const thumbnail = DataModel.getMediaThumbnail(mediaID);
		const long = DataModel.getMediaAttribute(mediaID, 'long', true);
		const lat = DataModel.getMediaAttribute(mediaID, 'lat', true);
		if (long && lat) {
			mapMarkers[index] = document.createElement('img');
			mapMarkers[index].className = 'marker';
			mapMarkers[index].src = thumbnail;
			const popupElement = document.createElement('div');
			ReactDOM.render(<SelectorButton mediaID={mediaID} setMedia={setMedia} playVideo={playVideo} hidden={true} />, popupElement);
			const viewportWidth = document.documentElement.clientWidth;
			const offset = viewportWidth > 2000 ? 60 : 30;
			mapPopups[index] = new mapboxgl.Popup({ offset, closeButton: false }).setDOMContent(popupElement);
			new mapboxgl.Marker(mapMarkers[index])
				.setLngLat([long, lat])
				.setPopup(mapPopups[index])
				.addTo(map);
		}
	});
}

function handlePanMap(direction, map) {
	const panFactor = 80;
	if (direction === 'down') {
		map.panBy([0, panFactor], { duration: 110, easing: (x) => x });
	} else if (direction === 'up') {
		map.panBy([0, -panFactor], { duration: 110, easing: (x) => x });
	} else if (direction === 'right') {
		map.panBy([panFactor, 0], { duration: 110, easing: (x) => x });
	} else if (direction === 'left') {
		map.panBy([-panFactor, 0], { duration: 110, easing: (x) => x });
	}
}

function handleZoomMap(zoomType, map) {
	const zoomFactor = 0.2;
	const zoom = map.getZoom();
	let newZoom;
	if (zoomType === 'in') {
		newZoom = zoom + zoomFactor;
		map.zoomTo(newZoom, { duration: 110, easing: (x) => x });
	} else if (zoomType === 'out') {
		newZoom = zoom - zoomFactor;
		map.zoomTo(newZoom, { duration: 110, easing: (x) => x });
	}
}

function getMapboxObject(mapData, minZoom) {
	const mapboxObject = {
		container: 'mapbox',
		style: mapData.style,
		center: [mapData.long, mapData.lat],
		zoom: mapData.zoom,
		pitchWithRotate: false,
		scrollZoom: false,
		boxZoom: false,
		dragRotate: false,
		doubleClickZoom: false,
		keyboard: false,
		maxZoom: 18,
		minZoom: minZoom,
		attributionControl: false,
	};
	return mapboxObject;
}

class MapOnline extends React.PureComponent {
	componentDidUpdate() {
		const { activeMapID, setMedia, playVideo } = this.props;
		const image = DataModel.getMediaAttribute(activeMapID, 'image', true);
		if (!image) {
			const mapData = DataModel.getMapData(activeMapID);
			mapboxgl.accessToken = mapData.accessToken;
			const minZoom = mapData.zoom < 9 ? mapData.zoom : 9;
			this.map = new mapboxgl.Map(getMapboxObject(mapData, minZoom));

			const bounds = null;
			if (bounds) {
				this.map.setMaxBounds(bounds);
			}

			if (activeMapID !== 'default') {
				this.map.setCenter([mapData.long, mapData.lat]);
				this.map.setZoom(mapData.zoom - 0.1);
				this.map.zoomTo(mapData.zoom + 0.1, { duration: 20000 });
				removeMapMarkers();
				addMapMarkers(this.map, activeMapID, setMedia, playVideo);
			}
		}
	}

	componentDidMount() {
		const { activeMapID, setMapState, setMedia, playVideo } = this.props;
		const image = DataModel.getMediaAttribute(activeMapID, 'image', true);
		const devMode = DataModel.getSettingsAttribute('devMode');
		if (!image) {
			const mapData = DataModel.getMapData(activeMapID);
			mapboxgl.accessToken = mapData.accessToken;
			const minZoom = mapData.zoom < 9 ? mapData.zoom : 9;
			this.map = new mapboxgl.Map(getMapboxObject(mapData, minZoom));

			const bounds = null;
			if (bounds) {
				this.map.setMaxBounds(bounds);
			}
			if (activeMapID !== 'default') {
				this.map.setCenter([mapData.long, mapData.lat]);
				this.map.setZoom(mapData.zoom - 0.1);
				this.map.zoomTo(mapData.zoom + 0.1, { duration: 20000 });
				removeMapMarkers();
				addMapMarkers(this.map, activeMapID, setMedia, playVideo);
			}
		}

		this.command = '';

		this.interval = setInterval(() => {
			if (this.command === 'up') {
				handlePanMap('up', this.map);
			}
			if (this.command === 'down') {
				handlePanMap('down', this.map);
			}
			if (this.command === 'left') {
				handlePanMap('left', this.map);
			}
			if (this.command === 'right') {
				handlePanMap('right', this.map);
			}
			if (this.command === 'in') {
				handleZoomMap('in', this.map);
			}
			if (this.command === 'out') {
				handleZoomMap('out', this.map);
			}
		}, 110);

		if (devMode && !image) {
			const map = this.map;
			map.on('move', function() {
				const center = map.getCenter();
				setMapState(center.lat, center.lng, map.getZoom());
			});
			map.on('zoom', function() {
				const center = map.getCenter();
				setMapState(center.lat, center.lng, map.getZoom());
			});
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		const { activeMapID, setMedia, playVideo } = this.props;
		const hideZoomButtons = DataModel.getMediaAttribute(activeMapID, 'hideZoomButtons', true);
		const image = DataModel.getMapImage(activeMapID, 'image');
		const altText = DataModel.getMediaAltText(activeMapID);
		let alt = altText[0];
		if (!alt) {
			alt = `${DataModel.getMediaTitle(activeMapID)} map`;
		} else {
			alt = `${altText}`;
		}
		const buttonStyle = hideZoomButtons ? { display: 'none' } : {};
		if (!image) {
			const mapData = DataModel.getMapData(activeMapID);
			if (this.map) {
				if (activeMapID !== 'default') {
					this.map.setCenter([mapData.long, mapData.lat]);
					this.map.setZoom(mapData.zoom - 0.1);
					this.map.zoomTo(mapData.zoom + 0.1, { duration: 20000 });
					removeMapMarkers();
					addMapMarkers(this.map, activeMapID, setMedia, playVideo);
				}
			}
		} else {
			return (
				<div className="image-area">
					<img className="bg-image" src={image} alt={alt} />
				</div>
			);
		}
		return (
			<div className="map-area" id="map-area">
				<p className="screen-reader">{alt}</p>
				<div id="mapbox" aria-hidden="true" className="absolute top right left bottom" style={{ width: '100%', height: '100%' }} />
				{renderCrosshairs()}
				<div aria-hidden="true" className="control-bar control-bar-map" style={buttonStyle}>
					<div className="buttons buttons-clear">
						<button
							aria-label="pan left"
							onTouchStart={() => (this.command = 'left')}
							onMouseDown={() => (this.command = 'left')}
							onKeyDown={(e) => {
								if (e.keyCode === 13 || e.keyCode === 32) {
									this.command = 'left';
								}
							}}
							onMouseUp={() => (this.command = '')}
							onKeyUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}>
							<i className="fas fa-arrow-circle-left" />
						</button>
						<button
							aria-label="pan down"
							onTouchStart={() => (this.command = 'down')}
							onMouseDown={() => (this.command = 'down')}
							onKeyDown={(e) => {
								if (e.keyCode === 13 || e.keyCode === 32) {
									this.command = 'down';
								}
							}}
							onMouseUp={() => (this.command = '')}
							onKeyUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}>
							<i className="fas fa-arrow-circle-down" />
						</button>
						<button
							aria-label="pan up"
							onTouchStart={() => (this.command = 'up')}
							onMouseDown={() => (this.command = 'up')}
							onKeyDown={(e) => {
								if (e.keyCode === 13 || e.keyCode === 32) {
									this.command = 'up';
								}
							}}
							onMouseUp={() => (this.command = '')}
							onKeyUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}>
							<i className="fas fa-arrow-circle-up" />
						</button>
						<button
							aria-label="pan right"
							onTouchStart={() => (this.command = 'right')}
							onMouseDown={() => (this.command = 'right')}
							onKeyDown={(e) => {
								if (e.keyCode === 13 || e.keyCode === 32) {
									this.command = 'right';
								}
							}}
							onMouseUp={() => (this.command = '')}
							onKeyUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}>
							<i className="fas fa-arrow-circle-right" />
						</button>
					</div>
					<div className="buttons buttons-clear">
						<button
							aria-label="zoom out"
							onTouchStart={() => (this.command = 'out')}
							onMouseDown={() => (this.command = 'out')}
							onKeyDown={(e) => {
								if (e.keyCode === 13 || e.keyCode === 32) {
									this.command = 'out';
								}
							}}
							onMouseUp={() => (this.command = '')}
							onKeyUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}>
							<i className="fas fa-minus-circle" />
						</button>
						<button
							aria-label="zoom in"
							onTouchStart={() => (this.command = 'in')}
							onMouseDown={() => (this.command = 'in')}
							onKeyDown={(e) => {
								if (e.keyCode === 13 || e.keyCode === 32) {
									this.command = 'in';
								}
							}}
							onMouseUp={() => (this.command = '')}
							onKeyUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}>
							<i className="fas fa-plus-circle" />
						</button>
					</div>
				</div>
			</div>
		);
	}
}

MapOnline.propTypes = {
	activeMapID: PropTypes.string,
	setMedia: PropTypes.func.isRequired,
	playVideo: PropTypes.func.isRequired,
	setMapState: PropTypes.func.isRequired,
};

export default MapOnline;
