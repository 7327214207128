import React from 'react';
import PropTypes from 'prop-types';
import SelectorButton from './SelectorButton';

class SelectorGroup extends React.PureComponent {
	render() {
		const { selectorGroup, css, active, setMedia, stopMapAnimation, playVideo } = this.props;
		const buttonCount = selectorGroup.length;
		const groupClass = `${css} button-count-${buttonCount}`;
		const ariaHidden = active ? false : true;
		const groupsMarkup = selectorGroup.map((mediaID) => {
			return (
				<SelectorButton
					key={mediaID}
					mediaID={mediaID}
					hidden={ariaHidden}
					setMedia={setMedia}
					stopMapAnimation={stopMapAnimation}
					playVideo={playVideo}
				/>
			);
		});
		return (
			<div aria-hidden={ariaHidden} className={groupClass}>
				{groupsMarkup}
			</div>
		);
	}
}

SelectorGroup.propTypes = {
	selectorGroup: PropTypes.array.isRequired,
	css: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	setMedia: PropTypes.func.isRequired,
	stopMapAnimation: PropTypes.func.isRequired,
	playVideo: PropTypes.func.isRequired,
};

export default SelectorGroup;
