import React from 'react';
import PropTypes from 'prop-types';
import MediaIcon from '../../common/MediaIcon';
import DataModel from '../../../models/dataModel';
import Logger from '../../../helpers/logger';
import Focus from '../../../helpers/focus';

function handleButtonClick(mediaID, setMedia, stopMapAnimation, playVideo) {
	if (stopMapAnimation) {
		stopMapAnimation();
	}
	const type = DataModel.getMediaType(mediaID);
	if (type === 'video') {
		const parentID = DataModel.getMediaParent(mediaID);
		playVideo(() => setMedia(parentID));
	}
	setMedia(mediaID);
	if (type === 'map') {
		Focus.set('map');
	} else {
		Focus.set('media');
	}
}

class SelectorButton extends React.PureComponent {
	render() {
		const { mediaID, hidden, setMedia, stopMapAnimation, playVideo } = this.props;
		const mediaTypeCustom = DataModel.getMediaTypeCustom(mediaID);
		const tabIndex = hidden ? '-1' : '0';
		return (
			<button
				type="button"
				className="media-button"
				tabIndex={tabIndex}
				onClick={() => {
					handleButtonClick(mediaID, setMedia, stopMapAnimation, playVideo);
					Logger.log('open-media', DataModel.getMediaType(mediaID), DataModel.getMediaTitle(mediaID));
				}}>
				<div className="image-box">
					<img src={DataModel.getMediaThumbnail(mediaID)} alt="" />
					<MediaIcon mediaType={mediaTypeCustom} />
				</div>
				<div className="media-heading">
					<h2 className="media-title">
						{DataModel.getMediaTitle(mediaID)} <span className="screen-reader">{mediaTypeCustom}</span>
					</h2>
					<p className="media-description">{DataModel.getMediaDescription(mediaID)}</p>
				</div>
			</button>
		);
	}
}

SelectorButton.propTypes = {
	mediaID: PropTypes.string.isRequired,
	hidden: PropTypes.bool.isRequired,
	setMedia: PropTypes.func.isRequired,
	stopMapAnimation: PropTypes.func,
	playVideo: PropTypes.func.isRequired,
};

export default SelectorButton;
