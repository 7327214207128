import React from 'react';
import PropTypes from 'prop-types';
import DataModel from '../../models/dataModel';
import Logger from '../../helpers/logger';

function arraysEqual(arr1, arr2) {
	if (arr1.length !== arr2.length) return false;
	for (let i = arr1.length; i--; ) {
		if (arr1[i] !== arr2[i]) return false;
	}
	return true;
}

function handleCheckKeySequence(keySequence, pinArray, setPopup) {
	if (arraysEqual(keySequence, pinArray)) {
		if (window.ipcRenderer) {
			Logger.log('quit-kiosk');
			window.ipcRenderer.send('quit-kiosk');
		}
		setPopup('');
	} else {
		setPopup('invalid');
	}
}

function renderDots(keySequence) {
	if (keySequence.length > 0) {
		const dots = keySequence.map((key, index) => {
			return <div className="dot" key={index} />;
		});
		return <div className="password-dots">{dots}</div>;
	}
	return (
		<div className="password-dots">
			<div className="dot dot-hidden" />
		</div>
	);
}

class PasswordPopup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			keySequence: [],
		};
	}

	handleEnterKey(key) {
		let newSequence = this.state.keySequence;
		if (newSequence.length < 4) {
			newSequence.push(key);
		}
		this.setState({ keySequence: newSequence });
	}

	componentDidMount() {
		let pin = DataModel.getSettingsAttribute('pin', true);
		if (!pin) {
			pin = '1234';
		}
		this.pinArray = pin.toString(10).split('');
		if (this.pinArray.length < 4) {
			this.pinArray = ['1', '2', '3', '4'];
		}
	}

	render() {
		const { setPopup } = this.props;
		return (
			<div className="credits-popup">
				<h1>Enter PIN</h1>
				<div className="buttons-inline buttons-pin">
					<button className="button" onClick={() => this.handleEnterKey('1')}>
						1
					</button>
					<button className="button" onClick={() => this.handleEnterKey('2')}>
						2
					</button>
					<button className="button" onClick={() => this.handleEnterKey('3')}>
						3
					</button>
					<button className="button" onClick={() => this.handleEnterKey('4')}>
						4
					</button>
					<button className="button" onClick={() => this.handleEnterKey('5')}>
						5
					</button>
					<button className="button" onClick={() => this.handleEnterKey('6')}>
						6
					</button>
					<button className="button" onClick={() => this.handleEnterKey('7')}>
						7
					</button>
					<button className="button" onClick={() => this.handleEnterKey('8')}>
						8
					</button>
					<button className="button" onClick={() => this.handleEnterKey('9')}>
						9
					</button>
					<button className="button" onClick={() => this.handleEnterKey('0')}>
						0
					</button>
				</div>
				{renderDots(this.state.keySequence)}
				<div className="buttons-inline">
					<button
						className="button"
						onClick={() => {
							handleCheckKeySequence(this.state.keySequence, this.pinArray, setPopup);
						}}>
						Submit
					</button>
				</div>
			</div>
		);
	}
}

PasswordPopup.propTypes = {
	setPopup: PropTypes.func.isRequired,
};

export default PasswordPopup;
