import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import soundsMiddleware from 'redux-sounds';
import './styles/app.css';
import './fontawesome/css/all.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import reducer from './reducers/reducer';

const soundData = {
	intro: 'content/audio/intro.mp3',
};

const loadedSoundsMiddleware = soundsMiddleware(soundData);

const store = createStore(reducer, composeWithDevTools(applyMiddleware(loadedSoundsMiddleware)));

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

export default store;
