const initialState = {
	kioskMode: 'view-only',
	kioskMultiScreens: 1,
	dataReady: false,
	settingsReady: false,
	activeBackgroundID: 1,
	activeViewID: 'home',
	carousel: { activeGroupID: 1, previousGroupID: 0, lastArrowClickDirection: '' },
	selector: { activeGroupIDs: [1, 1, 1, 1, 1], previousGroupID: 0, lastArrowClickDirection: '' },
	gallery: { activeImageID: 1, previousImageID: 0, lastArrowClickDirection: '', command: '' },
	activeMediaID: '',
	activePopupID: 'welcome',
	activeTypeFilter: 'all',
	activeTopicFilter: 'all',
	error: false,
	errorTitle: '',
	errorDescription: '',
	activeCaptionSetting: false,
	activeVolume: 0.5,
	webMode: false,
	timer: { remainingSeconds: 90, totalSeconds: 90, totalSecondsLong: 900 },
	mapState: { lat: 0, long: 0, zoom: 1, animating: true, loaded: false },
	mapMarkers: [],
	connectionStatus: 'offline',
	log: [],
	sessionStartDate: null,
	activeSponsorSlot: 0,
	transcript: [],
};

export default initialState;
