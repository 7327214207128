import React from 'react';
import PropTypes from 'prop-types';
import DataModel from '../../models/dataModel';
import VideoHelper from '../../helpers/videoHelper';
import Focus from '../../helpers/focus';
import Logger from '../../helpers/logger';

function renderPageTitle(activeViewID, activeMediaID) {
	const mediaType = DataModel.getMediaType(activeMediaID);
	let pageTitle;
	if (mediaType === 'map') {
		pageTitle = DataModel.getPageTitle(activeViewID, activeMediaID);
	} else {
		pageTitle = DataModel.getParentPageTitle(activeViewID, activeMediaID);
	}
	return <h2 className="page-title-breadcrumb">{pageTitle}</h2>;
}

function renderCloseButton(tabIndex, setView, setCarousel) {
	const hideHomeScreen = DataModel.getSettingsAttribute('hideHomeScreen', true);
	if (hideHomeScreen) {
		return null;
	}
	return (
		<button
			type="button"
			tabIndex={tabIndex}
			onClick={() => {
				setView('home');
				Focus.set('home');
				setCarousel(1, 0, '');
				Logger.log('end-session', 'close-button');
			}}>
			<i className="fas fa-times" />
			&nbsp;&nbsp;&nbsp;Close
		</button>
	);
}

function handleMenuClick(activeVolume, setView, playSound, stopVideo) {
	setView('menu');
	Focus.set('menu');
	const videoBackground = DataModel.getHomeAttribute('backgroundVideo', true);
	if (!videoBackground) {
		playSound('intro', activeVolume);
	}
	VideoHelper.fadeVolume(activeVolume);
	stopVideo();
	Logger.log('open-screen', 'main-menu', 'Main Menu');
}

class Breadcrumbs extends React.PureComponent {
	render() {
		const {
			activeViewID,
			activeMediaID,
			activeVolume,
			enableBreadcrumbs,
			setView,
			setMedia,
			setCarousel,
			playSound,
			stopVideo,
		} = this.props;
		const tabIndex = enableBreadcrumbs ? '0' : '-1';
		if (activeViewID === 'map') {
			const depth = DataModel.getMediaDepth(activeMediaID);
			if (depth === 1) {
				return (
					<ul className="footer-bar-breadcrumbs" role="navigation">
						<li>
							<button
								type="button"
								tabIndex={tabIndex}
								onClick={() => {
									handleMenuClick(activeVolume, setView, playSound, stopVideo);
								}}>
								<i className="fas fa-home" />
								&nbsp;&nbsp;Main Menu
							</button>
						</li>
						<li>{renderPageTitle(activeViewID, activeMediaID)}</li>
					</ul>
				);
			}
			if (depth === 2) {
				const parent = DataModel.getMediaParent(activeMediaID);
				const parentTitle = DataModel.getMediaTitle(parent);
				return (
					<ul className="footer-bar-breadcrumbs" role="navigation">
						<li>
							<button
								type="button"
								tabIndex={tabIndex}
								className="breadcrumb breadcrumb-first"
								onClick={() => {
									handleMenuClick(activeVolume, setView, playSound, stopVideo);
								}}>
								<i className="fas fa-home" />
								&nbsp;&nbsp;Main Menu
							</button>
						</li>
						<li>
							<button
								type="button"
								tabIndex={tabIndex}
								className="breadcrumb breadcrumb-last"
								onClick={() => {
									setMedia(parent);
									stopVideo('video');
									Logger.log('open-media', 'map', parentTitle);
								}}>
								{parentTitle}
							</button>
						</li>
						<li>{renderPageTitle(activeViewID, activeMediaID)}</li>
					</ul>
				);
			}
			if (depth > 2) {
				const parent = DataModel.getMediaParent(activeMediaID);
				const parentTitle = DataModel.getMediaTitle(parent);
				const grandparent = DataModel.getMediaParent(parent);
				const grandparentTitle = DataModel.getMediaTitle(grandparent);
				return (
					<ul className="footer-bar-breadcrumbs" role="navigation">
						<li>
							<button
								type="button"
								tabIndex={tabIndex}
								className="breadcrumb breadcrumb-first"
								onClick={() => {
									handleMenuClick(activeVolume, setView, playSound, stopVideo);
								}}>
								<i className="fas fa-home" />
								&nbsp;&nbsp;Main Menu
							</button>
						</li>
						<li>
							<button
								type="button"
								tabIndex={tabIndex}
								className="breadcrumb breadcrumb-last"
								onClick={() => {
									setMedia(grandparent);
									stopVideo('video');
									Logger.log('open-media', 'map', grandparentTitle);
								}}>
								{grandparentTitle}
							</button>
						</li>
						<li>
							<button
								type="button"
								tabIndex={tabIndex}
								className="breadcrumb breadcrumb-last breadcrumb-far-last"
								onClick={() => {
									setMedia(parent);
									stopVideo('video');
									Logger.log('open-media', 'map', parentTitle);
								}}>
								{parentTitle}
							</button>
						</li>
						<li>{renderPageTitle(activeViewID, activeMediaID)}</li>
					</ul>
				);
			}
		}
		if (activeViewID === 'menu' || activeViewID === 'home') {
			return (
				<ul className="footer-bar-breadcrumbs" role="navigation">
					<li>{renderCloseButton(tabIndex, setView, setCarousel)}</li>
					<li>{renderPageTitle(activeViewID, activeMediaID)}</li>
				</ul>
			);
		}
		return null;
	}
}

Breadcrumbs.propTypes = {
	activeViewID: PropTypes.string.isRequired,
	activeMediaID: PropTypes.string.isRequired,
	activeVolume: PropTypes.number.isRequired,
	enableBreadcrumbs: PropTypes.bool.isRequired,
	setMedia: PropTypes.func.isRequired,
	setCarousel: PropTypes.func.isRequired,
	playSound: PropTypes.func.isRequired,
	stopVideo: PropTypes.func.isRequired,
};

export default Breadcrumbs;
