import React from 'react';
import PropTypes from 'prop-types';
import MapMarker from './MapMarker';
import DataModel from '../../../models/dataModel';
import MapModel from '../../../models/mapModel';

class MapMarkers extends React.PureComponent {
	render() {
		const {
			visibleMapDimensions,
			bigMapDimensions,
			mapState,
			mapMarkers,
			setMedia,
			stopMapAnimation,
			setMapMarker,
			resetMapMarkers,
			playVideo,
		} = this.props;
		const { loaded } = mapState;
		if (visibleMapDimensions && loaded) {
			const mapMarkerJSX = mapMarkers.map((mapMarker) => {
				const { lat, long, zoom } = mapState;
				const { mediaID, isActive } = mapMarker;
				const x = DataModel.getMediaAttribute(mediaID, 'pinX', true);
				const y = DataModel.getMediaAttribute(mediaID, 'pinY', true);
				if (x !== null && y !== null) {
					const scale = MapModel.getScale(zoom, bigMapDimensions, visibleMapDimensions);
					const xPos = visibleMapDimensions.width / 2 + x * scale - lat * scale;
					const yPos = visibleMapDimensions.height / 2 + y * scale - long * scale;
					let tip;
					const horizontalMargin = visibleMapDimensions.width / 6.5;
					const verticalMargin = visibleMapDimensions.height / 5.7;
					if (xPos < horizontalMargin) {
						tip = 'left';
					} else if (xPos > visibleMapDimensions.width - horizontalMargin) {
						tip = 'right';
					} else if (yPos > verticalMargin) {
						tip = 'bottom';
					} else {
						tip = 'top';
					}
					return (
						<MapMarker
							key={mediaID}
							mediaID={mediaID}
							isActive={isActive}
							xPos={xPos}
							yPos={yPos}
							tip={tip}
							setMedia={setMedia}
							stopMapAnimation={stopMapAnimation}
							setMapMarker={setMapMarker}
							resetMapMarkers={resetMapMarkers}
							playVideo={playVideo}
						/>
					);
				}
				return null;
			});
			return (
				<div aria-hidden="true" className="map-markers">
					{mapMarkerJSX}
				</div>
			);
		}
		return null;
	}
}

MapMarkers.propTypes = {
	visibleMapDimensions: PropTypes.object,
	bigMapDimensions: PropTypes.object.isRequired,
	mapState: PropTypes.object.isRequired,
	mapMarkers: PropTypes.array.isRequired,
	setMedia: PropTypes.func.isRequired,
	stopMapAnimation: PropTypes.func.isRequired,
	setMapMarker: PropTypes.func.isRequired,
	resetMapMarkers: PropTypes.func.isRequired,
	playVideo: PropTypes.func.isRequired,
};

export default MapMarkers;
