import React from 'react';
import PropTypes from 'prop-types';

class CarouselDots extends React.PureComponent {
	render() {
		const { activeGroupID, allGroups } = this.props;
		if (allGroups.length > 1) {
			const dots = allGroups.map((group, index) => {
				const className = activeGroupID === index + 1 ? 'carousel-dot active' : 'carousel-dot';
				return <div className={className} key={index} />;
			});
			return <div className="carousel-dots">{dots}</div>;
		}
		return null;
	}
}

CarouselDots.propTypes = {
	activeGroupID: PropTypes.number.isRequired,
	allGroups: PropTypes.array.isRequired,
};

export default CarouselDots;
