import React from 'react';
import PropTypes from 'prop-types';
import Swipeable from 'react-swipeable';
import GalleryImage from './GalleryImage';
import DataModel from '../../../models/dataModel';
import Focus from '../../../helpers/focus';

function renderDots(images, activeImageID) {
	const dots = images.map((image) => {
		let className = 'dot';
		if (image.id === activeImageID) {
			className += ' active';
		}
		return <div className={className} key={image.id} />;
	});
	return <div className="dots">{dots}</div>;
}

function renderImages(mediaID, images, gallery, command, setCommand) {
	const imageMarkup = images.map((image) => {
		let className;
		let imageActive = false;
		let imageCommand = '';
		if (image.id === gallery.activeImageID) {
			className = 'enter';
			imageActive = true;
			imageCommand = command;
		} else if (image.id === gallery.previousImageID) {
			className = 'exit';
		} else {
			className = 'inactive';
		}
		return (
			<div className={className} key={image.id}>
				<GalleryImage
					mediaID={mediaID}
					path={image.path}
					imageID={`image-${image.id}`}
					command={imageCommand}
					active={imageActive}
					altText={image.altText}
					setCommand={setCommand}
				/>
			</div>
		);
	});
	return imageMarkup;
}

class Gallery extends React.PureComponent {
	handleArrowClick(direction, images, activeImageID, setGallery) {
		if (activeImageID === 1 && direction === 'left') {
		} else if (activeImageID === images.length && direction === 'right') {
		} else if (direction === 'right') {
			setGallery(activeImageID + 1, activeImageID, 'right', 'reset');
			Focus.set(`image-${activeImageID + 1}`);
		} else if (direction === 'left') {
			setGallery(activeImageID - 1, activeImageID, 'left', 'reset');
			Focus.set(`image-${activeImageID - 1}`);
		}
	}

	render() {
		const { mediaID, gallery, setGallery } = this.props;
		const { activeImageID, command } = gallery;
		const path = DataModel.getMediaPath(mediaID);
		const imageCount = DataModel.getMediaAttribute(mediaID, 'imageCount');
		const altText = DataModel.getMediaAltText(mediaID, gallery);
		const emptyImageArray = Array(imageCount).fill('');
		const images = emptyImageArray.map((image, index) => {
			const imageNumber = index + 1;
			return {
				id: imageNumber,
				path: `content/${path}${imageNumber}.jpg`,
				altText: altText[index],
			};
		});
		const leftArrowClass = activeImageID === 1 ? 'inactive' : '';
		const rightArrowClass = activeImageID === images.length ? 'inactive' : '';
		const leftArrowAriaHidden = activeImageID === 1 ? true : false;
		const rightArrowAriaHidden = activeImageID === images.length ? true : false;
		const leftArrowTabIndex = activeImageID === 1 ? '-1' : '0';
		const rightArrowTabIndex = activeImageID === images.length ? '-1' : '0';
		const setCommand = function(command) {
			setGallery(null, null, null, command);
		};
		return (
			<div>
				<div className="media-area">
					<Swipeable
						onSwipedLeft={() => this.handleArrowClick('right', images, activeImageID, setGallery)}
						onSwipedRight={() => this.handleArrowClick('left', images, activeImageID, setGallery)}>
						<div className="gallery">
							<div className="side-controls side-controls-left">
								<button
									aria-label="previous image"
									aria-hidden={leftArrowAriaHidden}
									tabIndex={leftArrowTabIndex}
									className={leftArrowClass}
									onClick={() => this.handleArrowClick('left', images, activeImageID, setGallery)}>
									<i className="fas fa-chevron-left" />
								</button>
							</div>
							{renderImages(mediaID, images, gallery, command, setCommand)}
							<div className="side-controls side-controls-right">
								<button
									aria-label="next image"
									aria-hidden={rightArrowAriaHidden}
									tabIndex={rightArrowTabIndex}
									className={rightArrowClass}
									onClick={() => this.handleArrowClick('right', images, activeImageID, setGallery)}>
									<i className="fas fa-chevron-right" />
								</button>
							</div>
						</div>
					</Swipeable>
				</div>
				<div className="control-bar-outside">
					<div>{renderDots(images, activeImageID)}</div>
				</div>
			</div>
		);
	}
}

Gallery.propTypes = {
	mediaID: PropTypes.string.isRequired,
	gallery: PropTypes.object.isRequired,
	setGallery: PropTypes.func.isRequired,
};

export default Gallery;
