const popups = [
	{
		id: 'welcome',
		title: 'Configuration Settings',
		size: 'small',
		footer: false,
	},
	{
		id: 'credits',
		title: 'Credits',
		size: 'medium',
		footer: true,
	},
	{
		id: 'help',
		title: 'Help',
		size: 'medium',
		footer: true,
	},
	{
		id: 'quit',
		title: 'Quit',
		size: 'small',
		footer: false,
	},
	{
		id: 'transcript',
		title: 'Transcript',
		size: 'narrow',
		footer: true,
	},
	{
		id: 'password',
		title: 'Enter PIN',
		size: 'small',
		footer: false,
	},
	{
		id: 'invalid',
		title: 'Invalid PIN',
		size: 'small',
		footer: false,
	},
];

export default popups;
