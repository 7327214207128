import React from 'react';
import PropTypes from 'prop-types';
import { Spring } from 'react-spring';
import Breadcrumbs from './Breadcrumbs';
import DataModel from '../../models/dataModel';
import Logger from '../../helpers/logger';
import Focus from '../../helpers/focus';

function renderMediaIndex(tabIndex, hideMediaIndex, setPopup) {
	if (!hideMediaIndex) {
		return (
			<button
				type="button"
				tabIndex={tabIndex}
				className="button-long"
				onClick={() => {
					setPopup('toc');
					Focus.set('toc');
					Logger.log('open-popup', 'media-index', 'Media Index');
				}}>
				<i className="fas fa-book" />
				&nbsp;&nbsp; Media Index
			</button>
		);
	}
	return null;
}

class FooterBar extends React.PureComponent {
	render() {
		const { state, actions } = this.props;
		const { activeViewID, activeMediaID, activePopupID, activeVolume } = state;
		const { setView, setMedia, setPopup, setCarousel, playSound, stopSound, playVideo, stopVideo } = actions;
		const helpFileType = DataModel.getHelpFileType();
		const showFooterBar = activeViewID === 'menu' || activeViewID === 'map';
		const hideMediaIndex = DataModel.getSettingsAttribute('hideMediaIndex', true);
		let enableFooterBar = false;
		if (activeViewID === 'menu' || activeViewID === 'map') {
			if (DataModel.getMediaType(activeMediaID) === 'map' || DataModel.getMediaType(activeMediaID) === null) {
				if (activePopupID === '') {
					enableFooterBar = true;
				}
			}
		}
		const ariaHidden = enableFooterBar ? false : true;
		const tabIndex = enableFooterBar ? '0' : '-1';
		return (
			<Spring
				to={{
					slideUp: showFooterBar ? 0 : 85,
				}}>
				{({ slideUp }) => (
					<div aria-hidden={ariaHidden}>
						<div className="footer-bar" style={{ transform: `translate3D(0, ${slideUp}px, 0)` }}>
							<Breadcrumbs
								activeViewID={activeViewID}
								activeMediaID={activeMediaID}
								activeVolume={activeVolume}
								enableBreadcrumbs={enableFooterBar}
								setView={setView}
								setMedia={setMedia}
								setCarousel={setCarousel}
								playSound={playSound}
								stopVideo={stopVideo}
							/>
							<ul className="footer-bar-buttons" role="navigation">
								<li>{renderMediaIndex(tabIndex, hideMediaIndex, setPopup)}</li>
								<li>
									<button
										type="button"
										tabIndex={tabIndex}
										onClick={() => {
											setPopup('credits');
											Focus.set('credits');
											Logger.log('open-popup', 'credits', 'Credits');
										}}>
										Credits
									</button>
								</li>
								<li>
									<button
										type="button"
										tabIndex={tabIndex}
										onClick={() => {
											setPopup('help');
											stopSound('intro');
											stopVideo();
											if (helpFileType === 'mp4') {
												playVideo();
											}
											Focus.set('help');
											Logger.log('open-popup', 'help', 'Help');
										}}>
										Help&nbsp;&nbsp;
										<i className="fas fa-question-circle" />
									</button>
								</li>
							</ul>
						</div>
					</div>
				)}
			</Spring>
		);
	}
}

FooterBar.propTypes = {
	state: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

export default FooterBar;
