import React from 'react';
import PropTypes from 'prop-types';

class ArrowButton extends React.PureComponent {
	render() {
		const { groupID, totalGroups, direction, setCarousel, handleArrowClick } = this.props;
		let style = { opacity: 0 };
		if (totalGroups > 1) {
			style = {};
		}
		const buttonClassName = `arrow-button arrow-button-${direction}`;
		const iconClassName = `fas fa-chevron-${direction}`;
		const ariaLabel = direction === 'left' ? 'previous set' : 'next set';
		if (groupID === 1 && direction === 'left') {
			return (
				<button aria-hidden="true" tabIndex="-1" type="button" className="arrow-button arrow-button-left arrow-inactive" style={style}>
					<i className={iconClassName} />
				</button>
			);
		}
		if (groupID === totalGroups && direction === 'right') {
			return (
				<button aria-hidden="true" tabIndex="-1" type="button" className="arrow-button arrow-button-right arrow-inactive" style={style}>
					<i className={iconClassName} />
				</button>
			);
		}
		return (
			<button
				aria-label={ariaLabel}
				type="button"
				className={buttonClassName}
				onClick={() => handleArrowClick(groupID, totalGroups, direction, setCarousel)}
				style={style}>
				<i className={iconClassName} />
			</button>
		);
	}
}

ArrowButton.propTypes = {
	groupID: PropTypes.number.isRequired,
	totalGroups: PropTypes.number.isRequired,
	direction: PropTypes.string.isRequired,
	setCarousel: PropTypes.func.isRequired,
	handleArrowClick: PropTypes.func.isRequired,
};

export default ArrowButton;
