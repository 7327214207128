import React from 'react';
import PropTypes from 'prop-types';
import DataModel from '../../models/dataModel';

function getLineMarkup(line, index) {
	const text = line.elements[0].text;
	if (line.name === 'title') {
		return (
			<h2 className="title" key={index}>
				{text}
			</h2>
		);
	}
	if (line.name === 'heading') {
		return <h2 key={index}>{text}</h2>;
	}
	return <p key={index}>{text}</p>;
}

function renderCredits(side) {
	let credits = DataModel.getCredits();
	if (side === 'left') {
		const leftCredits = credits.find((item) => item.name === 'left');
		credits = leftCredits ? leftCredits.elements : [];
	} else if (side === 'right') {
		const rightCredits = credits.find((item) => item.name === 'right');
		credits = rightCredits ? rightCredits.elements : [];
	}
	const creditsMarkup = credits.map((line, index) => {
		const lineMarkup = getLineMarkup(line, index);
		return lineMarkup;
	});
	return creditsMarkup;
}

class CreditsPopup extends React.PureComponent {
	handleTouchStart(event) {
		const { setPopup } = this.props;
		const touches = event.touches;
		if (touches.length > 2) {
			this.timeout = setTimeout(() => {
				setPopup('quit');
			}, 3000);
		}
	}

	handleTouchEnd(event) {
		clearTimeout(this.timeout);
	}

	render() {
		const logo = DataModel.getCreditsLogo();
		return (
			<div id="credits" tabIndex="-1" className="credits-popup">
				<h1>Credits</h1>
				<div className="credits-logo">
					<img
						className="logo"
						src={logo}
						alt=""
						onTouchStart={(event) => {
							const savedEvent = event;
							savedEvent.preventDefault();
							savedEvent.stopPropagation();
							this.handleTouchStart(savedEvent);
						}}
						onTouchEnd={(event) => {
							const savedEvent = event;
							savedEvent.preventDefault();
							savedEvent.stopPropagation();
							this.handleTouchEnd(savedEvent);
						}}
					/>
				</div>
				<div className="credits-copy">
					{renderCredits()}
					<div className="credits-left">{renderCredits('left')}</div>
					<div className="credits-right">{renderCredits('right')}</div>
				</div>
			</div>
		);
	}
}

CreditsPopup.propTypes = {
	setPopup: PropTypes.func.isRequired,
};

export default CreditsPopup;
