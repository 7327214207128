import React from 'react';
import PropTypes from 'prop-types';
import { Spring } from 'react-spring';
import Carousel from './Carousel';
import DataModel from '../../models/dataModel';

function renderMenuLogo() {
	const logo = DataModel.getMenuAttribute('logo', true);
	if (logo) {
		const logoPath = `content/${logo}`;
		return <img className="logo" src={logoPath} alt="" />;
	}
	return null;
}

function renderOverlayImage() {
	const image = DataModel.getMenuAttribute('overlayImage', true);
	if (image) {
		const imagePath = `content/${image}`;
		return <img className="menu-overlay" src={imagePath} alt="" />;
	}
	return null;
}

function renderMenuView(
	activeViewID,
	activeMediaID,
	activePopupID,
	carousel,
	activeSponsorSlot,
	setView,
	setCarousel,
	setMedia,
	fadeSound,
	playVideo,
	resetTimer
) {
	const isViewHome = activeViewID === 'home';
	const carouselGroups = DataModel.getCarouselGroups(activeSponsorSlot);
	let title = DataModel.getMenuAttribute('title', true);
	if (title) {
		title = title.replace('[br]', '\n');
	}
	const description = DataModel.getMenuAttribute('description', true);
	let ariaHidden = false;
	if (activeViewID === 'home' || activeViewID === 'map' || activeMediaID !== '' || activePopupID !== '') {
		ariaHidden = true;
	}
	return (
		<Spring
			to={{
				fadeIn: isViewHome ? 0 : 1,
				slideDown: isViewHome ? -150 : 0,
				slideUp: isViewHome ? 150 : 0,
			}}>
			{(props) => (
				<div id="menu" tabIndex="-1" aria-hidden={ariaHidden}>
					<div style={{ opacity: props.fadeIn }}>
						<div className="backdrop" style={{ opacity: 0.65 }} />
					</div>
					<div
						className="menu-overlay-container"
						style={{
							transform: `translate3D(0, ${props.slideDown}px, 0)`,
							opacity: props.fadeIn,
						}}>
						{renderOverlayImage()}
					</div>
					<div
						className="menu-content"
						style={{
							transform: `translate3D(0, ${props.slideDown}px, 0)`,
							opacity: props.fadeIn,
						}}>
						<div className="text">
							<h1 className="heading">{title}</h1>
							<p className="description">{description}</p>
						</div>
						{renderMenuLogo()}
					</div>
					<div
						className="container"
						style={{
							transform: `translate3D(0, ${props.slideUp}px, 0)`,
							opacity: props.fadeIn,
						}}>
						<Carousel
							carousel={carousel}
							carouselGroups={carouselGroups}
							setView={setView}
							setCarousel={setCarousel}
							setMedia={setMedia}
							fadeSound={fadeSound}
							playVideo={playVideo}
							resetTimer={resetTimer}
						/>
					</div>
				</div>
			)}
		</Spring>
	);
}

class MenuView extends React.PureComponent {
	render() {
		const { state, actions } = this.props;
		const { activeViewID, activeMediaID, activePopupID, carousel, activeSponsorSlot } = state;
		const { setView, setCarousel, setMedia, fadeSound, playVideo, resetTimer } = actions;
		return (
			<div>
				{renderMenuView(
					activeViewID,
					activeMediaID,
					activePopupID,
					carousel,
					activeSponsorSlot,
					setView,
					setCarousel,
					setMedia,
					fadeSound,
					playVideo,
					resetTimer
				)}
			</div>
		);
	}
}

MenuView.propTypes = {
	state: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

export default MenuView;
