import React from 'react';
import PropTypes from 'prop-types';
import DataModel from '../../../models/dataModel';

class Panorama extends React.PureComponent {
	render() {
		const { mediaID } = this.props;
		const path = `content/${DataModel.getMediaPath(mediaID)}`;
		return (
			<div className="media-area" id="media-area">
				<div className="panorama">
					<iframe className="iframe" title="Panorama" src={path} />
				</div>
			</div>
		);
	}
}

Panorama.propTypes = {
	mediaID: PropTypes.string.isRequired,
};

export default Panorama;
