class VideoHelper {
	static setVolume(volume) {
		const video = document.getElementById('videoBackground');
		if (video) {
			video.volume = volume;
		}
	}

	static playVideo() {
		const video = document.getElementById('videoBackground');
		if (video) {
			video.play();
		}
	}

	static fadeVolume(volume) {
		const video = document.getElementById('videoBackground');
		if (video) {
			const changeFactor = Math.abs(video.volume - volume) / 30;
			clearInterval(Window.videoVolumeInterval);
			Window.videoVolumeInterval = setInterval(function() {
				let newVolume = video.volume;
				if (newVolume < volume) {
					newVolume = newVolume + changeFactor;
				} else if (newVolume > volume) {
					newVolume = newVolume - changeFactor;
				}
				if (newVolume > 1) {
					newVolume = 1;
				}
				if (newVolume < 0) {
					newVolume = 0;
				}
				if (Math.abs(newVolume - volume) <= changeFactor) {
					clearInterval(Window.videoVolumeInterval);
					newVolume = volume;
				}
				newVolume = Math.round(newVolume * 1000) / 1000;
				video.volume = newVolume;
			}, 100);
		}
	}
}

export default VideoHelper;
