import React from 'react';
import PropTypes from 'prop-types';
import MapModel from '../../../models/mapModel';

class MapTileBig extends React.PureComponent {
	constructor() {
		super();
		this.state = { folder: '' };
	}

	componentDidUpdate() {
		const { folder, mapState, setMapState } = this.props;
		if (this.state.folder !== folder) {
			const { lat, long, zoom, animating } = mapState;
			this.setState({ folder: folder });
			setMapState(lat, long, zoom, animating, false);
		}
	}

	render() {
		const { mapID, folder, configData, visibleMapDimensions, bigMapDimensions, mapState, minZoom, maxZoom, setMapState } = this.props;
		if (mapID !== 'default' && folder && configData) {
			const imagePath = `content/${folder}FullMapImage.jpg`;
			const { loaded } = mapState;
			let styles;
			if (loaded) {
				styles = MapModel.getMapTileStyles(visibleMapDimensions, bigMapDimensions, mapState, minZoom, maxZoom);
			} else {
				styles = { display: 'none' };
			}
			return (
				<div className="maptiles" style={styles}>
					<img
						aria-hidden="true"
						alt=""
						src={imagePath}
						onLoad={() => {
							this.setState({ folder: folder });
							const { lat, long, zoom, animating } = mapState;
							setMapState(lat, long, zoom, animating, true);
						}}
					/>
				</div>
			);
		}
		return null;
	}
}

MapTileBig.propTypes = {
	mapID: PropTypes.string,
	folder: PropTypes.string,
	configData: PropTypes.object,
	visibleMapDimensions: PropTypes.object,
	bigMapDimensions: PropTypes.object,
	mapState: PropTypes.object.isRequired,
	minZoom: PropTypes.number.isRequired,
	maxZoom: PropTypes.number.isRequired,
	setMapState: PropTypes.func.isRequired,
};

export default MapTileBig;
