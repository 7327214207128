import React from 'react';
import PropTypes from 'prop-types';
import ArrowButton from './ArrowButton';
import CarouselGroups from './CarouselGroups';
import CarouselDots from './CarouselDots';
import Swipeable from 'react-swipeable';
import Focus from '../../helpers/focus';

function handleArrowClick(groupID, totalGroups, direction, setCarousel) {
	let previousGroupID = groupID;
	let newGroupID = groupID;
	if (direction === 'right') {
		if (newGroupID === totalGroups) {
			// newGroupID = 1;
		} else {
			newGroupID = newGroupID + 1;
		}
	}
	if (direction === 'left') {
		if (newGroupID === 1) {
			// newGroupID = totalGroups;
		} else {
			newGroupID = newGroupID - 1;
		}
	}
	setCarousel(newGroupID, previousGroupID, direction);
	Focus.set('carousel-buttons');
}

class Carousel extends React.PureComponent {
	render() {
		const { carousel, carouselGroups, setView, setCarousel, setMedia, fadeSound, playVideo, resetTimer } = this.props;
		const { activeGroupID, previousGroupID, lastArrowClickDirection } = carousel;
		const buttonProps = { setView, setMedia, fadeSound, playVideo, resetTimer };
		const totalGroups = carouselGroups.length;
		let className = 'carousel';
		return (
			<div className={className}>
				<Swipeable
					onSwipedLeft={() => handleArrowClick(activeGroupID, totalGroups, 'right', setCarousel)}
					onSwipedRight={() => handleArrowClick(activeGroupID, totalGroups, 'left', setCarousel)}>
					<div id="carousel-buttons" tabIndex="-1" className="carousel-buttons">
						<ArrowButton
							direction="left"
							groupID={activeGroupID}
							totalGroups={totalGroups}
							setCarousel={setCarousel}
							handleArrowClick={handleArrowClick}
						/>
						<CarouselGroups
							carouselGroups={carouselGroups}
							activeGroupID={activeGroupID}
							previousGroupID={previousGroupID}
							lastDirection={lastArrowClickDirection}
							buttonProps={buttonProps}
						/>
						<ArrowButton
							direction="right"
							groupID={activeGroupID}
							totalGroups={totalGroups}
							setCarousel={setCarousel}
							handleArrowClick={handleArrowClick}
						/>
					</div>
					<CarouselDots activeGroupID={activeGroupID} allGroups={carouselGroups} />
				</Swipeable>
			</div>
		);
	}
}

Carousel.propTypes = {
	carousel: PropTypes.object.isRequired,
	carouselGroups: PropTypes.array.isRequired,
	setView: PropTypes.func.isRequired,
	setCarousel: PropTypes.func.isRequired,
	setMedia: PropTypes.func.isRequired,
	fadeSound: PropTypes.func.isRequired,
	playVideo: PropTypes.func.isRequired,
};

export default Carousel;
