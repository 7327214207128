import React from 'react';
import PropTypes from 'prop-types';
import Logger from '../../helpers/logger';

function restartKiosk(setView, setPopup, setCarousel, setError) {
	setError(false, '', '');
	setView('home');
	setPopup('');
	setCarousel(1, 0, '');
	Logger.log('end-session', 'error');
}

class ErrorScreen extends React.PureComponent {
	render() {
		const { title, description, setView, setPopup, setCarousel, setError } = this.props;
		return (
			<div className="error-screen">
				<div className="error-content">
					<h1>
						<i className="fas fa-exclamation-triangle" />
						&nbsp;&nbsp;Oops. Something went wrong.
					</h1>
					<h2>{title}</h2>
					<p>{description}</p>
					<button className="button" onClick={() => restartKiosk(setView, setPopup, setCarousel, setError)}>
						Restart
					</button>
				</div>
			</div>
		);
	}
}

ErrorScreen.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	setView: PropTypes.func.isRequired,
	setPopup: PropTypes.func.isRequired,
	setCarousel: PropTypes.func.isRequired,
	setError: PropTypes.func.isRequired,
};

export default ErrorScreen;
