import React from 'react';
import PropTypes from 'prop-types';
import DataModel from '../../../models/dataModel';
import { MTLModel } from 'react-3d-viewer';

function renderLoading(loading) {
	if (loading) {
		return (
			<div className="spinner-screen">
				<div className="spinner-content">
					<i className="fas fa-spinner fa-spin" />
				</div>
			</div>
		);
	}
	return null;
}

class Object3D extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			zoom: 0,
			xRotation: 0,
			yRotation: 0,
			loading: true,
		};
	}

	handleDragStart() {
		this.dragging = true;
		this.animating = false;
	}

	handleDragStop() {
		this.dragging = false;
	}

	handleDragMove() {
		if (this.dragging) {
			const panFactor = 0.03 / 4;
			let newX = this.dragStart.originalX - (this.dragStart.x - this.dragMove.x) * panFactor;
			let newY = this.dragStart.originalY - (this.dragStart.y - this.dragMove.y) * panFactor;
			this.setState({ xRotation: newX, yRotation: newY });
		}
	}

	handlePanObject(panType, panFactor) {
		const { xRotation, yRotation } = this.state;
		if (!panFactor) {
			panFactor = 0.03;
		}
		let newXRotation = xRotation;
		let newYRotation = yRotation;
		if (panType === 'left') {
			newXRotation = xRotation - panFactor;
		} else if (panType === 'right') {
			newXRotation = xRotation + panFactor;
		} else if (panType === 'up') {
			newYRotation = yRotation - panFactor;
		} else if (panType === 'down') {
			newYRotation = yRotation + panFactor;
		}
		this.setState({ xRotation: newXRotation, yRotation: newYRotation });
	}

	handleZoomObject(zoomType) {
		const { zoom } = this.state;
		const zoomFactor = 0.01;
		let newZoom = zoom;
		if (zoomType === 'in') {
			newZoom = zoom + zoomFactor;
		} else if (zoomType === 'out') {
			newZoom = zoom - zoomFactor;
		}
		this.setState({ zoom: newZoom });
	}

	componentWillMount() {
		const { mediaID } = this.props;
		const zoom = DataModel.getObjectOffset(mediaID, 'offsetZ');
		this.setState({ zoom });
		this.command = '';
		this.animating = true;
		this.interval = setInterval(() => {
			if (this.command === 'up') {
				this.animating = false;
				this.handlePanObject('up');
			}
			if (this.command === 'down') {
				this.animating = false;
				this.handlePanObject('down');
			}
			if (this.command === 'left') {
				this.animating = false;
				this.handlePanObject('left');
			}
			if (this.command === 'right') {
				this.animating = false;
				this.handlePanObject('right');
			}
			if (this.command === 'in') {
				this.handleZoomObject('in');
			}
			if (this.command === 'out') {
				this.handleZoomObject('out');
			}
			if (this.animating) {
				this.handlePanObject('left', 0.008);
			}
		}, 15);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	renderControlBar() {
		if (!this.state.loading) {
			return (
				<div className="control-bar">
					<div className="buttons buttons-clear">
						<button
							onTouchStart={() => (this.command = 'left')}
							onMouseDown={() => (this.command = 'left')}
							onMouseUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}>
							<i className="fas fa-arrow-circle-left" />
						</button>
						<button
							onTouchStart={() => (this.command = 'down')}
							onMouseDown={() => (this.command = 'down')}
							onMouseUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}>
							<i className="fas fa-arrow-circle-down" />
						</button>
						<button
							onTouchStart={() => (this.command = 'up')}
							onMouseDown={() => (this.command = 'up')}
							onMouseUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}>
							<i className="fas fa-arrow-circle-up" />
						</button>
						<button
							onTouchStart={() => (this.command = 'right')}
							onMouseDown={() => (this.command = 'right')}
							onMouseUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}>
							<i className="fas fa-arrow-circle-right" />
						</button>
					</div>
					<div className="buttons buttons-clear">
						<button
							onTouchStart={() => (this.command = 'out')}
							onMouseDown={() => (this.command = 'out')}
							onMouseUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}>
							<i className="fas fa-minus-circle" />
						</button>
						<button
							onTouchStart={() => (this.command = 'in')}
							onMouseDown={() => (this.command = 'in')}
							onMouseUp={() => (this.command = '')}
							onTouchEnd={() => (this.command = '')}>
							<i className="fas fa-plus-circle" />
						</button>
					</div>
				</div>
			);
		}
		return null;
	}

	render() {
		const { mediaID, width, height } = this.props;
		const file = DataModel.getMediaAttribute(mediaID, 'file');
		const xPos = DataModel.getObjectOffset(mediaID, 'offsetX');
		const yPos = DataModel.getObjectOffset(mediaID, 'offsetY');
		const path = `content/${DataModel.getMediaPath(mediaID)}`;
		const obj = `${path}${file}.obj`;
		const mtl = `${path}${file}.mtl`;
		return (
			<div className="media-area" id="media-area">
				{renderLoading(this.state.loading)}
				<div
					className="object"
					onMouseDown={(event) => {
						event.preventDefault();
						event.stopPropagation();
						this.dragStart = {
							x: event.pageX,
							y: event.pageY,
							originalX: this.state.xRotation,
							originalY: this.state.yRotation,
						};
						this.handleDragStart();
					}}
					onTouchStart={(event) => {
						event.persist();
						event.preventDefault();
						event.stopPropagation();
						this.dragStart = {
							x: event.nativeEvent.touches[0].pageX,
							y: event.nativeEvent.touches[0].pageY,
							originalX: this.state.xRotation,
							originalY: this.state.yRotation,
						};
						this.handleDragStart();
					}}
					onMouseMove={(event) => {
						event.preventDefault();
						event.stopPropagation();
						this.dragMove = { x: event.pageX, y: event.pageY };
						this.handleDragMove();
					}}
					onTouchMove={(event) => {
						event.persist();
						event.preventDefault();
						event.stopPropagation();
						this.dragMove = { x: event.nativeEvent.touches[0].pageX, y: event.nativeEvent.touches[0].pageY };
						this.handleDragMove();
					}}
					onMouseUp={() => {
						this.handleDragStop();
					}}
					onTouchEnd={() => {
						this.handleDragStop();
					}}>
					<MTLModel
						position={{ x: xPos, y: yPos, z: this.state.zoom }}
						rotation={{ x: this.state.yRotation, y: this.state.xRotation, z: 0 }}
						src={obj}
						mtl={mtl}
						texPath={path}
						width={width}
						height={height}
						background="rgb(0,0,0)"
						anitialias={false}
						enableKeys={false}
						enableZoom={false}
						enableRotate={false}
						onLoad={() => this.setState({ loading: false })}
					/>
				</div>
				{this.renderControlBar()}
			</div>
		);
	}
}

Object3D.propTypes = {
	mediaID: PropTypes.string.isRequired,
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
};

export default Object3D;
