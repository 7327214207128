import React from 'react';
import PropTypes from 'prop-types';
import Video from './videos/Video';
import Gallery from './galleries/Gallery';
import Panorama from './panoramas/Panorama';
import Object3D from './objects/Object3D';
import HTML from './html/HTML';
import MediaViewFooter from './MediaViewFooter';
import DataModel from '../../models/dataModel';

function renderMediaPopup(
	mediaID,
	gallery,
	activeVolume,
	activeCaptionSetting,
	dimensions,
	connectionStatus,
	setMedia,
	playSound,
	setGallery,
	resetTimer,
	setPopup,
	setTranscript
) {
	const type = DataModel.getMediaType(mediaID);
	if (type === 'video') {
		return (
			<Video
				mediaID={mediaID}
				captionSetting={activeCaptionSetting}
				resetTimer={resetTimer}
				setPopup={setPopup}
				setTranscript={setTranscript}
			/>
		);
	} else if (type === 'html') {
		return (
			<HTML
				mediaID={mediaID}
				activeVolume={activeVolume}
				connectionStatus={connectionStatus}
				setMedia={setMedia}
				playSound={playSound}
				resetTimer={resetTimer}
				setPopup={setPopup}
				setTranscript={setTranscript}
			/>
		);
	} else if (type === 'gallery') {
		return <Gallery mediaID={mediaID} gallery={gallery} setGallery={setGallery} />;
	} else if (type === 'panorama') {
		return <Panorama mediaID={mediaID} />;
	} else if (type === 'object') {
		if (dimensions.width && dimensions.height) {
			return <Object3D mediaID={mediaID} width={dimensions.width} height={dimensions.height} />;
		} else {
			return null;
		}
	}
}

class MediaView extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = { width: 0, height: 0 };
	}

	componentDidUpdate() {
		const { width, height } = this.state;
		const mediaContent = document.getElementById('media-content');
		if (mediaContent) {
			const newWidth = document.getElementById('media-content').offsetWidth;
			const newHeight = document.getElementById('media-content').offsetHeight;
			if (width !== newWidth) {
				this.setState({ width: newWidth });
			}
			if (height !== newHeight) {
				this.setState({ height: newHeight });
			}
		}
	}

	render() {
		const { state, actions } = this.props;
		const { activeViewID, activeMediaID, activePopupID, gallery, activeCaptionSetting, activeVolume, connectionStatus } = state;
		const { setMedia, setGallery, playSound, resetTimer, setPopup, setTranscript } = actions;
		const { width, height } = this.state;
		const dimensions = { width, height };
		const type = DataModel.getMediaType(activeMediaID);
		const ariaHidden = activePopupID !== '' ? true : false;
		if ((activeViewID === 'map' && type !== 'map') || (activeViewID !== 'map' && activeMediaID)) {
			return (
				<div id="media" tabIndex="-1" aria-hidden={ariaHidden} className="media-popup">
					<div className="popup-content" id="media-content">
						{renderMediaPopup(
							activeMediaID,
							gallery,
							activeVolume,
							activeCaptionSetting,
							dimensions,
							connectionStatus,
							setMedia,
							playSound,
							setGallery,
							resetTimer,
							setPopup,
							setTranscript
						)}
						<MediaViewFooter
							activeMediaID={activeMediaID}
							activeVolume={activeVolume}
							setMedia={setMedia}
							playSound={playSound}
							resetTimer={resetTimer}
						/>
					</div>
				</div>
			);
		}
		return null;
	}
}

MediaView.propTypes = {
	state: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

export default MediaView;
