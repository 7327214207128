import React from 'react';
import PropTypes from 'prop-types';
import MediaType from '../common/MediaType';
import DataModel from '../../models/dataModel';
import VideoHelper from '../../helpers/videoHelper';
import Logger from '../../helpers/logger';

function isButtonVisible(mediaID, activeTypeFilter, activeTopicFilter) {
	const mediaType = DataModel.getMediaTypeCustom(mediaID);
	if (activeTypeFilter === 'all' && activeTopicFilter === 'all') {
		return true;
	}
	if (activeTypeFilter === mediaType && activeTopicFilter === 'all') {
		return true;
	}
	if (activeTypeFilter === 'all' && DataModel.doesMediaContainTopic(mediaID, activeTopicFilter)) {
		return true;
	}
	if (activeTypeFilter === mediaType && DataModel.doesMediaContainTopic(mediaID, activeTopicFilter)) {
		return true;
	}
	return false;
}

function handleButtonClick(mediaID, buttonActions) {
	const { setView, setMedia, setPopup, stopSound, playVideo, resetTimer } = buttonActions;
	const mediaDepth = DataModel.getMediaDepth(mediaID);
	const mediaType = DataModel.getMediaType(mediaID);
	if (mediaDepth === 1 && mediaType !== 'map') {
		setView('menu');
	} else {
		setView('map');
	}
	setMedia(mediaID);
	setPopup('');
	stopSound('intro');
	VideoHelper.fadeVolume(0);
	if (DataModel.getMediaType(mediaID) === 'video') {
		const parentID = DataModel.getMediaParent(mediaID);
		playVideo(() => setMedia(parentID));
	}
	resetTimer();
	Logger.log('open-media', mediaType, DataModel.getMediaTitle(mediaID));
}

class TableOfContentsButton extends React.PureComponent {
	render() {
		const { mediaID, active, activeTypeFilter, activeTopicFilter, buttonActions } = this.props;
		let mediaDepth;
		if (activeTypeFilter === 'all' && activeTopicFilter === 'all') {
			mediaDepth = DataModel.getMediaDepth(mediaID);
		} else {
			mediaDepth = 1;
		}
		const buttonVisible = isButtonVisible(mediaID, activeTypeFilter, activeTopicFilter);
		const height = buttonVisible ? 6.8 : 0;
		const opacity = buttonVisible ? 1 : 0;
		let buttonClass = `media-button media-button-depth-${mediaDepth}`;
		if (active) {
			buttonClass += ' media-button-active';
		}
		if (buttonVisible) {
			return (
				<div className="toc-button-container" style={{ height: `${height}vw`, opacity: opacity, overflow: 'hidden' }}>
					<button type="button" className={buttonClass} onClick={() => handleButtonClick(mediaID, buttonActions)}>
						<div className="image-box">
							<img src={DataModel.getMediaThumbnail(mediaID)} alt="" />
						</div>
						<div className="media-heading">
							<h2 className="media-title">{DataModel.getMediaTitle(mediaID)}</h2>
							<p className="media-description">{DataModel.getMediaDescription(mediaID)}</p>
						</div>
						<p className="media-type">
							<MediaType mediaType={DataModel.getMediaTypeCustom(mediaID)} />
						</p>
					</button>
				</div>
			);
		}
		return null;
	}
}

TableOfContentsButton.propTypes = {
	mediaID: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	activeTypeFilter: PropTypes.string.isRequired,
	activeTopicFilter: PropTypes.string.isRequired,
	buttonActions: PropTypes.object.isRequired,
};

export default TableOfContentsButton;
