import React from 'react';
import PropTypes from 'prop-types';
import { Spring } from 'react-spring';
import DataModel from '../../models/dataModel';
import VideoHelper from '../../helpers/videoHelper';
import Logger from '../../helpers/logger';
import Focus from '../../helpers/focus';

function handleClick(activeSponsorSlot, setView, setMedia, playVideo, fadeSound, resetTimer, setSponsorSlot) {
	const hideMainMenu = DataModel.getSettingsAttribute('hideMainMenu', true);
	const firstMedia = DataModel.getFirstMedia();
	const type = DataModel.getMediaType(firstMedia);
	if (hideMainMenu) {
		if (type === 'map') {
			setView('map');
		}
		setMedia(firstMedia);
		fadeSound('intro');
		VideoHelper.fadeVolume(0);
		if (type === 'video') {
			playVideo(() => setMedia(''));
		}
		resetTimer();
		Logger.log('start-session', type, DataModel.getMediaTitle(firstMedia));
	} else {
		setView('menu');
		Focus.set('menu');
		Logger.log('start-session', 'main-menu', 'Main Menu');
	}
	setSponsorSlot(DataModel.getNextSponsorSlot(activeSponsorSlot));
}

function renderOverlayImage() {
	const image = DataModel.getHomeAttribute('overlayImage', true);
	if (image) {
		const imagePath = `content/${image}`;
		return <img className="home-overlay" src={imagePath} alt="" />;
	}
	return null;
}

function renderHomeView(activeViewID, activeSponsorSlot, setView, setMedia, playVideo, fadeSound, resetTimer, setSponsorSlot) {
	const isViewHome = activeViewID === 'home';
	let title = DataModel.getHomeAttribute('title', true);
	if (title) {
		title = title.replace('[br]', '\n');
	}
	const prompt = DataModel.getHomeAttribute('prompt', true);
	const alt = DataModel.getHomeAttribute('alt', true);
	const ariaHidden = isViewHome ? false : true;
	const tabIndex = isViewHome ? '0' : '-1';
	return (
		<Spring to={{ opacity: isViewHome ? 1 : 0, height: isViewHome ? 100 : 1 }}>
			{({ opacity, height }) => (
				<div id="home" tabIndex="-1" style={{ opacity }} aria-hidden={ariaHidden}>
					<h1 className="home-title">{title}</h1>
					<p className="screen-reader">{alt}</p>
					<p className="home-text">{prompt}</p>
					{renderOverlayImage()}
					<button
						aria-label={prompt}
						style={{ height: `${height}%` }}
						type="button"
						tabIndex={tabIndex}
						className="home-button"
						onClick={() => handleClick(activeSponsorSlot, setView, setMedia, playVideo, fadeSound, resetTimer, setSponsorSlot)}
					/>
				</div>
			)}
		</Spring>
	);
}

class HomeView extends React.PureComponent {
	componentDidMount() {
		const { state, actions } = this.props;
		const { setPopup } = actions;
		const hideHomeScreen = DataModel.getSettingsAttribute('hideHomeScreen', true);
		if (hideHomeScreen) {
			const { activeSponsorSlot } = state;
			const { setView, setMedia, playVideo, fadeSound, resetTimer, setSponsorSlot } = actions;
			handleClick(activeSponsorSlot, setView, setMedia, playVideo, fadeSound, resetTimer, setSponsorSlot);
		}
		let keySequence = [];
		let pin = DataModel.getSettingsAttribute('pin', true);
		if (!pin) {
			pin = '1234';
		}
		let pinArray = pin.toString(10).split('');
		if (pinArray.length < 4) {
			pinArray = ['1', '2', '3', '4'];
		}
		if (window.ipcRenderer) {
			window.ipcRenderer.on('show-quit-popup', (event) => {
				setPopup('quit');
			});
		}
		document.addEventListener('keypress', function onEvent(event) {
			const numKeys = keySequence.length;
			if (numKeys === 0 || numKeys === 4) {
				if (event.key === pinArray[0]) {
					keySequence.push('1');
				} else {
					keySequence = [];
				}
			} else if (numKeys === 1 && event.key === pinArray[1]) {
				keySequence.push('2');
			} else if (numKeys === 2 && event.key === pinArray[2]) {
				keySequence.push('3');
			} else if (numKeys === 3 && event.key === pinArray[3]) {
				keySequence = [];
				setPopup('quit');
			} else {
				keySequence = [];
			}
		});
	}

	render() {
		const { state, actions } = this.props;
		const { activeViewID, activeSponsorSlot } = state;
		const { setView, setMedia, playVideo, fadeSound, resetTimer, setSponsorSlot } = actions;
		return (
			<div>{renderHomeView(activeViewID, activeSponsorSlot, setView, setMedia, playVideo, fadeSound, resetTimer, setSponsorSlot)}</div>
		);
	}
}

HomeView.propTypes = {
	state: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

export default HomeView;
