import React from 'react';
import PropTypes from 'prop-types';

class MediaIcon extends React.PureComponent {
	render() {
		const { mediaType } = this.props;
		if (mediaType === 'map') {
			return (
				<span className="media-icon">
					<i className="fas fa-globe" />
				</span>
			);
		}
		if (mediaType === 'video') {
			return (
				<span className="media-icon">
					<i className="fas fa-video" />
				</span>
			);
		}
		if (mediaType === 'gallery') {
			return (
				<span className="media-icon">
					<i className="fas fa-images" />
				</span>
			);
		}
		if (mediaType === 'html') {
			return (
				<span className="media-icon">
					<i className="fas fa-tv" />
				</span>
			);
		}
		if (mediaType === 'panorama') {
			return (
				<span className="media-icon">
					<span className="degrees-360">360&#176;</span>
					<i className="fas fa-image" />
				</span>
			);
		}
		if (mediaType === 'object') {
			return (
				<span className="media-icon">
					<span className="degrees-360">360&#176;</span>
					<i className="fas fa-cube" />
				</span>
			);
		}
		if (mediaType === 'video360') {
			return (
				<span className="media-icon">
					<span className="degrees-360">360&#176;</span>
					<i className="fas fa-video" />
				</span>
			);
		}
		return null;
	}
}

MediaIcon.propTypes = {
	mediaType: PropTypes.string.isRequired,
};

export default MediaIcon;
